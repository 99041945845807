.MediaAbout {
  .top-info {
    display: flex;

    margin: auto;
    .left-firewall-content {
      width: 50%;
      .f-head {
        font-family: "Ubuntu";
        font-size: 45px;
        font-weight: 400;
        line-height: 51.71px;
        text-align: left;
        text-underline-position: from-font;
        background: radial-gradient(
          157.47% 1012.75% at 6.55% 17.65%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );

        text-decoration-skip-ink: none;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        span {
          background: radial-gradient(
              157.47% 1012.75% at 6.55% 17.65%,
              #ffffff 0%,
              rgba(255, 255, 255, 0) 100%
            ),
            linear-gradient(90deg, #34a853 0%, #5de18b 100%);
          text-decoration-skip-ink: none;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
      }
    }
    .right-firewall-content {
      width: 50%;
      .fire-paragraph {
        text-align: end;
        font-family: "Maven Pro";
        font-size: 18px;
        font-weight: 400;
        line-height: 21.15px;
        text-align: right;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
  }
  .aboutSection {
    margin-top: 5rem;
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    justify-content: space-between;
    // gap: 17px;
    row-gap: 3px;

    .media-media {
      // width: 400px;
      width: 32.7%;
      height: 355px;
      border: 0.02rem solid;
      border-radius: 20px;
      text-align: center;
      border-image-source: linear-gradient(
          0deg,
          rgba(246, 246, 246, 0.2),
          rgba(246, 246, 246, 0.2)
        ),
        linear-gradient(116.19deg, rgba(255, 255, 255, 0) 78.56%, #ffffff 100%);

      border-image-slice: 1;
      overflow: hidden;
      position: relative;

      .media-overlay-info {
        padding: 2rem 1.5rem 0 1.5rem;
        .media-title {
          font-family: "Ubuntu";
          font-size: 24px;
          font-weight: 400;
          line-height: 27.58px;
          text-align: left;
          background: #ffffff;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .media-para {
          margin-top: 1rem;
          font-family: "Maven Pro";
          font-size: 14px;
          font-weight: 400;
          line-height: 16.45px;
          text-align: left;
          background: #ffffff;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      img {
        width: 100%;
        object-fit: fit-content;
        margin: auto;
        text-align: center;
        transition: transform 0.2s ease;
      }
      img:hover {
        transform: scale(1.05); /* Scale image to 1.2 times its size on hover */
      }
    }
    .media-media:nth-child(2) {
      img {
        margin-top: -138px;
      }
    }
    .media-media:nth-child(4) {
      margin-top: 9px;
      width: 510px;
      width: 42%;

      img {
        margin-top: -17px;
        height: 70%;
        width: 70%;
        object-fit: contain;
      }
    }
    .media-media:nth-child(5) {
      width: 700px;
      width: 56.75%;
      margin-top: 9px;
    }
    .allbg {
      position: absolute;
      bottom: 0;
      right: 0;

      .bginner {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        .bgbig,
        .bgcenter,
        .bgsmall {
          border-radius: 50%;
          position: absolute;
        }
        .bgbig {
          height: 190px;
          width: 190px;
          background: #ad57a0;
          filter: blur(130px);
        }
        .bgcenter {
          height: 150px;
          width: 150px;
          background: #796d91;
          filter: blur(90px);
        }
        .bgsmall {
          height: 70px;
          width: 70px;
          background: #deb7aa;
          filter: blur(50px);
        }
      }
    }
  }
}

@media (max-width: 1220px) {
  .MediaAbout {
    .aboutSection {
      justify-content: space-evenly;
      gap: 17px;
      .media-media {
        width: 48%;
      }
      .media-media:nth-child(4) {
        width: 383px;
        height: 348px;
        width: 48%;
        margin-top: 0;
        img {
          margin-top: -20px;
          height: 72%;
          width: 72%;
          object-fit: contain;
        }
      }
      .media-media:nth-child(5) {
        width: 910px;
        width: 98%;
      }
    }
  }
}
@media (max-width: 846px) {
  .MediaAbout {
    .aboutSection {
      margin-top: 3rem;

      justify-content: space-between;
      gap: 0;
      row-gap: 15px;

      .media-media {
        width: 49%;
        height: 348px;

        .media-overlay-info {
          padding: 2rem 1.5rem 0 1.5rem;
          .media-title {
            font-family: "Ubuntu";
            font-size: 24px;
            font-weight: 400;
            line-height: 27.58px;
            text-align: left;
            background: #ffffff;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .media-para {
            margin-top: 1rem;
            font-family: "Maven Pro";
            font-size: 14px;
            font-weight: 400;
            line-height: 16.45px;
            text-align: left;
            background: #ffffff;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }
        img {
          object-fit: fit-content;
          margin: auto;
          text-align: center;
        }
      }
      .media-media:nth-child(2) {
        img {
          margin-top: -138px;
        }
      }
      .media-media:nth-child(4) {
        width: 49%;
        height: 348px;
        img {
          margin-top: -60px;
          height: 80%;
          width: 80%;
          object-fit: contain;
        }
      }
      .media-media:nth-child(5) {
        width: 55%;
        width: 750px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .MediaAbout {
    margin-top: 5rem;
    .top-info {
      width: 90%;

      .left-firewall-content {
        width: 50%;

        .f-head {
          font-size: 2.1rem;
          line-height: 35.71px;
        }
      }
      .right-firewall-content {
        width: 50%;
        .fire-paragraph {
          font-size: 16px;
          font-weight: 400;
          line-height: 20.15px;
          br {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .MediaAbout {
    .top-info {
      width: 90%;
      flex-direction: column;
      gap: 10px;
      .left-firewall-content {
        width: 100%;
        .f-head {
          font-size: 2.1rem;
          line-height: 35.71px;
          display: inline-block;
          br {
            display: none;
          }
        }
      }
      .right-firewall-content {
        width: 100%;
        .fire-paragraph {
          font-size: 16px;
          text-align: start;
          font-weight: 400;
          line-height: 20.15px;
        }
      }
    }
  }
}

@media (max-width: 625px) {
  .MediaAbout {
    // border: 2px solid red;

    .aboutSection {
      margin-top: 1rem;
      justify-content: center;
      gap: 0;
      row-gap: 15px;
      .media-media {
        width: 100%;
        height: 348px;
        height: 100%;

        .media-overlay-info {
          .media-title {
            font-size: 1.5rem;
          }
          .media-para {
            margin-top: 10px;
          }
        }
        img {
          object-fit: fit-content;
          margin: auto;
          text-align: center;
          margin-top: 20px;
        }
      }
      .media-media:nth-child(2) {
        height: 350px;
        position: relative;
        @media (max-width: 400px) {
          height: 300px;
        }
        img {
          width: 100%;
          margin-top: 0px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
      .media-media:nth-child(4) {
        width: 100%;
        height: 100%;
        padding: 1rem 0;
        img {
          margin-top: 10px;
          height: 70%;
          width: 70%;
          object-fit: contain;
        }
      }
      .media-media:nth-child(5) {
        width: 55%;
        width: 750px;
      }
    }
  }
}

@media (max-width: 550px) {
  .MediaAbout {
    .top-info {
      width: 100%;
      .left-firewall-content {
        .f-head {
          font-size: 1.5rem;
          line-height: 29.71px;
          display: inline-block;
          br {
            display: none;
          }
        }
      }
      .right-firewall-content {
        width: 100%;
        .fire-paragraph {
          font-size: 16px;
          text-align: start;
          font-weight: 400;
          line-height: 20.15px;
        }
      }
    }
  }
}
