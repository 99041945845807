.MYteams {
  .top-botton {
    width: 87%;
    margin: auto;
    .iicon {
      font-size: 22px;
      font-weight: 300;
      line-height: 23px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
  }
  .empHeading {
    width: 90%;
    margin: auto;
    display: flex;
    justify-content: space-between;
    .details-emp {
      padding-right: 2rem;
      font-size: 42px;
      font-weight: 500;
      line-height: 46px;
      letter-spacing: -2px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
    .ceocheck {
      display: flex;
      gap: 20px;
      .jalaj {
        width: 296px;
        height: 382px;
        gap: 16px;
        border-radius: 24px;
        border: 2px 0px 0px 0px;
        opacity: 1;
        background: #000000;
        border: 2px solid #282828;
        overflow: hidden;
        position: relative;
        @media (max-width: 767px) {
          height: 250px;
        }

        &:hover {
          .jajajimg {
            filter: grayscale(0);
            transform: rotateY(180deg);
            z-index: -1;
          }
          .detials {
            color: rgb(0, 0, 0);
            z-index: 2;
            backdrop-filter: blur(10px);
          }
        }
        .detials {
          height: 75%;
          width: 100%;
          padding: 1rem;
          position: absolute;
          top: 0%;
          color: rgb(0, 0, 0);
          transition: filter 0.3s linear;
          transition: all 0.3s linear;
          transform-style: preserve-3d;
          transition: transform 0.6s ease-in-out;
        }
        .imgoverdiv {
          width: 100%;
          height: 75%;
          transition: all 0.5s ease;
          @media (max-width: 767px) {
            height: 70%;
          }
          .jajajimg {
            width: 100%;
            height: 100%;
            object-fit: fit-content;
            background: #282828;
            filter: grayscale(1);
            transition: filter 0.3s linear;
            transform-style: preserve-3d;
            transition: transform 0.6s ease-in-out;
           
          }
        }
        .about {
          padding: 1rem;
          height: 25%;
          display: flex;
          justify-content: space-evenly;
          align-items: start;
          flex-direction: column;

          .name {
            font-size: 18px;
            font-weight: 500;
            line-height: 19px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            @media (max-width: 767px) {
              font-size: 16px;
            }
          }
          .postion {
            font-size: 16px;
            font-weight: 400;
            line-height: 17px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #9d9fa1;
            @media (max-width: 767px) {
              font-size: 12px;
              font-weight: normal;
            }
          }
        }
      }
    }
  }
  .employes {
    margin-top: 2rem;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;

    .jalaj {
      width: 296px;
      height: 382px;
      gap: 16px;
      border-radius: 24px;
      border: 2px 0px 0px 0px;
      opacity: 1;
      background: #000000;
      border: 2px solid #282828;
      overflow: hidden;
      .jajajimg {
        width: 100%;
        height: 75%;
        object-fit: fit-content;
        background: #282828;
        filter: grayscale(1);
      }
      .about {
        padding: 1rem;
        height: 25%;
        display: flex;
        justify-content: space-evenly;
        align-items: start;
        flex-direction: column;
        .name {
          font-size: 18px;
          font-weight: 500;
          line-height: 19px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
        .postion {
          font-size: 16px;
          font-weight: 400;
          line-height: 17px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          color: #9d9fa1;
        }
      }
    }
  }
  .buttons {
    width: 90%;
    margin: auto;
    margin-top: 2rem;

    button {
      padding: 8px 28px;
      width: 100%;
      margin: auto;
      background: transparent;
      color: #d0d5dd;
      font-size: 20px;
      font-weight: 400;
      border-radius: 25px;
      border: 2px solid #282828;
    }
  }
}

@media (max-width: 1024px) {
  .MYteams {
    .top-botton {
      width: 87%;

      .iicon {
        font-size: 18px;
      }
    }
    .empHeading {
      width: 90%;

      .details-emp {
        padding-right: 2rem;
        font-size: 2rem;
        line-height: 2.1rem;
        letter-spacing: 0;
      }
      .ceo {
        display: flex;
        gap: 10px;
        .jalaj {
          width: 220px;
          height: 282px;

          border-radius: 24px;

          .about {
            padding: 5px;
            height: 25%;
            justify-content: center;
            .name {
              font-size: 16px;
              line-height: 17px;
            }
            .postion {
              font-size: 12px;
              font-weight: 400;
              line-height: 17px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #9d9fa1;
            }
          }
        }
      }
    }
    .employes {
      margin-top: 2rem;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;
      .jalaj {
        width: 220px;
        height: 282px;

        border-radius: 24px;
        transform: scale(1);
        .about {
          padding: 5px;
          height: 25%;
          justify-content: center;
          .name {
            font-size: 16px;
            line-height: 17px;
          }
          .postion {
            font-size: 12px;
            font-weight: 400;
            line-height: 17px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #9d9fa1;
          }
        }
      }
    }
    .buttons {
      width: 90%;
      margin: auto;
      margin-top: 2rem;

      button {
        padding: 8px 28px;
        width: 100%;
        margin: auto;
        background: transparent;
        color: #d0d5dd;
        font-size: 20px;
        font-weight: 400;
        border-radius: 25px;
        border: 2px solid #282828;
      }
    }
  }
}
@media (max-width: 800px) {
  .MYteams {
    .top-botton {
      width: 87%;

      .iicon {
        font-size: 16px;
      }
    }
    .empHeading {
      width: 90%;

      .details-emp {
        padding-right: 2rem;
        font-size: 1.5rem;
        line-height: 1.7rem;
        font-weight: 300;
        letter-spacing: 0;
      }
      .ceo {
        display: flex;
        gap: 10px;
        .jalaj {
          width: 180px;
          height: 250px;
          border-radius: 24px;
          .jajajimg {
            height: 70%;
          }
          .about {
            padding: 5px;
            height: 30%;
            justify-content: center;
            .name {
              font-size: 16px;
              line-height: 17px;
              font-weight: 400;
            }
            .postion {
              font-size: 12px;
              font-weight: 100;
              line-height: 17px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #9d9fa1;
            }
          }
        }
      }
    }
    .employes {
      margin-top: 2rem;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;
      .jalaj {
        width: 180px;
        height: 250px;
        border-radius: 24px;
        .jajajimg {
          height: 70%;
        }
        .about {
          padding: 5px;
          height: 30%;
          justify-content: center;
          .name {
            font-size: 16px;
            line-height: 17px;
            font-weight: 400;
          }
          .postion {
            font-size: 12px;
            font-weight: 100;
            line-height: 17px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #9d9fa1;
          }
        }
      }
    }
    .buttons {
      width: 90%;
      margin: auto;
      margin-top: 2rem;

      button {
        padding: 8px 28px;
        width: 100%;
        margin: auto;
        background: transparent;
        color: #d0d5dd;
        font-size: 20px;
        font-weight: 400;
        border-radius: 25px;
        border: 2px solid #282828;
      }
    }
  }
}
@media (max-width: 650px) {
  .MYteams {
    .top-botton {
      width: 87%;

      .iicon {
        font-size: 16px;
      }
    }
    .empHeading {
      width: 90%;
      flex-direction: column;
      .details-emp {
        padding-right: 2rem;
        font-size: 1.5rem;
        line-height: 1.7rem;
        font-weight: 300;
        letter-spacing: 0;
      }
      .ceo {
        display: flex;
        gap: 10px;
        .jalaj {
          width: 180px;
          height: 250px;
          border-radius: 24px;
          .jajajimg {
            height: 70%;
          }
          .about {
            padding: 5px;
            height: 30%;
            justify-content: center;
            .name {
              font-size: 16px;
              line-height: 17px;
              font-weight: 400;
            }
            .postion {
              font-size: 12px;
              font-weight: 100;
              line-height: 17px;
              text-align: left;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
              color: #9d9fa1;
            }
          }
        }
      }
    }
    .employes {
      margin-top: 2rem;
      display: flex;
      gap: 10px;
      flex-wrap: wrap;
      justify-content: center;
      .jalaj {
        width: 180px;
        height: 250px;
        border-radius: 24px;

        .jajajimg {
          height: 70%;
        }
        .about {
          padding: 5px;
          height: 30%;
          justify-content: center;
          .name {
            font-size: 16px;
            line-height: 17px;
            font-weight: 400;
          }
          .postion {
            font-size: 12px;
            font-weight: 100;
            line-height: 17px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            color: #9d9fa1;
          }
        }
      }
    }
    .buttons {
      width: 90%;
      margin: auto;
      margin-top: 2rem;

      button {
        padding: 8px 28px;
        width: 100%;
        margin: auto;
        background: transparent;
        color: #d0d5dd;
        font-size: 20px;
        font-weight: 400;
        border-radius: 25px;
        border: 2px solid #282828;
      }
    }
  }
}

// .OurTeam {
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   .colordiv {
//     position: absolute;
//     top: 10%;
//     width: 295px;
//     height: 240px;

//     left: -95px;

//     background: #58ff92;

//     filter: blur(445px);
//   }
//   .rightbg {
//     position: absolute;
//     right: -2%;
//   }
//   .center {
//     position: absolute;
//     bottom: -30%;
//     left: 0;
//     height: 100%;
//     z-index: -1;
//   }
//   .teamBtn {
//     position: absolute;
//     top: 15.5%;
//     left: 50%;
//     transform: translate(-50%, -50%);
//     text-align: center;
//     .btnbtn {
//       font-size: 1rem;
//       background: #ffffff;
//       background: radial-gradient(
//           78.17% 203.96% at 6.34% 25.68%,
//           rgba(255, 255, 255, 0.3) 0%,
//           rgba(255, 255, 255, 0.1) 100%
//         )
//         /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
//       border: 1px solid #ffffff33;
//       border-radius: 8px;
//       padding: 4px 19px;
//     }
//     .themind {
//       margin-top: 1rem;
//       font-size: 3rem;
//       font-weight: 400;
//       line-height: 47px;
//       background: radial-gradient(
//         104.21% 376.56% at 50% 50%,
//         #ffffff 0%,
//         rgba(255, 255, 255, 0) 100%
//       );
//       -webkit-background-clip: text;
//       background-clip: text;
//       color: transparent;
//     }
//     .wantbe {
//       margin-top: 8px;
//       font-weight: 400;
//       font-size: 18px;
//       color: #ffffff;
//       a {
//         color: #ffffff;
//       }
//     }
//   }
// }

// // new code ....
// .OurteamMain {
//   width: 100%;
//   height: 27rem;
//   position: relative;
//   top: 35%;

//   .carousel-container {
//     width: 100%;
//     height: 100%;
//     transform-style: preserve-3d;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     input {
//       display: none;
//     }
//     .cards {
//       position: relative;
//       width: 100%; // Updated to fit more images
//       height: 100%;

//       .cardPlace {
//         position: absolute;
//         width: 25%;
//         width: 300px;
//         height: 100%;
//         left: 0;
//         right: 0;
//         margin: auto;
//         transition: transform 0.4s ease;
//         border-radius: 20px;
//         cursor: pointer;
//         overflow: hidden;
//         opacity: 1;

//         .inner-card {
//           border-radius: 20px;
//           position: relative;
//           height: 100%;
//           width: 100%;
//           overflow: hidden;
//           .imp-img {
//             border-radius: 20px;
//             width: 100%;
//             height: 100%;
//             object-fit: cover;
//           }
//           .imploye-details {
//             height: 91%;
//             width: 98%;
//             display: flex;
//             justify-content: center;
//             align-items: center;
//             flex-direction: column;
//             gap: 20px;
//             position: absolute;
//             bottom: 0;
//             left: 0;
//             opacity: 0;
//             backdrop-filter: blur(3px);
//             transform: rotateY(180deg);
//             transition: opacity 0.3s linear;
//             border-radius: 20px;
//           }
//         }
//       }
//     }

//     // Main selected item
//     #item-1:checked ~ .cards #song-1,
//     #item-2:checked ~ .cards #song-2,
//     #item-3:checked ~ .cards #song-3,
//     #item-4:checked ~ .cards #song-4,
//     #item-5:checked ~ .cards #song-5,
//     #item-6:checked ~ .cards #song-6,
//     #item-7:checked ~ .cards #song-7,
//     #item-8:checked ~ .cards #song-8,
//     #item-9:checked ~ .cards #song-9,
//     #item-10:checked ~ .cards #song-10 {
//       transform: translateX(0) scale(1);
//       opacity: 1;
//       z-index: 2;
//       transition: transform 0.6s;
//       &:hover {
//         transform: rotateY(180deg);
//         .imploye-details {
//           opacity: 1;
//         }
//       }
//     }

//     // Left side
//     #item-1:checked ~ .cards #song-10,
//     #item-2:checked ~ .cards #song-1,
//     #item-3:checked ~ .cards #song-2,
//     #item-4:checked ~ .cards #song-3,
//     #item-5:checked ~ .cards #song-4,
//     #item-6:checked ~ .cards #song-5,
//     #item-7:checked ~ .cards #song-6,
//     #item-8:checked ~ .cards #song-7,
//     #item-9:checked ~ .cards #song-8,
//     #item-10:checked ~ .cards #song-9 {
//       transform: translateX(-70%) scale(0.9);
//       opacity: 0.7;
//       z-index: 1;
//     }

//     // Right side
//     #item-1:checked ~ .cards #song-2,
//     #item-2:checked ~ .cards #song-3,
//     #item-3:checked ~ .cards #song-4,
//     #item-4:checked ~ .cards #song-5,
//     #item-5:checked ~ .cards #song-6,
//     #item-6:checked ~ .cards #song-7,
//     #item-7:checked ~ .cards #song-8,
//     #item-8:checked ~ .cards #song-9,
//     #item-9:checked ~ .cards #song-10,
//     #item-10:checked ~ .cards #song-1 {
//       transform: translateX(70%) scale(0.9);
//       opacity: 0.7;
//       z-index: 1;
//     }

//     // Far left position
//     #item-1:checked ~ .cards #song-9,
//     #item-2:checked ~ .cards #song-10,
//     #item-3:checked ~ .cards #song-1,
//     #item-4:checked ~ .cards #song-2,
//     #item-5:checked ~ .cards #song-3,
//     #item-6:checked ~ .cards #song-4,
//     #item-7:checked ~ .cards #song-5,
//     #item-8:checked ~ .cards #song-6,
//     #item-9:checked ~ .cards #song-7,
//     #item-10:checked ~ .cards #song-8 {
//       transform: translateX(-130%) scale(0.8);
//       opacity: 0.5;
//       z-index: 0;
//     }

//     // Far right position
//     #item-1:checked ~ .cards #song-3,
//     #item-2:checked ~ .cards #song-4,
//     #item-3:checked ~ .cards #song-5,
//     #item-4:checked ~ .cards #song-6,
//     #item-5:checked ~ .cards #song-7,
//     #item-6:checked ~ .cards #song-8,
//     #item-7:checked ~ .cards #song-9,
//     #item-8:checked ~ .cards #song-10,
//     #item-9:checked ~ .cards #song-1,
//     #item-10:checked ~ .cards #song-2 {
//       transform: translateX(130%) scale(0.8);
//       opacity: 0.5;
//       z-index: 0;
//     }
//   }
//   .left-arrow-icon {
//     display: flex;
//     justify-content: end;
//     padding: 1rem 7rem;

//     text-align: end;
//     .arrf {
//       border: 1px solid rgb(65, 65, 65);
//       width: 46px;
//       height: 46px;
//       border-radius: 50%;
//       display: flex;
//       justify-content: center;
//       align-items: center;
//       background: linear-gradient(
//           0deg,
//           rgba(19, 19, 19, 0.1),
//           rgba(19, 19, 19, 0.1)
//         ),
//         radial-gradient(
//           247.83% 244.94% at -204.35% -150%,
//           #ffffff 0%,
//           rgba(255, 255, 255, 0) 100%
//         ) !important
//           /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
//     }
//   }
// }

// @media (max-width: 1045px) {
//   .OurTeam {
//     position: absolute;
//     height: 100%;
//     width: 100%;
//     top: 0;
//     padding: 0;
//     .colordiv {
//       position: absolute;
//       top: 10%;
//       width: 105px;
//       height: 100px;

//       left: -95px;

//       background: #58ff92;

//       filter: blur(445px);
//     }
//     .rightbg {
//       position: absolute;
//       right: -2%;
//     }
//     .center {
//       position: absolute;
//       bottom: -30%;
//       left: 0;
//       height: 100%;
//       z-index: -1;
//     }

//     .teamBtn {
//       position: absolute;
//       top: 14.5%;
//       left: 50%;
//       transform: translate(-50%, -50%);
//       text-align: center;

//       .btnbtn {
//         font-size: 14px;
//         padding: 2px 19px;
//       }
//       .themind {
//         margin-top: 1rem;
//         font-size: 2.1rem;
//         font-weight: 400;
//         line-height: 33px;
//       }
//       .wantbe {
//         margin-top: 8px;

//         font-size: 16px;
//       }
//     }
//   }
//   .OurteamMain {
//     // border: 1px solid rgb(233, 5, 5);
//     width: 100%;
//     height: 80%;
//     position: relative;
//     top: 30%;
//     .carousel-container {
//       // border: 1px solid rgb(0, 195, 255);
//       input {
//         display: none;
//       }
//       .cards {
//         position: relative;
//         width: 100%; // Updated to fit more images
//         height: 100%;
//         // border: 1px solid rgb(255, 217, 0);
//         display: flex;
//         justify-content: center;
//         align-items: center;
//         .cardPlace {
//           position: absolute;
//           width: 25%;
//           width: 250px;
//           // border: 1px solid rgb(0, 255, 255);
//           height: 90%;

//           .inner-card {
//             height: 100%;
//             width: 100%;
//             img {
//               width: 100%;
//               height: 100%;
//               object-fit: cover;
//             }
//           }
//         }
//       }
//     }
//   }
// }
// @media (max-width: 845px) {
//   .OurteamMain {
//     width: 100%;
//     top: 15%;
//     .carousel-container {
//       .cards {
//         .cardPlace {
//           width: 200px;
//           height: 80%;
//         }
//       }
//     }
//   }
// }
// @media (max-width: 695px) {
//   .OurteamMain {
//     width: 100%;

//     .carousel-container {
//       .cards {
//         .cardPlace {
//           width: 150px;
//           height: 220px;
//         }
//       }
//     }
//     .left-arrow-icon {
//       display: none;
//     }
//   }
// }

// @media (max-width: 600px) {
//   .OurTeam {
//     position: absolute;
//     height: 100%;
//     width: 100%;
//     top: 2%;
//     .colordiv {
//       position: absolute;
//       top: 10%;
//       width: 105px;
//       height: 100px;

//       left: -95px;

//       background: #58ff92;

//       filter: blur(445px);
//     }
//     .rightbg {
//       position: absolute;
//       right: -2%;
//     }
//     .center {
//       position: absolute;
//       bottom: -30%;
//       left: 0;
//       height: 100%;
//       z-index: -1;
//     }

//     .teamBtn {
//       top: 14.5%;
//       width: 100%;
//       text-align: center;
//       .btnbtn {
//         font-size: 14px;
//         padding: 2px 13px;
//       }
//       .themind {
//         margin-top: 1rem;
//         font-size: 1.5rem;
//         line-height: 23px;
//       }
//       .wantbe {
//         margin-top: 0px;
//         font-size: 14px;
//         text-align: center !important;
//       }
//     }
//   }
// }

// @media (max-width: 525px) {
//   .OurteamMain {
//     width: 100%;
//     .carousel-container {
//       .cards {
//         .cardPlace {
//           width: 150px;
//           height: 220px;
//         }
//       }
//       // Main selected item
//       #item-1:checked ~ .cards #song-1,
//       #item-2:checked ~ .cards #song-2,
//       #item-3:checked ~ .cards #song-3,
//       #item-4:checked ~ .cards #song-4,
//       #item-5:checked ~ .cards #song-5,
//       #item-6:checked ~ .cards #song-6,
//       #item-7:checked ~ .cards #song-7,
//       #item-8:checked ~ .cards #song-8,
//       #item-9:checked ~ .cards #song-9,
//       #item-10:checked ~ .cards #song-10 {
//         transform: translateX(0) scale(1);
//         opacity: 1;
//         z-index: 2;
//       }

//       // Left side
//       #item-1:checked ~ .cards #song-10,
//       #item-2:checked ~ .cards #song-1,
//       #item-3:checked ~ .cards #song-2,
//       #item-4:checked ~ .cards #song-3,
//       #item-5:checked ~ .cards #song-4,
//       #item-6:checked ~ .cards #song-5,
//       #item-7:checked ~ .cards #song-6,
//       #item-8:checked ~ .cards #song-7,
//       #item-9:checked ~ .cards #song-8,
//       #item-10:checked ~ .cards #song-9 {
//         transform: translateX(-70%) scale(0.9);
//         opacity: 0.7;
//         z-index: 1;
//       }

//       // Right side
//       #item-1:checked ~ .cards #song-2,
//       #item-2:checked ~ .cards #song-3,
//       #item-3:checked ~ .cards #song-4,
//       #item-4:checked ~ .cards #song-5,
//       #item-5:checked ~ .cards #song-6,
//       #item-6:checked ~ .cards #song-7,
//       #item-7:checked ~ .cards #song-8,
//       #item-8:checked ~ .cards #song-9,
//       #item-9:checked ~ .cards #song-10,
//       #item-10:checked ~ .cards #song-1 {
//         transform: translateX(70%) scale(0.9);
//         opacity: 0.7;
//         z-index: 1;
//       }

//       // Far left position
//       #item-1:checked ~ .cards #song-9,
//       #item-2:checked ~ .cards #song-10,
//       #item-3:checked ~ .cards #song-1,
//       #item-4:checked ~ .cards #song-2,
//       #item-5:checked ~ .cards #song-3,
//       #item-6:checked ~ .cards #song-4,
//       #item-7:checked ~ .cards #song-5,
//       #item-8:checked ~ .cards #song-6,
//       #item-9:checked ~ .cards #song-7,
//       #item-10:checked ~ .cards #song-8 {
//         transform: translateX(-130%) scale(0.8);
//         opacity: 0.5;
//         z-index: 0;
//         display: none;
//       }

//       // Far right position
//       #item-1:checked ~ .cards #song-3,
//       #item-2:checked ~ .cards #song-4,
//       #item-3:checked ~ .cards #song-5,
//       #item-4:checked ~ .cards #song-6,
//       #item-5:checked ~ .cards #song-7,
//       #item-6:checked ~ .cards #song-8,
//       #item-7:checked ~ .cards #song-9,
//       #item-8:checked ~ .cards #song-10,
//       #item-9:checked ~ .cards #song-1,
//       #item-10:checked ~ .cards #song-2 {
//         transform: translateX(130%) scale(0.8);
//         opacity: 0.5;
//         z-index: 0;
//         display: none;
//       }
//     }
//   }
// }

// @media (max-width: 360px) {
//   .OurteamMain {
//     width: 100%;
//     .carousel-container {
//       .cards {
//         .cardPlace {
//           width: 120px;
//           height: 150px;
//         }
//       }
//     }
//   }
// }
