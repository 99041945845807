.singleJob {
  height: 100%;
  width: 100%;
  z-index: 99;
  overflow: hidden;
  position: relative;
  .back {
    border: none;
    background: none;
    color: white;
    padding: 43px;
    position: absolute;
    z-index: 9999;
  }

  .single1-div {
    height: 15vh;
    width: 100vw;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 3rem;
    position: relative;
    z-index: 90;

    .productHead {
      font-weight: 700;
      padding-top: 2rem;
    }

    .aply-outer {
      .aply-btnnn {
       
        background: #45a0e5;
        border: none;
        font-weight: 700;
        padding: 0.7rem 2rem;
        border-radius: 9px;
        // margin-left: 40%;
        z-index: 9;
      }
    }
  }

  .single2-div {
    height: 100%;
    width: 100%;
    // background: white;
    // color: black;
    display: flex;

    .singleLeft {
      width: 65%;
      width: 60%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .detailss {
        width: 70%;

        .div-a {
          .pro-heading {
            margin-left: 1rem;
            font-weight: 700;
            padding-top: 4rem;
          }

          h4 {
            margin-top: 62px;
            margin-left: 1rem;
          }
        }

        .div-b {
          margin-left: 1rem;
          margin-top: 59px;
        }

        .divlast {
          padding-bottom: 4rem;
        }

        .divC {
          margin-top: 59px;
          margin-left: 1rem;
          padding-bottom: 4rem;

          .aply-btn {
            background: #45a0e5;
            border: none;
            font-weight: 500;
            padding: 0.7rem 2rem;
            border-radius: 9px;
            margin: 3rem;
          }
        }
      }
    }

    .singleRight {
      width: 35%;
      width: 40%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: start;

      .outerDiv {
        width: 80%;
        height: 100%;
        padding: 2rem 0;
        // background: #d9d9d9;
        background: #111111;
        border-radius: 20px;

        margin-top: 4rem;

        h5 {
          margin-left: 1rem;
          font-weight: 600;
        }

        .box-outer-btn {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          padding-bottom: 2rem;

          .aply-btn {
            background: #45a0e5;
            border: none;
            font-weight: 500;
            padding: 0.7rem 2rem;
            border-radius: 9px;
          }
        }

        .div-box {
          width: 100%;
          height: 4rem;
          display: flex;
          margin-top: 1rem;
          //   background: #f9f9f95b;
          //   background: #2121213a;

          .left {
            width: 20%;
            height: 100%;
            display: flex;
            justify-content: center;
            font-size: 2rem;
          }

          .right {
            width: 80%;

            .right-para {
              opacity: 0.5;
            }

            p {
              margin: 0;
              font-weight: 600;
            }
          }
        }
      }

      .social-icons {
        margin-top: 1rem;
        height: 100%;
        width: 100%;

        // .iconsdiv {
        //   height: 3rem;
        //   width: 100%;
        //   display: flex;
        //   gap: 0.5rem;

        //   .icons {
        //     height: 100%;
        //     width: 3rem;
        //     border-radius: 50%;
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;
        //     cursor: pointer;

        //     i {
        //       font-size: 2rem;
        //       color: white;
        //     }
        //   }

        //   .fb {
        //     background: #3c5a99;
        //   }

        //   .in {
        //     background: #007ab9;
        //   }

        //   .twit {
        //     background: #55acee;
        //   }

        //   .insta {
        //     background: #bb346c;
        //   }

        //   .what {
        //     background-color: green;
        //   }
        // }
        .share-container {
          display: flex;
          gap: 10px;
        }
      }
    }
  }
}
.fill-form {
  width: 100%;
  height: 100%;
  background: rgba(4, 4, 4, 0.162);
  backdrop-filter: blur(8px);
  overflow-y: scroll;
  position: fixed;
  top: 0%;
  left: 0%;
  transition: transform(-50%, -50%);
  padding: 2rem 0;
  z-index: 100 !important;
  &::-webkit-scrollbar {
    display: none;
  }
}

@media (max-width: 768px) {
  .singleJob {
    .single2-div {
      .singleLeft {
        .detailss {
          width: 95%;
        }
      }
    }

    .singleRight {
      .outerDiv {
        width: 95% !important;
      }
      .social-icons {
        width: 90%;
        height: 80%;
        .iconsdiv {
          height: 2rem;
          flex-wrap: wrap;
          .icons {
            width: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .singleJob {
    .single2-div {
      flex-direction: column-reverse;

      .singleLeft {
        width: 80%;
        margin: auto;

        .divC {
          height: 100%;
          width: 90%;
          display: flex;
          flex-direction: column;
          justify-items: center;
          align-items: center;
          text-align: justify;
        }
      }

      .singleRight {
        width: 100%;

        .outerDiv {
          margin: 0;
          width: 78% !important;
          margin: auto;

          .box-outer-btn {
            display: none;
          }
        }

        .social-icons {
          width: 75%;
          margin: auto;
          margin-top: 1rem;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .singleJob {
    .back {
      border: none;
      background: none;
      color: white;

      padding: 35px 30px;
      position: absolute;
      z-index: 9999;
    }
  }
}
