.NewPortfolio {
  backdrop-filter: blur(94px);
  border: 1px solid rgba(0, 0, 0, 0);
  position: relative;

  .portTopHead {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: sticky;
    top: 0;
    background: black;
    .position {
      position: absolute !important;
      top: 1% !important;
      left: 1% !important;
      z-index: 99 !important;
    }

    .hd {
      margin: 0;
      font-size: 6rem;
      color: red;
      text-align: center;
      line-height: 5.5rem;
      font-family: "ubuntu";
      font-weight: 700;
    }
    .tp-para {
      text-align: center;
      font-size: 4.5rem;
      margin-top: -30px;
      font-style: italic;
    }
  }
  .portfolio-section {
    padding: 0 2rem;
    overflow: hidden;
    background: #33333334;
    position: sticky;
    top: 0;
    height: 100vh;
    width: 100vw;
    background: black;

    .overlays {
      display: flex;
      justify-content: space-between;
      height: 100vh;
      width: 100%;
      padding: 2rem 0;
      max-height: 680px;
      position: relative;
      .left-content {
        width: 48%;
        height: 100%;
        text-align: start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .logo {
          width: 150px;
          height: 48px;
          height: 120px;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
        .titles {
          margin-top: 10px;
          .Port-title {
            margin-bottom: 10px;
            font-size: 28px;
          }
          .port-para {
            padding: 2rem 0;
            color: #fffafab4;
          }
          .export-link {
            color: blue;
            font-size: 18px;
          }
          .techhead {
            font-size: 28px;
          }
        }
        .tech-logos {
          display: flex;
          flex-wrap: wrap;
          gap: 5px;
          img {
            height: 50px;
            width: 50px;
            border-radius: 10%;
            //   object-fit: cover;
          }
        }
      }
      //   right
      .bg {
        height: 300px;
        width: 300px;
        position: absolute;
        right: 32%;
        top: -5%;
        border-radius: 50%;
        z-index: -1;
        filter: blur(157px);
      }
      .right-content {
        width: 48%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        // align-items: center;
        // flex-direction: column;
        gap: 10px;

        .imgall-con,
        .image2-container {
          width: 47%;
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 10px;
        }
        .imgall-con {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 10px;

          .imgall {
            height: 100%;
            width: 100%;

            .imgone {
              width: 100%;
              border-radius: 25px;
            }
          }
          .imgall:nth-child(1) img {
            // margin-top: -400px;
          }
        }
        .image2-container {
          display: flex;
          flex-direction: column;
          gap: 10px;
          .img2 {
            height: 100%;
            width: 100%;
            .imgtwo {
              width: 100%;
              border-radius: 25px;
            }
          }
          .img2:nth-child(1) img {
            // margin-top: -470px;
          }
        }
        .mobile {
          height: 500px;
          width: 300px;
          overflow: hidden;
          position: relative;
          .image-container {
            height: 94.9%;
            width: 88%;
            border-radius: 30px;
            overflow: hidden;
            position: relative;
            top: 0.79rem;
            margin: auto;
            .notch {
              height: 17px;
              width: 150px;
              margin: auto;
              z-index: 11;
              position: relative;

              img {
                height: 100%;
                width: 100%;
                height: 17px;
                width: 150px;
                margin-top: -10px;
                z-index: 15;
              }
            }

            .imgcontainer {
              width: 100%;
              height: 100%;
              overflow: scroll;
              z-index: 1;
              position: relative;
              top: -19px;
              img {
                width: 100%;
                height: auto;
              }
            }
          }
          .div {
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            z-index: -1;

            img {
              overflow: hidden;
              height: 100%;
              width: 100%;
            }
          }
        }
        .export-link {
          font-size: 14px;
          border-radius: 5px;
          background: radial-gradient(
              78.17% 203.96% at 6.34% 25.68%,
              rgba(255, 255, 255, 0.3) 0%,
              rgba(255, 255, 255, 0.1) 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          border: 1px solid #ffffff33;
          font-weight: 400;
          font-family: var(--paragraphFont);
        }
      }
    }

    // .overlays.highligh {
    //   flex-direction: row-reverse;
    //   justify-content: space-between;
    //   .right-content {
    //     text-align: start;
    //     align-items: start;
    //     justify-content: start;
    //     gap: 10px;
    //   }
    //   .left-content {
    //     padding-left: 1rem;
    //   }
    // }
  }
  // .portfolio-section.highlight {
  //   order: 1;
  //   flex-direction: row-reverse;
  // }
}

@media (max-width: 1068px) {
  .NewPortfolio {
    .portTopHead {
      max-height: 575px;

      .hd {
        font-size: 5rem;

        line-height: 4.5rem;
      }
      .tp-para {
        text-align: center;
        font-size: 3.2rem;
        margin-top: -22px;
        font-style: italic;
      }
    }
    .portfolio-section {
      max-height: 575px;

      .overlays {
        max-height: 575px;
        padding: 2rem 0;
        .left-content {
          padding: 1rem 0;
          .logo {
            width: 120px;

            height: 120px;
          }
          .titles {
            margin-top: 0px;
            .Port-title {
              margin-bottom: 0px;
              font-size: 20px;
            }
            .port-para {
              padding: 10px 0;
              line-height: normal;
              font-size: 14px;
            }
            .export-link {
              color: blue;
              font-size: 14px;
            }
            .techhead {
              font-size: 20px;
            }
          }
          .tech-logos {
            img {
              height: 30px;
              width: 30px;
            }
          }
        }
        //   right
        .bg {
          height: 250px;
          width: 250px;
        }
        .right-content {
          width: 48%;
          height: 100%;
          display: flex;
          justify-content: space-evenly;

          .imgall-con,
          .image2-container {
            width: 47%;
            height: 100%;

            flex-direction: column;
            gap: 5px;
          }
          .imgall-con {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 5px;

            .imgall {
              height: 100%;
              width: 100%;
              .imgone {
                width: 100%;
                border-radius: 25px;
              }
            }
            .imgall:nth-child(1) img {
              margin-top: -200px;
            }
          }
          .image2-container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            .img2 {
              height: 100%;
              width: 100%;
              .imgtwo {
                width: 100%;
                border-radius: 25px;
              }
            }
            .img2:nth-child(1) img {
              margin-top: -270px;
            }
          }
        }
      }

      // .overlays.highligh {
      //   flex-direction: row-reverse;
      //   justify-content: space-between;
      //   .right-content {
      //     text-align: start;
      //     align-items: start;
      //     justify-content: start;
      //     gap: 10px;
      //   }
      //   .left-content {
      //     padding-left: 1rem;
      //   }
      // }
    }
    // .portfolio-section.highlight {
    //   order: 1;
    //   flex-direction: row-reverse;
    // }
  }
}
