.Ourclient {
  width: 100%;
  text-align: center;
  position: relative;
  .bgImg {
    width: 100%;
    position: absolute;
    top: -40%;
    display: flex;
    justify-content: space-between;
    align-items: start;
    z-index: -1;

    .leftcolor,
    .rightcolor {
      height: inherit;
    }
  }
  .heading {
    width: fit-content;
    margin: auto;
    font-size: 3.68rem;
    font-weight: 400;
    background: var(--gradient-text);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  p {
    margin-top: 1.5rem;
    font-size: 14px;
    font-weight: 400;
    color: #f6f6f6;
  }
  .overlayNumber {
    background: #00000000;
    .numbers {
      width: 60%;
      height: 100%;
      margin: auto;
      margin: 4rem auto;
      position: relative;
      display: flex;
      justify-content: space-around;
      padding: 1.8rem 0;
      background: linear-gradient(
          0deg,
          rgba(246, 246, 246, 0.01),
          rgba(246, 246, 246, 0.01)
        ),
        radial-gradient(
            65.87% 354.63% at -30.94% -318.52%,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      border-radius: 26px;
      box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2);
      border: 1px solid;
      border-image-source: linear-gradient(
          0deg,
          rgba(246, 246, 246, 0.2),
          rgba(246, 246, 246, 0.2)
        ),
        linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 5.32%,
          #ffffff 32.98%,
          rgba(255, 255, 255, 0) 60.64%
        );
      border-image-slice: 1;
      border-width: fit-content;
      backdrop-filter: blur(44px);
      border: .09rem solid ;
      border-image-source: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 5%,
        rgba(255, 255, 255, 0.5882352941) 25%,
        rgba(255, 255, 255, 0) 90%
      );
      border-image-slice: 1;



      .number {
        h1 {
          font-size: 2rem;
          font-weight: 300;

          margin: 0;
        }
        p {
          margin-top: 3px;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }

  .clientslogo {
    margin-top: 5rem;
    height: 10%;
    width: 90%;
    margin: auto;
    gap: 10px;
    .logo {
      width: 10rem;
      // height: 100%;
    }
  }
}

@media (max-width: 1024px) {
  .Ourclient {
    .bgImg {
      width: 100%;
      position: absolute;
      top: -40%;
      display: flex;
      justify-content: space-between;
      align-items: start;
      .leftcolor,
      .rightcolor {
        height: inherit;
      }
    }
    .heading {
      font-size: 2.1rem;
    }
    p {
      margin-top: 10px;
    }
    .overlayNumber {
      .numbers {
        width: 60%;
        height: 100%;
        padding: 1.2rem 0;

        .number {
          h1 {
            font-size: 1.5rem;
          }
        }
      }
    }

    .clientslogo {
      margin-top: 3rem;
      gap: 20px;
      .logo {
        width: 100px;
        height: 100px;
      }
    }
    .show-more-btn {
      margin-top: 2rem;
      color: white;
      padding: 10px 20px;
      border-radius: 8px;
      border: 1px solid rgba(128, 128, 128, 0.486);
      background: radial-gradient(
          110.37% 306.48% at -30.59% -224.07%,
          rgba(255, 255, 255, 0.89) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(0deg, #131313, #131313);
      color: white;
      font-family: "Maven Pro", sans-serif;
    }
  }
}
@media (max-width: 768px) {
  .Ourclient {
    .bgImg {
      width: 100%;
      position: absolute;
      top: -40%;
      display: flex;
      justify-content: space-between;
      align-items: start;

      .leftcolor,
      .rightcolor {
        height: inherit;
      }
    }
    .heading {
      font-size: 1.7rem;
    }
    p {
      margin-top: 10px;
    }
    .overlayNumber {
      .numbers {
        width: 70%;
        height: 100%;
        padding: 14px 0;

        .number {
          h1 {
            font-size: 1.3rem;
          }
          p {
            font-size: 12px;
          }
        }
      }
    }

    .clientslogo {
      margin-top: 3rem;
      gap: 10px;
      img {
        width: 80px;
        height: auto;
      }
    }
  }
}
@media (max-width: 530px) {
  .Ourclient {
    height: 100%;
    margin: 0;
    .bgImg {
      width: 100%;
      height: 100%;
      position: absolute;
      top: -40%;
      display: flex;
      justify-content: space-between;
      align-items: start;

      .leftcolor,
      .rightcolor {
        height: inherit;
        width: 50%;
      }
      .rightcolor {
        margin: 0;
        padding: 0;
      }
    }
    .heading {
      font-size: 1.7rem;
    }
    p {
      margin-top: 10px;
      width: 100%;
      margin: 10px auto;
      padding: 0 1.5rem;
      br {
        display: none;
      }
    }
    .overlayNumber {
      .numbers {
        width: 90%;
        height: 100%;
        padding: 10px 0;
        // flex-direction: column;
        flex-wrap: wrap;
        gap: 15px;
        .number {
          h1 {
            font-size: 1.2rem;
          }
          p {
            font-size: 12px;
            margin-bottom: 0;
            margin-top: 0;
            margin: auto;
            width: fit-content;
          }
        }
      }
    }

    .clientslogo {
      margin-top: 1rem;
      padding: 0 1rem;
      justify-content: space-evenly;
      gap: 0px;
      .logo {
        width: 80px !important;
        height: auto;
      }
    }
    .show-more-btn {
      margin-top: 1rem;
      padding: 5px 10px;
    }
  }
}
