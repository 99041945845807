.job-section-wrapper {
  height: 100%;
  width: 100%;
  border-radius: 13px;
  position: relative;
  overflow: hidden;
  z-index: 3;

  border: 1px solid #96969627;
  position: relative;
  margin: auto;
  .job-section-content {
    width: 350px;

    height: 198px;
    background: #1313131a;
    backdrop-filter: blur(44px);
    padding: 1rem 1.5rem;
    z-index: -1;

    .job-section-header {
      height: 2rem !important;
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-direction: column;

      .job-title {
        font-family: "Ubuntu", sans-serif;
        font-size: 24px;
        font-weight: 400;
        line-height: 18.68px;
        text-align: left;
      }
      .job-specs {
        width: 100%;
        display: flex;
        justify-content: space-between;
        .spec-item {
          height: 32px;
          width: 76px;
          padding-top: 10px;
          padding: 0.6rem;
          font-size: 1.5rem;
          // border: 2px solid #fef6f633;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "Ubuntu", sans-serif;
          background: radial-gradient(
            78.17% 203.96% at 6.34% 25.68%,
            rgba(255, 255, 255, 0.3) 0%,
            rgba(255, 255, 255, 0.1) 100%
          );

          .spec-detail {
            font-family: "Maven Pro", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 14.1px;
            text-align: center;
            margin: 0;
          }
        }
      }
    }

    .job-section-middle {
      margin-top: 0.9rem;
      width: 100%;

      p {
        line-height: 0.5625rem;
        font-size: 0.7rem;
      }
    }

    .job-section-footer {
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 2rem;

      button {
        width: 100px;
        height: 34px;
        gap: 10px;
        border-radius: 8px;
        font-size: 12px;
        font-weight: 700;
        line-height: 14.1px;
        font-family: "Maven Pro", sans-serif;
        text-align: center;
        color: white;
        border: none;
        cursor: pointer;
        background: radial-gradient(
          256.66% 747.37% at 0% 0%,
          #ffffff 0%,
          #4285f4 16.41%,
          rgba(66, 133, 244, 0) 100%
        );
      }

      .applied-count {
        font-size: 1rem;
      }
    }
  }
  .job-section-blur-effect {
    width: 82px;
    height: 82px;

    background: white;
    filter: blur(74px);
    z-index: 1;
    border-radius: 50%;
    position: absolute;
    top: -25%;
    left: -10%;
    border: 2px solid white;
  }
}

@media (max-width: 820px) {
  .job-section-wrapper {
    .job-section-content {
      width: 340px;
      height: 198px;
      .job-section-header {
        .job-specs {
          width: 100%;
        }
      }
    }
  }
}
@media (max-width: 790px) {
  .job-section-wrapper {
    .job-section-content {
      width: 300px;
      height: 178px;
      .job-section-header {
        .job-specs {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 712px) {
  .job-section-wrapper {
    .job-section-content {
      width: 263px;
      height: 158px;
      .job-section-header {
        padding: 0;
        .job-title {
          font-size: 1.3rem !important;
          font-weight: 400;
          line-height: 18.68px;
        }
        .job-specs {
          width: 100%;
          height: 100%;
          .spec-item {
            .spec-detail {
              font-size: 14px;
            }
          }
        }
      }
      .job-section-footer {
        margin-top: 15px;
        button {
          width: 90px;
          height: 28px;
          font-weight: 400;
        }
        .applied-count {
          font-size: 14px;
        }
      }
    }
  }
}

@media (max-width: 620px) {
  .job-section-wrapper {
    .job-section-content {
      width: 293px;
      height: 158px;
    }
  }
}
@media (max-width: 327px) {
  .job-section-wrapper {
    .job-section-content {
      width: 250px;
      height: 148px;
    }
  }
}
// @media (max-width: 700px) {
//   .job-section-wrapper {
//     margin-left: 4rem;
//     .job-section-content {
//       width: 400px;
//       height: 198px;
//       margin: auto;
//       .job-section-header {
//         .job-specs {
//           width: 80%;
//         }
//       }
//     }
//   }
// }

// @media (max-width: 550px) {
//   .job-section-wrapper {
//     margin-left: 2rem;
//     .job-section-content {
//       width: 370px;
//       height: 198px;
//       //margin: auto;
//       .job-section-header {
//         .job-specs {
//           width: 80%;
//         }
//       }
//     }
//   }
// }
