.mainContact {
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 10rem;
  padding-bottom: 5rem;
  backdrop-filter: blur(94px);

  overflow-x: hidden;
  .contectTopBg {
    width: 454px;
    height: 454px;
    top: -130px;
    left: 1163px;
    gap: 0px;

    position: absolute;
    border-radius: 50%;
    background: linear-gradient(
      90deg,
      #f68447 0%,
      #f36169 29.83%,
      #f36169 72.32%,
      #dd67b5 100%
    );
    filter: blur(107px);
    opacity: 0.6;
  }
  .contactLeftBg {
    width: 334px;
    height: 334px;
    background: #4285f4;
    filter: blur(137px);
    border-radius: 50%;
    position: absolute;
    bottom: 33%;
    left: -17%;
    z-index: -1;
  }
  .contactLeftdown {
    width: 258px;
    height: 258px;
    border-radius: 50%;
    background: #4285f4;
    filter: blur(167px);
    position: absolute;
    bottom: 29%;
    left: 10%;
  }
  .header {
    position: absolute;
    top: 35px;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 96%;
    z-index: 99;
  }

  .afterHeader {
    width: 94%;
    margin: auto;

    .firstPart {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .headingOfContact {
        h3 {
          padding-top: 0rem;
          font-weight: 400;
          font-size: 45px;
          line-height: 51.71px;
          text-align: left;
          background: radial-gradient(
            256.66% 747.37% at 0% 0%,
            #ffffff 0%,
            #4285f4 16.41%,
            rgba(66, 133, 244, 0) 100%
          );
          background-clip: text;
          color: transparent;
          font-style: italic;
          font-weight: 700;
        }
        .contact-heading-mid {
          background: radial-gradient(
            104.21% 376.56% at 50% 50%,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
          );
          background-clip: text;
          color: transparent;
          font-size: 45px;
          font-weight: 400;
          line-height: 51.71px;
        }
        .QR-code {
          z-index: 3;
        }
      }
    }

    .secondPart {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 100%;
      margin-top: 8rem;
      .contactdetail {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        width: 45%;
        height: 100%;
        margin-top: 4rem;
        margin-left: 1rem;

        .leftdiv {
          //flex: 1; // Each side of the contact details (left and right) takes equal space
          display: flex;
          flex-direction: column;
          gap: 20px; // Space between address and phone numbers

          h3 {
            margin-bottom: 0.7rem;
            font-size: 20px;
            color: #f6f6f6;
          }

          p {
            margin: 0;
            font-size: 16px;
            color: #f6f6f6;
            line-height: 1.5;
          }

          .phoneNumbers {
            display: flex;
            flex-direction: column;
            gap: 5px;

            h4 {
              margin: 0;
              font-size: 24px;
              color: #f6f6f6;
            }
          }
        }

        .rightdiv {
          display: flex;
          flex-direction: column;
          gap: 40px;

          .emailSection {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .generalEmail,
            .workEmail {
              h3 {
                margin-bottom: 5px;
                font-size: 18px;
                color: rgba(246, 246, 246, 0.65);
              }

              p {
                margin: 0;
                font-size: 22px;
                color: #f6f6f6;
              }
            }
          }

          .socialSection {
            h3 {
              margin-bottom: 10px;
              font-size: 18px;
              color: rgba(246, 246, 246, 0.65);
            }

            .socialLinks {
              display: flex;
              gap: 18px;
              font-size: 18px;
              color: #f6f6f6;
              a .social-media-link {
                height: 20px;
                width: 20px;
              }
            }
          }
        }
      }

      .map-main {
        width: 47%;
        height: 23rem;
        // margin-right: 5.1rem;
        background: linear-gradient(
          0deg,
          rgba(246, 246, 246, 0.1),
          rgba(246, 246, 246, 0.1)
        );
        border: 1px solid #f6f6f633;
        border-radius: 16px;
        position: relative;
        .mapgreenbg {
          width: 238.77px;
          height: 238.77px;
          background: linear-gradient(90deg, #34a853 0%, #ff7444 100%);
          filter: blur(107px);
          position: absolute;
          border-radius: 50%;
          left: 28%;
          top: -15%;
          z-index: -1;
        }
        .mapYellobg {
          width: 238.77px;
          height: 238.77px;
          background: linear-gradient(90deg, #f7e72f 0%, #ff7444 100%);
          filter: blur(97px);

          position: absolute;
          border-radius: 50%;
          top: -15%;
          right: -9%;
          z-index: -1;
        }
        .mapdetails {
          width: 95%;
          height: 93%;
          margin: auto;
          margin-top: 0.8rem;
          border: 2px solid white;
          border-radius: 5px;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .mainContact {
    padding-right: 2rem;
    padding-left: 2rem;
    .afterHeader {
      width: 100%;
      .secondPart {
        width: 100%;
        gap: 40px;
        .contactdetail {
          width: 40%;
          gap: 10px;
          .leftdiv {
            h3 {
              font-size: 1.5rem;
            }
            p {
              font-size: 14px;
            }
            .phoneNumbers {
              h4 {
                font-size: 18px;
              }
            }
          }
          .rightdiv {
            gap: 30px;
            .emailSection {
              gap: 30px;
              .generalEmail {
                h3 {
                  font-size: 1.3rem;
                }
                p {
                  font-size: 16px;
                }
              }
              .workEmail {
                h3 {
                  font-size: 1.3rem;
                }
                p {
                  font-size: 16px;
                }
              }
              .socialSection {
                h3 {
                  font-size: 1.3rem;
                }

                .socialLinks {
                  a .social-media-link {
                    height: 16px;
                    width: 16px;
                  }
                }
              }
            }
          }
        }
        .map-main {
          width: 50%;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .mainContact {
    height: 100%;
    width: 100%;
    padding: 5rem 2rem;
    padding-top: 8rem;
    .contectTopBg {
      width: 454px;
      height: 454px;
      position: absolute;
      left: 80%;
      top: -15%;
      z-index: -1;
      border-radius: 50%;
      background: linear-gradient(
        90deg,
        #f68447 0%,
        #f36169 29.83%,
        #f36169 72.32%,
        #dd67b5 100%
      );
      filter: blur(147px);
      // filter: blur(0);
    }
  }
}

@media (max-width: 940px) {
  .mainContact {
    .afterHeader {
      width: 100%;
      .secondPart {
        width: 100%;
        gap: 40px;
        .contactdetail {
          display: flex;
          width: 80%;

          .rightdiv {
            gap: 30px;
            .emailSection {
              gap: 30px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .mainContact {
    padding-top: 4rem;
    // .contectTopBg {
    //   width: 454px;
    //   height: 454px;
    //   top: -130px;
    //   left: 1163px;
    //   gap: 0px;
    //   opacity: 0px;
    //   position: absolute;
    //   border-radius: 50%;
    //   background: linear-gradient(
    //     90deg,
    //     #f68447 0%,
    //     #f36169 29.83%,
    //     #f36169 72.32%,
    //     #dd67b5 100%
    //   );
    //   filter: blur(107px);
    //   opacity: 0.6;
    // }
    .contactLeftBg {
      width: 134px;
      height: 134px;
      background: #4285f4;
      filter: blur(137px);
      border-radius: 50%;
      position: absolute;
      bottom: 33%;
      left: -17%;
    }
    .contactLeftdown {
      width: 158px;
      height: 158px;
      border-radius: 50%;
      background: #4285f4;
      filter: blur(167px);
      position: absolute;
      bottom: 29%;
      left: 1%;
    }
    @media (max-width: 430px) {
      padding: 2rem 0;
    }
    .afterHeader {
      width: 100%;
      margin-top: 1rem;
      .firstPart {
        display: flex;
        flex-direction: column;
        gap: 50px;
      }
      .secondPart {
        width: 100%;
        gap: 25px;

        .contactdetail {
          margin-top: 0;
          display: flex;
          flex-direction: column;
          width: 100%;
          .rightdiv {
            gap: 25px;
            .emailSection {
              gap: 25px;
            }
          }
        }
        .map-main {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .mainContact {
    // .contectTopBg {
    //   width: 454px;
    //   height: 454px;
    //   position: absolute;
    //   left: 80%;
    //   top: 20%;
    //   z-index: -1;
    //   border-radius: 50%;
    //   background: linear-gradient(
    //     90deg,
    //     #f68447 0%,
    //     #f36169 29.83%,
    //     #f36169 72.32%,
    //     #dd67b5 100%
    //   );
    //   filter: blur(147px);
    //   filter: blur(0);
    // }
    padding: 0 1rem;
    padding-top: 7rem;
    
    .afterHeader {
      width: 100%;

      .firstPart {
        display: flex;
        flex-direction: column;
        margin-bottom: 1.5rem;
        .sstory {
          font-size: 2.5rem !important;
          margin: 0;
          line-height: 0;
        }
       
      }
      .secondPart {
        width: 100%;
        // gap: 25px;

        display: flex;
        flex-direction: column;
        margin: auto;
        padding: 0 !important;
        .contactdetail {
          margin-top: 0;
          display: flex;
          flex-direction: row;
          width: 100%;
          margin: auto;
          .rightdiv {
            gap: 25px;
            .emailSection {
              gap: 25px;
            }
          }
        }
        .map-main {
          width: 100%;
          margin: auto;
        }
      }
    }
  }
}

@media (max-width: 575px) {
  .mainContact {
    .afterHeader {
      .secondPart {
        margin-bottom: 2rem;
      }
    }
  }
}
@media (max-width: 575px) {
  .mainContact {
    // padding: 0 1rem;
    .afterHeader {
      .firstPart {
        .headingOfContact {
          margin-top: -40px;

          h3 {
            font-size: 25px;
          }
          .contact-heading-mid {
            font-size: 28px;
            line-height: 25px;
          }
        }
        .contactForm {
          margin: 0;
          padding: 0;
        }
      }
      .secondPart {
        .contactdetail {
          display: flex;
          flex-direction: column;
          padding-left: 1rem;
          .leftdiv {
            margin-bottom: 1rem;
          }
        }
      }
    }
  }
}
// @media (max-width: 485px) {
//   .mainContact {
//     .header {
//       width: 90%;
//     }
//     .afterHeader {
//       width: 90%;

//       .firstPart {
//         .headingOfContact {
//           // line-height: 1px;
//           margin: 0;
//           h3 {
//             font-size: 2rem;
//           }
//           .contact-heading-mid {
//             font-size: 1.8rem;
//             line-height: 1.1;
//           }
//         }
//         .contactForm {
//           margin-top: -30px;
//         }
//       }
//       .secondPart {
//         width: 100%;
//         gap: 25px;
//         display: flex;
//         flex-direction: column;
//         margin: auto;
//         margin-bottom: 4rem;
//         margin-top: 20px;

//         .contactdetail {
//           margin-top: 0;
//           display: flex;
//           flex-direction: row;
//           width: 100%;
//           margin: auto;
//           .rightdiv {
//             gap: 25px;
//             .emailSection {
//               gap: 25px;
//             }
//           }
//         }
//         .map-main {
//           width: 100%;
//           height: 250px;
//           margin: auto;
//         }
//       }
//     }
//   }
// }

// @media (max-width: 375px) {
//   .mainContact {
//     // padding: 0 1rem;
//     .afterHeader {
//       .firstPart {
//         .headingOfContact {
//           margin-top: -40px;

//           h3 {
//             font-size: 25px;
//           }
//           .contact-heading-mid {
//             font-size: 25px;
//           }
//         }
//         .contactForm {
//           margin: 0;
//           padding: 0;
//         }
//       }
//       .secondPart {
//         .contactdetail {
//           display: flex;
//           flex-direction: column;
//           padding-left: 1rem;
//           .leftdiv {
//             margin-bottom: 1rem;
//           }
//         }
//       }
//     }
//   }
// }
