.MediaService {
  padding: 0rem 4rem;
  padding-top: 7rem;
  padding-bottom: 5rem;
  backdrop-filter: blur(94px);
  overflow-x: hidden;


  .mss-header {
    width: 93%;
    margin: auto;
    position: absolute;
    top: 20px;
    left: 49.4%;
    transform: translateX(-50%);
  }

  .media-video-section,
  .media-about-details,
  .reletedwork-section,
  .ms-section {
    padding: 5rem 0;

  }
  .media-about-details{
    padding-top: 0;

  }
  .reletedwork-section{
    margin-top: 3rem;
  }
  .media-video-section {
    height: 50rem;
    width: 100%;
    padding-top: 6rem;
  }
  .ms-section {
   margin-top: 5rem;
   padding-bottom: 0;

    .afterHeader {
      width: 100%;
      margin: auto;

      .firstPart {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .headingOfContact {
          width: 50%;
          h3 {
            // padding-top: 2.8rem;
            font-weight: 400;
            font-size: 45px;
            line-height: 51.71px;
            text-align: left;
            background: radial-gradient(
              87.64% 653.71% at 4.4% 28.57%,
              #ffffff 0%,
              rgba(255, 255, 255, 0.4) 100%
            );
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            width: fit-content;

            font-weight: 400;
            .contact-heading-mid {
              background: linear-gradient(90deg, #4285f4 0%, #9cc2ff 100%);

              background-clip: text;
              color: transparent;
              font-size: 45px;
              font-weight: 400;
              line-height: 51.71px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .MediaService {
    padding-top: 4rem;
    .media-video-section {
      height: 100%;
      padding-bottom: 9rem;
    }
    .ms-section {
      .afterHeader {
        width: 100%;
        margin: auto;

        .firstPart {
          width: 100%;
          .headingOfContact {
            width: 50%;
            h3 {
              padding-top: 2.1rem;
              font-weight: 400;
              font-size: 2.1rem;
              line-height: 31.71px;

              .contact-heading-mid {
                background: linear-gradient(90deg, #4285f4 0%, #9cc2ff 100%);

                background-clip: text;
                color: transparent;
                font-size: 2.1rem;
                font-weight: 400;
                line-height: 50.71px;
              }
            }
          }
          .ms-contactForm {
            width: 50%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .MediaService {
    padding-top: 10px;
    .media-video-section {
      height: 100%;
      @media (max-width: 670px) {
        padding-bottom: 6rem;
      }
    }
    .ms-section {
      .afterHeader {
        width: 100%;
        margin: auto;

        .firstPart {
          width: 100%;
          flex-direction: column;
          gap: 20px;
          .headingOfContact {
            width: 100%;
            h3 {
              padding-top: 1.5rem;
              font-weight: 400;
              font-size: 1.5rem;
              line-height: 31.71px;
              br {
                display: none;
              }

              .contact-heading-mid {
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 20.71px;
              }
            }
          }
          .ms-contactForm {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 670px) {
  .MediaService {
    padding: 3rem 2rem;
    padding-top: 0;
    .media-video-section {
      height: 100%;
    }
  }
}
