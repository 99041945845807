.MediaVideo {
  padding-right: 6rem;
  .video-sec-media {
    display: flex;
    align-items: end;
    flex-direction: column;
    justify-content: end;
    .video {
      width: 722px;
      height: 399px;
      background: white;
      border-radius: 7px;
      border: 1px solid;
      position: relative;
      border-image-source: linear-gradient(
          0deg,
          rgba(246, 246, 246, 0.2),
          rgba(246, 246, 246, 0.2)
        ),
        linear-gradient(
          301.74deg,
          rgba(255, 255, 255, 0) 82.02%,
          #ffffff 104.67%
        ),
        linear-gradient(
          110.22deg,
          rgba(255, 255, 255, 0) 84.67%,
          #ffffff 103.09%
        );
      border-image-slice: 1;
    }
    .machine {
      position: absolute;
      bottom: -48%;
      left: -60%;
      .machine-inner {
        position: relative;
        // border: 1px solid rebeccapurple;
        .glow,
        .nglow,
        .mglow {
          //   border: 1px solid white;
          transition: opacity 0.5s ease-in-out;
          position: absolute;
          top: -64%;
          left: 43%;
          z-index: -1;
        }
        .visible {
          opacity: 1;
        }

        .hidden {
          opacity: 0;
        }
        .nglow {
          top: -63%;
        }

        @keyframes identifier {
          0% {
            width: 0%;
            top: -64%;
            left: 43%;
          }
          100% {
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .MediaVideo {
    padding-right: 2rem;
    .video-sec-media {
      .video {
        width: 622px;
        height: 399px;
      }
    }
  }
}
@media (max-width: 1024px) {
  .MediaVideo {
    padding-right: 3rem;
    .video-sec-media {
      .video {
        width: 522px;
        height: 300px;
        .machine {
          bottom: -68%;
          left: -60%;
          .machine-inner {
            .glow,
            .nglow,
            .mglow {
              position: absolute;
              top: -64%;
              left: 43%;
              z-index: -1;
            }
            .nglow {
              top: -63%;
            }

            @keyframes identifier {
              0% {
                width: 0%;
                top: -64%;
                left: 43%;
              }
              100% {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .MediaVideo {
    padding-right: 3rem;
    @media (max-width: 915px) {
      padding-right: 0;
    }
  }
}
@media (max-width: 854px) {
  .MediaVideo {
    padding-right: 3rem;
    .video-sec-media {
      .video {
        width: 400px;
        height: 250px;
        .machine {
          bottom: -39%;
          left: -50%;
          .machine-inner {
            width: 180px;
            height: 180px;
            img {
              height: 100%;
              width: 100%;
            }
            .glow,
            .nglow,
            .mglow {
              // width: 500px;
              // transform: rotate(-30deg);
              position: absolute;
              top: -42%;
              left: 38%;
              z-index: -1;
            }
            .mglow {
              top: -35%;
              left: 53%;
            }

            @keyframes identifier {
              0% {
                width: 0%;
                top: -64%;
                left: 43%;
              }
              100% {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 670px) {
  .MediaVideo {
    height: 100%;
    padding: 0;
    margin: auto;
    padding: auto;
    .video-sec-media {
      .video {
        width: 100%;
        height: 100%;
        .machine {
          display: none;
          bottom: -39%;
          left: -50%;
          .machine-inner {
            width: 180px;
            height: 180px;
            img {
              height: 100%;
              width: 100%;
            }
            .glow,
            .nglow,
            .mglow {
              // width: 500px;
              // transform: rotate(-30deg);
              position: absolute;
              top: -42%;
              left: 38%;
              z-index: -1;
            }
            .mglow {
              top: -35%;
              left: 53%;
            }

            @keyframes identifier {
              0% {
                width: 0%;
                top: -64%;
                left: 43%;
              }
              100% {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
