.fourth-div {
  margin-top: 5rem;
  height: 100%;
  width: 100%;
  position: relative;
  padding-bottom: 2rem;
  overflow: hidden;
  z-index: -1;

  .listing-heading {
    width: 120px;
    padding: 4px 12px;
    gap: 0px;
    margin: auto;
    border-radius: 5px;
    border: 1px;
    margin-bottom: 1rem;
    background: radial-gradient(
      78.17% 203.96% at 6.34% 25.68%,
      rgba(255, 255, 255, 0.3) 0%,
      rgba(255, 255, 255, 0.1) 100%
    );

    font-family: "Maven Pro", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 18.8px;
    text-align: center;
  }
  .heading-4 {
    text-align: center;
    font-size: 2.8rem;
    line-height: 2.7rem;
    font-weight: 400;
    width: fit-content;
    background: radial-gradient(
      104.21% 376.56% at 50% 50%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    width: fit-content;
    margin: auto;
    margin-bottom: 1rem;
  }
  .Navbar {
    width: 85%;
    margin: auto;
    margin-top: 4rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    h5 {
      z-index: 999999;
      font-size: 16px;
      cursor: pointer;
      text-align: center;
      width: 15%;
      @media (max-width: 1300px) {
        width: fit-content;
      }
    }

    .nava {
      cursor: pointer;
      transition: border 0.1s linear;
      &.active {
        border-bottom: 2px solid rgb(240, 24, 24);
        font-weight: bold;
        font-family: "ubuntu", sans-serif;
      }
    }

    .nava {
      cursor: pointer;
    }

    .active-border {
      position: relative;
      padding-bottom: 10px;
      color: #3d7ab9;
    }
    
    .active-border::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px; /* Same height as the bottom border */
      background-color: #3d7ab9; /* Same color as the border */
      box-shadow: 0 0 8px #3d7ab9; /* Apply the shadow here */
    }
    
  }

  .job-post-box {
    height: 100%;
    width: 85%;
    margin: auto;
    margin-top: 2rem;
    display: flex;

    .jobpost {
      display: flex;
      justify-content: start;
      gap: 38px;
      flex-wrap: wrap;
      @media (max-width: 1332px) {
        justify-content: center;
      }
    }
  }
}

@media (max-width: 1200px) {
  .fourth-div {
    .Navbar {
      width: 90%;
      gap: 2rem;
      margin: auto;
    }
    .job-post-box {
      .jobpost {
        gap: 20px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .fourth-div {
    margin-top: 15rem;
    padding-bottom: 2rem;

    .heading-4 {
      text-align: center;
      font-size: 2.1rem;
      line-height: 2.2rem;
      margin-bottom: 10px;
    }
    .Navbar {
      margin-top: 2rem;
    }
  }
}

@media (max-width: 820px) {
  .fourth-div {
    .job-post-box {
      height: 100%;
      width: 85%;
      margin: auto;
      margin-top: 1.5rem;
      margin-bottom: 1rem;
      .jobpost {
        width: 90% !important;
        display: flex;
        justify-content: space-between;
        gap: 20 !important; /* Adds space between the forms */
      }
    }
  }
}

@media (max-width: 868px) {
  .fourth-div {
    .Navbar {
      justify-content: space-between;
      gap: 20px;
    }
    .job-post-box {
      width: 100%;
      .jobpost {
        width: 90%;
        margin: auto;
        //margin: .5rem;
        gap: 15px;
      }
    }
  }
}
//done
@media (max-width: 768px) {
  .fourth-div {
    margin-top: 0;
    padding: 2rem 0;
  }
}
@media (max-width: 700px) {
  .fourth-div {
    .Navbar {
      //gap: 0;
      justify-content: space-between;
      width: 90%;
      margin: auto;
      flex-wrap: wrap;
      h5 {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 620px) {
  .fourth-div {
    .heading-4 {
      text-align: center;
      font-size: 1.7rem;
      line-height: 1.7rem;
      margin-bottom: 30px;
    }
    .Navbar {
      gap: 20px;
      width: 98%;
      justify-content: center;
      // border: 2px solid red;
    .active-border {
      padding-bottom: 7px;
      
    }
    }
    .job-post-box {
      height: 100%;
      width: 85%;
      margin: auto;
      margin-top: 1rem;
      margin-bottom: 1rem;
      .jobpost {
        width: 90% !important;
        display: flex;
        justify-content: center;
        // flex-wrap: nowrap;
        gap: 20 !important; /* Adds space between the forms */
      }
    }
  }
}

@media (max-width: 386px) {
  .fourth-div {
    .Navbar {
    }
  }
}

// @media (max-width: 530px) {
//   .fourth-div {
//     margin: 4rem 0;
//     .heading-4 {
//       font-size: 8vw;
//     }

//     .para-4 {
//       width: 90%;
//       margin: auto;
//     }

//     .Navbar {
//       width: 90%;
//       margin: 2rem auto;
//       display: grid;
//       grid-template-columns: repeat(3, 1fr);

//       .nav {
//         font-size: 1rem;
//         padding: 0.5rem;
//         text-align: center;
//         color: #007ab9;
//         // border: 1px solid red;
//       }
//       .active-border {
//         border: none;
//         // color: white;
//       }
//     }
//     .job-post-box {
//       margin-top: 4rem;
//     }
//   }
// }
