.AboutPage {
  overflow: hidden;
  position: relative;
  padding-bottom: 3rem;
  // backdrop-filter: blur(94px);

  .navheader {
    width: 95%;
    position: absolute;
    top: 1%;
    top: 37px;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
  .secondSection {
    z-index: 1;
    padding-top: 8rem;
    position: relative;
    .leftgreenbg {
      width: 291px;
      height: 252px;
      background: #4285f4;
      filter: blur(122px);
      position: absolute;
      opacity: 0.6;
      right: -10%;
      top: 27%;
      border-radius: 50%;
    }
    .secondgreenbg {
      width: 197px;
      height: 180px;
      background: #58ff92;
      border-radius: 50%;
      position: absolute;
      opacity: 0.6;
      right: -0%;
      z-index: -1;
      top: 5%;
      filter: blur(112px);
    }
    .centerwhitebg {
      width: 295px;
      height: 240px;
      position: absolute;
      background: #58ff92;
      filter: blur(230px);
      z-index: -1;
      left: -35px;
      top: 250px;
      gap: 0px;

    }
  }
  .thirdSection {
    scrollbar-width: none;
    margin: 12rem 0;
  }
  .fourthSection {
    margin: 5rem 0;
  }

  .fifthSection {
    margin-top: 15rem;
    display: flex;
    align-items: center;
    // justify-content: ;
    gap: 10px;
    padding: 0 3rem;
    z-index: 1;
    .leftsectrion {
      width: 50%;
      height: 100%;
      padding-top: 5rem;
      
    }
    .rigthSection {
      width: 50%;
    }
  }
  .sixSection {
    margin-top: 4rem;
  }
}

@media (max-width: 1024px) {
  .AboutPage {
    .secondSection {
      padding: 3rem;
    }
    .fifthSection{
      .leftsectrion{
        padding-top: 0;
      }
    }
  }
}
@media (max-width: 900px) {
  .AboutPage {
    height: 100%;
    padding-bottom: 5rem;

    .fifthSection {
      margin-top: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 30px;
      padding: 0 3rem;
      .leftsectrion {
        width: 80%;
      }
      .rigthSection {
        width: 80%;
      }
      @media (max-width: 550px) {
        padding: 0 1.5rem;
        .leftsectrion,
        .rigthSection {
          width: 100%;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .AboutPage {
    .secondSection {
      margin-top: 2.5rem;
      padding: 0 1.5rem;
    }
    .thirdSection {
      scrollbar-width: none;
      margin: 4rem 0;
      margin-bottom: 1rem;
    }
    .fourthSection {
      margin: 0;
      padding: 0;
    }

    .fifthSection {
      margin-top: 2rem;
    }
    .joinform {
      padding: 0 3rem;
    }
  }
}

@media (max-width: 550px) {
  .AboutPage {
    .fourthSection {
      padding: 3rem 0;
    }
    .fifthSection {
      padding: 2rem 1.5rem;
    }
    .sixSection {
      margin-top: 20px;
    }
    .joinform {
      padding: 2rem 2rem;
    }
  }
}
