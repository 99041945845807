* {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;
}
.brandIceberge {
  width: 100%;
  height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;

  .ice-heading {
    width: 90%;
    margin: auto;
    .left-content {
      .elevate {
        font-family: "Ubuntu";
        font-size: 44.59px;
        font-weight: 400;
        line-height: 51.24px;
        text-align: left;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        background: radial-gradient(
          157.47% 1012.75% at 6.55% 17.65%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        width: fit-content;
      }
    }
    .right-content {
      .brand-paragraph {
        font-family: "Maven Pro";
        font-size: 18px;
        font-weight: 400;
        line-height: 21.15px;
        text-align: right;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        background: #f6f6f6;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        width: fit-content;
      }
    }
  }

  .ss {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    // background: red;
    .brandIcebergeChewck {
      transition: transform 0.4s linear;
      margin: auto;
      display: flex;
      .ice-berge-section {
        width: 874px;
        height: 1029px;
        margin: auto;
        margin-left: 6rem;
        margin-top: 5rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ice-you-heading {
          font-family: "Maven Pro";
          font-size: 22px;
          font-weight: 700;
          line-height: 25.85px;
          text-align: center;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;

          color: #f6f6f621;
        }
        .ICEBERG {
          font-family: "Maven Pro";
          font-size: 218px;
          font-weight: 900;
          line-height: 180.85px;
          margin-top: -25px;
          padding: 0;
          text-align: left;
          color: #f6f6f621;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
        }
        .ice-image {
          margin-top: -110px;
          z-index: 9;
        }
        // .fullline-ouverlay {

        .full-line {
          height: 69.5%;
          background-color: #ffffff;
          width: 5px;
          position: absolute;
          top: 15.5rem;
          right: 17%;
          z-index: 0;
          transition: all 0.9s linear;
        }
        // }
        .blur-overlay {
          display: none;
          z-index: -1;
          position: absolute;
          top: 0%;
          height: 40%;
          width: 300%;
          z-index: 99;
          background: rgba(0, 0, 255, 0.041);
          backdrop-filter: blur(20px);
          // background: red;
          // filter: blur(10px);
        }

        .ice-bg {
          z-index: 0;
          position: absolute;
          top: 40%;
          height: 60%;
          width: 300%;
          background: linear-gradient(
            180deg,
            rgba(66, 133, 244, 0.8) 0%,
            rgba(19, 19, 19, 0) 100%
          );
          backdrop-filter: blur(40px);
        }
      }

      .lines-container {
        width: 260px;
        z-index: 9;
        position: relative;
        height: 100%;
        height: 46rem;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 19.2rem;
        margin-left: -162px;
        gap: 6.7rem;
        gap: 7.99rem;
        border-left: 4px solid white;
        transition: "all 0.9s ease" !important;
        @media (max-width: 800px) {
          display: none;
        }
        .line-item {
          display: flex;
          align-items: center;
          margin-top: -17px;

          .horizontal-line {
            z-index: 1;
            width: 20px;
            height: 2px;
            background-color: #ffffff;
            margin-right: 20px;
          }

          .text {
            z-index: 9;
            display: inline-flex;
            padding: 5px 20px;
            align-items: center;
            border-radius: 8px;
            border: 1px solid rgba(255, 255, 255, 0.2);
            background: radial-gradient(
              203.96% 78.18% at 6.34% 25.68%,
              rgba(255, 255, 255, 0.3) 0%,
              rgba(255, 255, 255, 0.1) 100%
            );
          }
        }
      }
      // }

      &.move-left {
        transform: translateX(-36%);
      }

      .showOtherDetails {
        width: 500px !important;

        position: absolute;

        right: -47%;
        opacity: 0;

        z-index: 999;

        transition: transform 0.2s linear, opacity 0.001s linear, z-index 0.5s;

        &.moveLeft {
          transform: translateX(0); /* Move to its visible position */
          opacity: 1;
          z-index: 999 !important; /* Bring it to the front */
        }

        .arryaPartOfice {
          width: 100%;

          .category-container {
            width: 100%;
            margin-bottom: 45px; /* Space between different categories */
            .TopPartOflefticeburg {
              display: flex;
              flex-direction: column; /* Align children in a column */
              align-items: flex-start; /* Align items to the start (left) */
              margin-bottom: 30px;
            }

            .item-box {
              display: flex;
              align-items: center; // Vertically centers the image
              padding: 16px;
              border: 1px solid #ddd; // Optional: For box styling
              border-radius: 25px;
              margin-bottom: 16px;
              background: #c2c2c210;
              backdrop-filter: blur(2px);

              .img-container {
                flex-shrink: 0; // Prevent the image from shrinking
                margin-right: 16px; // Space between image and text
                display: flex;
                justify-content: center; // Center the image horizontally
                align-items: center; // Center the image vertically

                .img {
                  width: 60px; // Adjust the size as needed
                  height: 60px; // Adjust the size as needed
                  border-radius: 50%; // Optional: Make the image circular
                  object-fit: cover; // Ensures the image fits nicely
                }
              }

              .text-content {
                display: flex;
                flex-direction: column;
                padding-top: 12px;
                margin-left: 10px;

                .TitleOfList {
                  font-size: 18px; // Adjust font size
                  font-weight: bold; // Make the title bold
                  margin-bottom: 15px; // Space between title and details
                }

                .details {
                  font-size: 14px; // Adjust font size
                  color: #555; // Optional: Lighter color for details
                }
              }
            }
          }
        }
      }
    }
  }
  //
  .iceberg-container {
    display: none;
    @media (max-width: 800px) {
      display: block;
      padding: 2rem;
    }
  }
}

@media (max-width: 1300px) {
  .brandIceberge {
    .ss {
      .brandIcebergeChewck {
        &.move-left {
          transform: translateX(-42%); // Move left by 50%
          @media (max-width: 1170px) {
            transform: translateX(-49%); // Move left by 50%
          }
        }
      }
    }
  }
}

@media (max-width: 1070px) {
  .brandIceberge {
    .ss {
      .brandIcebergeChewck {
        .ice-berge-section {
          position: relative;
          width: 600px;
          height: 729px;
          margin: auto;
          margin-left: 6rem;
          margin-top: 2rem;

          .ice-you-heading {
            font-size: 22px;

            line-height: 25.85px;
          }
          .ICEBERG {
            font-size: 128px;
            line-height: 140.85px;
            margin-top: -35px;
          }
          .ice-image {
            margin-top: -80px;
            height: 85%;
            width: 95%;
            .icee {
              height: 100%;
              width: 100%;
              transition: all 0.2s ease !important;
            }
          }

          .full-line {
            top: 15.5rem;
            height: 56%; /* Or whatever thickness you want for the line */
            width: 3px; /* Adjust width as per your design */
            right: 17%;
          }

          .blur-overlay {
            display: none;

            z-index: -1;
            position: absolute;
            top: 0%;
            height: 40%;
            width: 300%;
            z-index: 99;
            background: rgba(0, 0, 255, 0.041);
            backdrop-filter: blur(20px);
            // background: red;
            // filter: blur(10px);
          }

          .ice-bg {
            z-index: 0;
            position: absolute;
            top: 40%;
            height: 60%;
            width: 300%;
            background: linear-gradient(
              180deg,
              rgba(66, 133, 244, 0.8) 0%,
              rgba(19, 19, 19, 0) 100%
            );
            backdrop-filter: blur(40px);
          }
        }
        .lines-container {
          width: 250px;
          height: 26rem;
          margin-top: 16.7rem;
          margin-left: -115px;
          gap: 3.49rem; /* Gap between the individual lines */

          .line-item {
            .horizontal-line {
              // margin-top: -24px;
              margin-top: 10px;
              margin-left: -2px;
            }
            .text {
              z-index: 9;
              display: inline-flex;
              padding: 2px 10px;
              align-items: center;
              border-radius: 5px;
              font-size: 14px;
            }
          }
        }

        &.move-left {
          transform: translateX(-36%); // Move left by 50%
          @media (max-width: 1070px) {
            transform: translateX(-49%); // Move left by 50%
          }
        }

        .showOtherDetails {
          width: 400px !important;
          position: absolute;
          right: -47%;
          opacity: 0;

          z-index: 999;

          transition: transform 0.2s linear, opacity 0.001s linear, z-index 0.5s;

          &.moveLeft {
            transform: translateX(0); /* Move to its visible position */
            opacity: 1;
            z-index: 999 !important; /* Bring it to the front */
          }

          .arryaPartOfice {
            width: 100%;

            .category-container {
              width: 100%;
              margin-bottom: 15px; /* Space between different categories */
              .TopPartOflefticeburg {
                display: flex;
                flex-direction: column; /* Align children in a column */
                align-items: flex-start; /* Align items to the start (left) */
                margin-bottom: 0px;
              }

              .item-box {
                // Vertically centers the image
                padding: 10px;

                margin-bottom: 10px;

                .img-container {
                  margin-right: 10px; // Space between image and text
                  // Center the image vertically
                }

                .text-content {
                  display: flex;
                  flex-direction: column;
                  padding-top: 10px;
                  margin-left: 10px;

                  .TitleOfList {
                    font-size: 18px; // Adjust font size
                    font-weight: bold; // Make the title bold
                    margin-bottom: 15px; // Space between title and details
                  }

                  .details {
                    font-size: 14px; // Adjust font size
                    color: #555; // Optional: Lighter color for details
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1025px) {
  .brandIceberge {
    .ice-heading {
      width: 90%;
      margin: auto;
      .left-content {
        .elevate {
          font-size: 2.1rem;
          line-height: 38.24px;
        }
      }
      .right-content {
        width: 50%;
        .brand-paragraph {
          font-size: 18px;
          font-weight: 400;
          line-height: 20.15px;
          br {
            display: none;
          }
        }
      }
    }
  }
}
@media (max-width: 850px) {
  .brandIceberge {
    // border: 1px solid red;

    .ice-heading {
      flex-direction: column;
      gap: 10px;
      .left-content {
        width: 100%;
        .elevate {
          display: inline-block;
          text-align: center !important;
          margin: auto;
          font-size: 1.7rem;
          line-height: 29.24px;
          margin-left: 5px;
        }
      }
      .right-content {
        width: 100%;
        .brand-paragraph {
          text-align: justify !important;
          font-size: 16px;
          font-weight: 400;
          line-height: 20.15px;
          br {
            display: none;
          }
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .brandIceberge {
    overflow: hidden;
    width: 100%;
    .ss {
      width: 100%;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      .brandIcebergeChewck {
        .ice-berge-section {
          margin: 0;
          .ice-you-heading {
            font-weight: 400;
          }
          .ICEBERG {
            font-size: 22vw;
          }
        }
      }
    }
  }
}
