.Performance {
  text-align: center;
  .Performance-head {
    font-family: "Ubuntu";
    font-size: 2.8rem;
    font-weight: 400;
    line-height: 51.71px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: radial-gradient(
      157.47% 1012.75% at 6.55% 17.65%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: fit-content;
    margin: auto;
    span {
      background: linear-gradient(90deg, #34a853 0%, #5de18b 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
  .performance-para {
    margin-top: 20px;
    font-family: "Maven Pro";
    font-size: 18px;
    font-weight: 400;
    line-height: 21.15px;
    text-align: center;
    background: #f6f6f6;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .card-container-parent {
    height: 100%;
    width: 100%;
    position: relative;
    .colorgif {
      width: 75%;
      height: 75%;
      position: absolute;
      left: 50%;
      top: 39%;
      transform: translate(-50%, -50%);
      background: url("../../../assets/images/marketing service/performance/ss.gif")
        lightgray 50% / cover no-repeat;
      mix-blend-mode: lighten;
    }
    .Performance-cards {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 20px;
      position: relative;

      .Performance-card {
        width: 500px;
        margin-top: 50px;
        height: 261px;
        display: flex;
        align-items: start;
        flex-direction: column;
        justify-content: center;
        padding: 0 2.5rem;
        backdrop-filter: blur(65px);
        border: 1px solid #f6f6f633;
        background: #f6f6f612;
        position: relative;
        overflow: hidden;

        .item-heading {
          font-family: "Ubuntu";
          font-size: 2rem;
          font-weight: 400;
          line-height: 36.77px;
          background: #ffffff;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          width: fit-content;
        }
        .item-des {
          font-family: "Maven Pro";
          font-size: 14px;
          font-weight: 400;
          line-height: 16.45px;
          text-align: left;
          background: #ffffff;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          width: fit-content;
        }
        .itembg {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: -1;
          .itembg1 {
            height: 262px;
            width: 262px;
            border-radius: 50%;
            position: absolute;
            filter: blur(240px);
            z-index: 1;
          }
          .itembg2 {
            border-radius: 50%;

            height: 144px;
            width: 144px;

            position: absolute;
            z-index: 2;

            filter: blur(95px);
          }
          .itembg3 {
            border-radius: 50%;
            height: 76px;
            width: 76px;
            position: absolute;
            z-index: 3;
            filter: blur(40px);
            filter: brightness(22px);
            box-shadow: 0 0 15px #beb7aa, 0 0 30px #beb7aa;
          }
        }
        .right-0 {
          right: 5%;
          top: 7%;
        }
        .left-0 {
          left: 5%;
          bottom: 7%;
        }

        .left-1 {
          right: 5%;
          bottom: 7%;
        }
        .left-2 {
          left: 5%;
          top: 7%;
        }

        .center {
          top: 0%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .Performance-card:nth-child(1) {
        border-radius: 64px 0 64px 0;
      }

      .Performance-card:nth-child(2) {
        border-radius: 0 64px 0 64px;
      }
      .Performance-card:nth-child(3) {
        border-radius: 0 64px 0 64px;
      }
      .Performance-card:nth-child(4) {
        border-radius: 64px 0 64px 0;
      }
      .Performance-card:nth-child(5) {
        border-radius: 0 0 64px 64px;
        margin: auto;
        margin-top: 50px;
      }
    }
  }
}

@media (max-width: 1124px) {
  .Performance {
    .card-container-parent {
      .colorgif {
        height: 30%;
        width: 30%;
        position: absolute;
        left: 50%;
        top: 38%;
        transform: translate(-50%, -50%);
        // background: url(<path-to-image>) lightgray 50% / cover no-repeat;
        mix-blend-mode: lighten;
        @media (max-width: 804px) {
          display: none;
        }
      }
      .Performance-cards {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;

        .Performance-card {
          width: 43%;
          margin-top: 50px;
          height: 261px;
          padding: 0 2.5rem;
          .item-heading {
            font-family: "Ubuntu";
            font-size: 2rem;

            line-height: 36.77px;
          }
        }
      }
    }
  }
}
@media (max-width: 1024px) {
  .Performance {
    .Performance-head {
      font-size: 2.1rem;

      line-height: 35.71px;
    }
    .performance-para {
      width: 80%;
      margin: auto;
      margin-top: 20px;
      font-size: 16px;
      br {
        display: none;
      }
    }
  }
}
@media (max-width: 768px) {
  .Performance {
    .Performance-head {
      font-size: 1.7rem;
      line-height: 28.71px;
    }

    .Performance-cards {
      .colorgif {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        // background: url(<path-to-image>) lightgray 50% / cover no-repeat;
        mix-blend-mode: lighten;
      }
      .card-container-parent {
        .Performance-card {
          width: 48%;
          margin-top: 25px;
          height: 261px;
          padding: 0 2.5rem;
          @media (max-width: 670px) {
            height: 290px;
            padding: 0 1.5rem;
          }
          .item-heading {
            font-family: "Ubuntu";
            font-size: 1.5rem;
            line-height: 30.77px;
          }
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .Performance {
    .Performance-head {
      font-size: 1.7rem;
      line-height: 28.71px;
    }
    .performance-para {
      width: 100%;
    }
    .card-container-parent {
      .Performance-cards {
        flex-direction: column;
        .colorgif {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          // background: url(<path-to-image>) lightgray 50% / cover no-repeat;
          mix-blend-mode: lighten;
        }

        .Performance-card {
          width: 100%;
          margin-top: 25px;
          height: 100%;
          padding: 2rem;

          .item-heading {
            font-family: "Ubuntu";
            font-size: 1.5rem;
            line-height: 30.77px;
          }
        }
        .Performance-card:nth-child(5) {
          margin-top: 25px;
        }
      }
    }
  }
}
