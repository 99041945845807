.BlogPage {
  padding: 5rem 1.5rem;
  padding-top: 0;
  backdrop-filter: blur(94px);
 border: 1px solid rgba(0, 0, 0, 0);


  .blog-first-section,
  .blog-second-section,
  .blog-third-section {
    padding: 2rem 1.5rem;
  }

  .blog-third-section {
    padding: 3rem 1.5rem;
    margin-bottom: 12rem;

  }
  .blog-header-nav {
    width: 100%;
    margin: auto;
    margin-top: 20px;
  }
}

@media (max-width: 770px) {
  .BlogPage {
    .JoinFormss {
      margin: auto;
      margin-top: 2rem;
      width: 94%;
      @media (max-width: 520px) {
        width: 89%;
        
      }
    }
  }
}

@media (max-width: 550px) {
  .BlogPage {
    .blog-header-nav {
      width: 98%;
      margin-top: 20px;
    }
    padding: 5rem 1px;
    padding-top: 0;
  }
}
@media (max-width: 480px) {
  .BlogPage {
    .blog-header-nav {
      width: 91%;
      margin-top: 20px;
    }
    padding: 5rem 1px;
    padding-top: 0;
  }
}
