.OurServices {
  text-align: center;
  scrollbar-width: none;
  overflow: hidden;
  width: 100vw;
  max-width: 1800px;
  h1 {
    font-size: 2.75rem;
    font-weight: 400;
    width: fit-content;
    background: var(--gradient-text);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin: auto;
    text-align: center;
  }
  .inner-des {
    margin-top: 1rem;
    font-size: 14px;
    font-weight: 400;
    color: #f6f6f6;
    font-family: "Maven Pro";
  }

  .serviceCard {
    margin-top: 3rem;
    scrollbar-width: none;
    height: 100vh;
    margin: 1rem 0;
    .service-cards-container {
      display: flex;
      gap: 10px;
      width: max-content;
      gap: 40px;
      padding: 2rem 0;
      padding-left: 5rem;
      width: max-content;
      // scrollbar-width: none;

      .service-card {
        width: 420px;
        height: 550px;
        border-radius: 48px;
        overflow: hidden;
        // will-change: transform;
        overflow: hidden;
        text-align: center;
        border: 1px solid #ffffff33;
        background: #13131303;
        backdrop-filter: blur(60px);
        cursor: pointer;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
        border: 0.09rem solid;
        border: 1px solid #ffffff33;

        // border-image-source: linear-gradient(
        //   30deg,
        //   rgba(255, 255, 255, 0) 5%,
        //   rgba(255, 255, 255, 0.5882352941) 25%,
        //   rgba(255, 255, 255, 0) 90%
        // );
        // border-image-slice: 1;

        // transition: transform 0.3s ease, box-shadow 0.3s ease;
        // &:hover {
        //   transform: translateY(-5px);
        //   box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        // }

        video {
          object-fit: cover;
          height: 54%;
          width: 100%;
          border-radius: 48px 48px 0 0;
        }

        .cardInfo {
          height: 46%;
          width: 100%;
          text-align: start;
          padding: 15px 20px;
          border-radius: 0 0 48px 48px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          overflow: hidden;
          position: relative;
          margin-top: -4px;

          //
          .uperbg {
            width: 326px;
            height: 326px;
            margin-left: 190px;
            margin-top: -230px;
            filter: blur(154px);
            border-radius: 50%;
            position: absolute;
            top: 0;
            .centerbg {
              width: 188.57px;
              height: 188.57px;

              backdrop-filter: blur(324px);
              border-radius: 50%;

              .bottombg {
                width: 111.86px;
                height: 111.86px;

                backdrop-filter: blur(184px);
                border-radius: 50%;
              }
            }
          }
          //

          h3 {
            font-size: 1.5rem;
            line-height: 1.6rem;
            font-family: "Ubuntu";
            color: #f6f6f6;
            font-weight: 400;
          }
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;

            li {
              font-size: 14px;
              color: #ffffff;
              font-family: "Maven Pro";
              background: radial-gradient(
                  78.17% 203.96% at 6.34% 25.68%,
                  rgba(255, 255, 255, 0.3) 0%,
                  rgba(255, 255, 255, 0.1) 100%
                )
                /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
              border: 1px solid #ffffff33;
              padding: 4px 19px;
              border-radius: 8px;
            }
          }

          p {
            font-size: 14px;
            color: #ffffffcc;
            font-weight: 100;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .OurServices {
    h1 {
      font-size: 2.1rem;
    }
    .inner-des {
      margin-top: 10px;
    }
    .serviceCard {
      margin-top: 3rem;
      overflow-x: auto;
      scrollbar-width: none;

      .service-cards-container {
        display: flex;
        gap: 10px; /* Space between items */
        width: max-content;
        gap: 40px;
        padding: 2rem 0;
        padding-left: 5rem;

        .service-card {
          width: 370px;
          height: 500px;

          video {
            height: 54%;
          }

          .cardInfo {
            padding: 10px 20px;

            h3 {
              font-size: 1.4rem;
              line-height: 1.4rem;
            }
            ul {
              li {
                padding: 2px 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen {
  .OurServices {
    .serviceCard {
      height: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      padding-right: 2rem;
    }
  }
}

@media (max-width: 590px) {
  .OurServices {
    h1 {
      font-size: 1.7rem;
    }
    .inner-des {
      margin-top: 7px;
      font-size: 14px;
      padding: 0 2rem;
      br {
        display: none;
      }
    }
    .serviceCard {
      margin-top: 2rem;

      .service-cards-container {
        gap: 10px;
        padding: 0;
        padding-left: 1rem;

        .service-card {
          width: 320px;
          height: 420px;

          video {
            height: 50%;
          }

          .cardInfo {
            height: 50%;
            padding: 10px 20px;
            justify-content: center;
            gap: 10px;

            h3 {
              font-size: 1.2rem;
              line-height: 1.3rem;
            }
            ul {
              li {
                padding: 1px 10px;
                font-size: 12px;
              }
            }
            p {
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
