.AboutHome {
  height: 100vh;
  max-height: 60rem;
  overflow: hidden;
  position: relative;

  .circle {
    width: 100%;
    position: absolute;
    bottom: 0;

    .subinner {
      z-index: -1;
      .bgTopBox {
        position: absolute;
        height: 100%;
        width: 100%;
        bottom: 0;

        .kkk {
          height: 100%;
          width: 100%;
          position: relative;

          .name1,
          .name2,
          .name3,
          .name4,
          .centername {
            position: absolute;
            color: rgba(255, 255, 255, 0.719);
            background: radial-gradient(
              104.21% 376.56% at 50% 50%,
              #ffffff 0%,
              rgba(255, 255, 255, 0) 100%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .name1 {
            top: 55%;
            left: 7%;
            transform: rotate(-40deg);
          }
          .name2 {
            top: 16%;
            left: 25%;
            transform: rotate(-20deg);
          }
          .name3 {
            top: 16%;
            right: 25%;
            transform: rotate(20deg);
          }
          .centername {
            top: 0%;
            right: 50%;
            // transform: rotate(20deg);
            opacity: 0.5;
          }
          .name4 {
            top: 57%;
            right: 7%;
            transform: rotate(40deg);
          }
        }
        .top-names {
          height: 100%;
          width: 100%;
          position: relative;
          .nam1,
          .nam2,
          .nam3,
          .nam4,
          .cen {
            position: absolute;
            color: rgba(255, 255, 255, 0.158);
          }
          .nam1 {
            top: -80%;
            left: 12%;
            transform: rotate(-32deg);
          }
          .nam2 {
            top: -126%;
            left: 35%;
            transform: rotate(-10deg);
          }
          .nam3 {
            top: -126%;
            right: 35%;
            transform: rotate(10deg);
          }
          .cen {
            top: -50%;
            right: 50%;

            opacity: 0.5;
          }
          .nam4 {
            top: -80%;
            right: 12%;
            transform: rotate(35deg);
          }
        }
        .top-lay-names {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 100%;
          z-index: -1;
          .tln {
            padding: 0 2rem;
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            .nam11,
            .nam22,
            .nam33,
            .nam44 {
              position: absolute;
              color: rgba(255, 255, 255, 0.021);
            }
            .nam11 {
              top: 10%;
              left: 2%;
              transform: rotate(-30deg);
            }
            .nam22 {
              top: -25%;
              left: 25%;
              transform: rotate(-10deg);
            }
            .nam33 {
              top: -20%;
              right: 25%;
              transform: rotate(10deg);
            }
            .nam44 {
              top: 10%;
              right: 2%;
              transform: rotate(30deg);
            }
          }
        }
        .top-lay-names2 {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 100%;
          height: 190%;
          z-index: -1;
          .tln {
            padding: 0 2rem;
            position: relative;
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: space-between;
            .nam11,
            .nam22,
            .nam33,
            .nam44 {
              position: absolute;
              color: rgba(255, 255, 255, 0.021);
            }
            .nam11 {
              top: 10%;
              left: 2%;
              transform: rotate(-30deg);
            }
            .nam22 {
              top: -25%;
              left: 25%;
              transform: rotate(-10deg);
            }
            .nam33 {
              top: -20%;
              right: 25%;
              transform: rotate(10deg);
            }
            .nam44 {
              top: 10%;
              right: 2%;
              transform: rotate(30deg);
            }
          }
        }
        @keyframes rotateCircle {
          0% {
            transform: rotate(0deg);
          }
          100% {
            transform: rotate(360deg);
          }
        }
      }
      height: 50%;
      width: 100%;
      .backgrounds {
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 9 !important;
        filter: drop-shadow(0 0 100px #4285f4);
      }
      .go {
        width: 300px;
        height: 300px;
        border-radius: 50%;
        background-color: #7cadff;
        filter: box-shadow(0 0 150px #4285f4);
        box-shadow: 0 0 100px #4285f4;
        filter: blur(62.404998779296875px);
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      }
      .abh {
        position: absolute;
        left: 50%;
        top: -30%;
        transform: translate(-50%, -50%);
        opacity: 0.8;
      }
    }
  }
  .cir {
    width: 180vw;
    // width: 100%;
    animation: rotate 155s linear infinite;
    position: absolute;
    z-index: 10;
    // top: -300px;
    left: -540px;
    border-radius: 50%;
  }
  @keyframes rotate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .homeinner {
    z-index: 9999 !important;
    height: 100%;
    width: 100%;
    h1 {
      margin: auto;
      margin-top: 6rem;
      font-size: 2.78rem;
      font-weight: 400;
      line-height: 3.71rem;
      font-family: "Ubuntu";
      background: radial-gradient(
        104.21% 376.56% at 50% 50%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      width: fit-content;
      span {
        font-style: italic;
        background: linear-gradient(90deg, #34a853 0%, #5de18b 100%);
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        width: fit-content;
      }
    }
    p {
      margin-top: 10px;
      font-size: 14px;
      text-align: center;
    }
  }
}

@media (min-width: 1395px) {
  .AboutHome {
    .homeinner {
      h1 {
        margin-top: 15rem;
      }
    }
  }
}

@media (max-width: 1200px) {
  .AboutHome {
    height: 650px;
    max-height: 700px;
    .circle {
      bottom: 0;
    }
  }
}

@media (max-width: 1024px) {
  .AboutHome {
    height: 600px;
    .circle {
      .subinner {
        .bgTopBox {
          .kkk {
            .name1 {
              top: 50%;
              left: 7%;
              transform: rotate(-40deg);
            }
            .name2 {
              top: 16%;
              left: 25%;
              transform: rotate(-20deg);
            }
            .name3 {
              top: 16%;
              right: 25%;
              transform: rotate(20deg);
            }
            .centername {
              top: 0%;
              right: 50%;
              // transform: rotate(20deg);
              opacity: 0.5;
            }
            .name4 {
              top: 52%;
              right: 7%;
              transform: rotate(40deg);
            }
          }
        }
        height: 50%;
        width: 100%;
        .backgrounds {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .abh {
          width: 600px;
          height: 600px;
          border-radius: 50%;
          left: 50%;
          top: -30%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
      }
    }
    .homeinner {
      h1 {
        margin-top: 6rem;
        font-size: 2.1rem;
        line-height: 2rem;
      }
    }
    @media (max-width: 723px) {
      height: 500px;
    }
  }
}

@media (max-width: 768px) {
  .AboutHome {
    .circle {
      .subinner {
        .bgTopBox {
          .kkk {
            .name1 {
              top: 45%;
              left: 7%;
              transform: rotate(-40deg);
            }
            .name2 {
              top: 11%;
              left: 25%;
              transform: rotate(-20deg);
            }
            .name3 {
              top: 11%;
              right: 25%;
              transform: rotate(20deg);
            }
            .centername {
              top: -7%;
              right: 50%;
              // transform: rotate(20deg);
              opacity: 0.5;
            }
            .name4 {
              top: 46%;
              right: 7%;
              transform: rotate(40deg);
            }
          }
        }
        height: 50%;
        width: 100%;
        .backgrounds {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
        .go {
          width: 200px;
          height: 200px;
          filter: blur(52.404998779296875px);
        }
        .abh {
          width: 500px;
          height: 400px;
          border-radius: 50%;
          left: 50%;
          top: -30%;
          transform: translate(-50%, -50%);
          z-index: -1;
        }
      }
    }
  }
}
@media (max-width: 610px) {
  .AboutHome {
    .circle {
      .subinner {
        .bgTopBox {
          .kkk {
            .name1,
            .name2,
            .name3,
            .name4,
            .centername {
              top: 70% !important;
              transform: rotate(0);
            }
            .name1 {
              left: 9%;
            }
            .name2 {
              left: 35%;
            }
            .name3 {
              right: 35%;
            }
            .centername {
              right: 50%;
              display: none;
            }
            .name4 {
              right: 9%;
            }
          }
        }
        height: 50%;
        width: 100%;
        .backgrounds {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
    }
  }
}

@media (max-width: 555px) {
  * {
    p {
      text-align: justify !important;
    }
  }
  .AboutHome {
    height: 500px !important;
    .circle {
      .subinner {
        .top-names {
          height: 100%;
          width: 100%;
          position: relative;
          .nam1,
          .nam2,
          .nam3,
          .nam4,
          .cen {
            position: absolute;
            font-size: 12px;
            color: rgba(255, 255, 255, 0.158);
          }
          .nam1 {
            top: -85%;
            left: 10%;
            transform: rotate(-30deg);
          }
          .nam2 {
            top: -126%;
            left: 35%;
            transform: rotate(-10deg);
          }
          .nam3 {
            top: -126%;
            right: 35%;
            transform: rotate(15deg);
          }
          .cen {
            top: -50%;
            right: 50%;

            opacity: 0.5;
          }
          .nam4 {
            top: -85%;
            right: 10%;
            transform: rotate(35deg);
          }
        }
        .go {
          width: 130px;
          height: 130px;
          filter: blur(32.404998779296875px);
        }
      }
    }
    .homeinner {
      h1 {
        margin-top: 7rem;
        font-size: 1.7rem;
        line-height: 2rem;
      }
      p {
        font-size: 14px;
        padding: 1.7rem;

        br {
          display: none;
        }
      }
    }
    @media (max-width: 723px) {
      height: 450px;
    }
  }
}
