* {
  font-weight: 400;
  color: #f6f6f6;
}
.ServicePage {
  // overflow: hidden;
  // padding-bottom: 3rem;
  position: relative;
  // backdrop-filter: blur(94px);

  .homeOuter {
    position: relative;
    .header {
      position: absolute;
      width: 96%;
      top: 6%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 99;
    }
    .homeSection {
      height: 41rem;
      width: 100%;
      display: flex;
      overflow: hidden;
      background: #000;
      z-index: 1;
      .leftSection {
        border-right: 1px solid rgb(255, 255, 255);
        padding-left: 5rem;
        z-index: 5;
        width: 50%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: start;
        gap: 35px;
        background: radial-gradient(
            104.22% 103.03% at 101.56% 0%,
            #4285f4 0%,
            rgba(0, 0, 0, 0) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

        h1 {
          font-family: "Ubuntu";
          font-size: 2.65rem;
          font-weight: 400;
          line-height: 3.1rem;
          background: radial-gradient(
              276.82% 843.77% at 9.55% 21.57%,
              #ffffff 0%,
              rgba(255, 255, 255, 0) 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          width: fit-content;
          span {
            background: radial-gradient(
                351.67% 1064.88% at -39.28% -40.2%,
                #ffffff 0%,
                #4285f4 16.41%,
                rgba(66, 133, 244, 0) 100%
              )
              /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            width: fit-content;
            }
        }
        p {
          font-family: var(--paragraphFont);
          font-size: 14px;
          line-height: 1rem;
        }
        button {
          font-size: 1rem;
          font-weight: 700;
          font-family: var(--paragraphFont);
          padding: 14px 26px;
          background: radial-gradient(
              34.03% 95.74% at -2.32% -6.38%,
              rgba(255, 255, 255, 0.9) 0%,
              rgba(255, 255, 255, 0) 100%
            ),
            linear-gradient(0deg, #4285f4, #4285f4);
          border-radius: 10px;
          border: 1px solid;

          border-image-source: linear-gradient(
              0deg,
              rgba(255, 255, 255, 0.2),
              rgba(255, 255, 255, 0.2)
            ),
            linear-gradient(
              108.65deg,
              #ffffff -26.25%,
              rgba(255, 255, 255, 0) 31.16%
            );
          border: 1px solid rgba(255, 255, 255, 0.192);
        }
      }
      .rightSection {
        width: 50%;
        display: flex;
        justify-content: center;
        align-items: end;
        padding-bottom: 2rem;
        position: relative;
        background: radial-gradient(
            102.03% 96.6% at 1.64% 1.92%,
            rgba(13, 93, 225, 0.3) 0%,
            rgba(0, 0, 0, 0) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

        .days {
          display: flex;
          align-items: center;
          gap: 4px;
          margin-left: -130px;
          z-index: 100;
          .day {
            z-index: 9999999;
            width: 161px;
            height: 141px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 25px;
            padding: 22px;
            background: radial-gradient(
                57.14% 65.25% at -14.29% -15.6%,
                rgba(246, 246, 246, 0.8) 0%,
                rgba(246, 246, 246, 0) 100%
              ),
              linear-gradient(127.49deg, #4285f4 0%, #002563 100%); /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
            border: 1px solid;
            border-image-source: linear-gradient(
                130.85deg,
                #ffffff -4.96%,
                rgba(255, 255, 255, 0) 21.35%
              ),
              linear-gradient(
                0deg,
                rgba(255, 255, 255, 0.2),
                rgba(255, 255, 255, 0.2)
              );
            border-image-slice: 1;
            border-radius: 4px;
            border: 1px solid rgba(255, 255, 255, 0.2);

            h4 {
              font-weight: 700;
              font-size: 2.75rem;
            }
            h5 {
              font-size: 1rem;
              font-weight: 700;
            }
          }
          .hour {
            z-index: 22;
            width: 161px;
            height: 141px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 25px;
            padding: 22px;
            backdrop-filter: blur(44px);
            background: #1313131a;
            border: 1px solid rgba(255, 255, 255, 0.2);
            border-radius: 4px;
            position: relative;
            overflow: hidden;
            .topcolor {
              position: absolute;
              top: -70px;
              left: -50px;
              height: 100px;
              width: 100px;
              background: #f6f6f6;
              filter: blur(40px);
            }
            h4 {
              font-weight: 300;
              font-size: 2.75rem;
            }
            h5 {
              font-size: 1rem;
              font-weight: 300;
            }
          }
          p {
            font-weight: 300;
            font-size: 2.75rem;
            line-height: 2.124rem;
            font-style: italic;
          }
        }
        .rgbBg {
          position: absolute;
          z-index: 1;
          left: -90px;
          bottom: -103%;
          width: 1027.85px;
          height: 1027.85px;

          border-radius: 0px 300px 0px 0px;
          border: 1px 0px 0px 0px;
          transform: rotate(-49.33deg);
          // background: linear-gradient(
          //   120deg,
          //   #f6e52e 0%,
          //   #ed6357 27%,
          //   #e05a81 47%,
          //   #836ee3 69%,
          //   #5251ae 84%,
          //   #173575 100%
          // );
          background: linear-gradient(
            120deg,
            #f6e52e 16%,
            #ed6357 41%,
            #e05a81 51%,
            #836ee3 64%,
            #5251ae 88%,
            #173575 82%
          );
        }
      }
    }
    .dialog-container {
      position: fixed;
      top: 0%;
      left: 0%;
      transition: transform(-50%, -50%);
      z-index: 99999;
    }
  }

  .sectiontwo {
    margin-top: 4rem;
    height: 120vh;
    width: 100vw;
    height: 55rem;
    position: sticky;
    top: -9rem;
    overflow: hidden;
  }
  .sectionthree-default {
    height: 80vh;
  }

  .innerdiv {
    position: relative;
    margin-top: 10rem;
    padding: 5rem 0;
    background: #131313;
    backdrop-filter: blur(94px);
    overflow: visible;
    // z-index: -1;
    .bluebg {
      position: absolute;
      top: -19%;
      left: -16%;
      width: 300px;
      height: 300px;
      gap: 0px;
      opacity: 0.4;
      background: #4285f4;
      filter: blur(94px);
      z-index: -1;
    }
    // .rightBlueBg {
    //   position: absolute;
    //   right: -9%;
    //   width: 300px;
    //   // top: 4%;
    //   height: 300px;
    //   opacity: 0.6;
    //   background: #4285f4;
    //   filter: blur(104px);
    //   z-index: 9;
    // }
  }

  .challengeSection {
    padding: 3rem 0;
    background: #131313;
    backdrop-filter: blur(94px);
    z-index: 1;
  }
  .PortFolio {
    background: #131313;
    backdrop-filter: blur(94px);
  }
  .JoinForms {
    background: #131313;
    backdrop-filter: blur(94px);
    z-index: 99999;
    padding: 4rem 0;
    border-top: 2px solid;

    border-image-source: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 50%,
      rgba(255, 255, 255, 0) 100%
    );
    border-image-slice: 1;
  }
}

@media (max-width: 1024px) {
  .ServicePage {
    .homeOuter {
      .homeSection {
        height: 30rem;
        .leftSection {
          padding-left: 2rem;
          gap: 25px;
          h1 {
            font-size: 2.1rem;
            line-height: 2.3rem;
          }

          button {
            font-size: 14px;
            padding: 10px 20px;
          }
        }
        .rightSection {
          .days {
            margin-left: -50px;
            .day {
              width: 121px;
              height: 110px;
              gap: 15px;
              padding: 18px;
              h4 {
                font-size: 2.1rem;
              }
              h5 {
                font-size: 1rem;
              }
            }
            .hour {
              width: 121px;
              height: 110px;
              gap: 15px;
              padding: 18px;

              h4 {
                font-size: 2.1rem;
              }
              h5 {
                font-size: 1rem;
              }
            }
            p {
              font-weight: 300;
              font-size: 2.1rem;
              line-height: 2rem;
            }
          }
          .rgbBg {
            left: -120px;
            bottom: -153%;
            width: 1027.85px;
            height: 1027.85px;
          }
        }
      }
    }

    .innerdiv {
      .bluebg {
        top: -18%;
        left: -20%;
      }
      .rightBlueBg {
        bottom: -25%;
        right: -19%;
      }
    }
  }
}

@media (max-width: 778px) {
  .ServicePage {
    .JoinForms {
      padding: 0 3.5rem;
    }
  }
}
@media (max-width: 768px) {
  .ServicePage {
    backdrop-filter: blur(94px);

    .homeOuter {
      .header {
        top: 40px;
      }
      .homeSection {
        width: 100%;
        height: 100%;
        flex-direction: column;
        background: radial-gradient(
          102.03% 96.6% at 1.64% 1.92%,
          rgba(13, 93, 225, 0.3) 0%,
          rgba(0, 0, 0, 0) 100%
        );
        .leftSection {
          margin-top: 10rem;
          align-items: center;
          width: 100%;
          border: none;
          padding-left: 0;
          text-align: center !important;
          gap: 25px;
          background: none;

          h1 {
            font-size: 2.1rem;
            line-height: 2rem;
            text-align: center;
          }

          button {
            font-size: 14px;
            padding: 10px 20px;
          }
        }

        .rightSection {
          width: 100%;
          background: none;
          border: none;
          .days {
            margin: 0;
            margin-top: 3rem;
            .day {
              width: 100px;
              height: 100px;
              gap: 5px;
              padding: 18px;
              h4 {
                font-size: 2.1rem;
              }
              h5 {
                font-size: 1rem;
              }
            }
            .hour {
              width: 100px;
              height: 100px;
              gap: 5px;
              padding: 15px;

              h4 {
                font-size: 2.1rem;
              }
              h5 {
                font-size: 1rem;
              }
            }
            p {
              font-weight: 300;
              font-size: 2rem;
              line-height: 1.8rem;
            }
          }
          .rgbBg {
            display: none;
            left: -120px;
            bottom: -153%;
            width: 1027.85px;
            height: 1027.85px;
          }
        }
      }
    }
    .sectiontwo {
      height: 100%;
      position: relative;
      top: 0;
    }
    .sectionthree-default {
      display: none;
    }
    .innerdiv {
      margin: 0;
    }
    .homeOuter,
    .sectiontwo,
    .sectionthree-default,
    .innerdiv,
    .challengeSection,
    .PortFolio,
    .JoinForms {
      background: transparent;
    }
  }
}
@media (max-width: 550px) {
  .ServicePage {
    .homeOuter {
      .homeSection {
        .leftSection {
          margin-top: 6rem;
          gap: 15px;
          h1 {
            font-size: 1.7rem;
            line-height: 1.9rem;
          }
          p {
            text-align: justify;
            margin: auto;
            width: 85%;
            br {
              display: none;
            }
          }
          button {
            margin-top: 1rem;
          }
        }

        .rightSection {
          padding: 0;
          margin-top: -20px;
          .days {
            gap: 10px;
            margin: auto;
            margin-top: 3rem;
            .day {
              width: 80px;
              height: 80px;
              gap: 5px;
              padding: 10px;
              h4 {
                font-size: 1.7rem;
              }
              h5 {
                font-size: 14px;
              }
            }
            .hour {
              width: 80px;
              height: 80px;
              gap: 5px;
              padding: 10px;

              h4 {
                font-size: 1.7rem;
              }
              h5 {
                font-size: 14px;
              }
            }
            p {
              font-size: 1.3rem;
              line-height: 1.1rem;
            }
          }
        }
      }
    }

    .innerdiv {
      // margin-top: 4rem;
      .bluebg {
        width: 150px;
        height: 150px;
        top: -12%;
        left: -10%;
        z-index: -1;
      }
      .rightBlueBg {
        bottom: -25%;
        right: -19%;
      }
    }

    .challengeSection {
      margin: 2rem 0;
    }
    .JoinForms {
      padding: 0 1.5rem;
    }
  }
}
@media (max-width: 490px) {
  .ServicePage {
    background: #000;
    .innerdiv {
      .bluebg {
        top: -5%;
        left: -18%;
        display: none;
      }
      .rightBlueBg {
        bottom: -25%;
        right: -19%;
        display: none;
      }
    }
  }
}
