.OurExperts {
  padding: 4rem 0;
  position: relative;
  z-index: 1;
  .BgTopBox {
    width: 331.45px;
    height: 331.45px;
    border-radius: 50%;
    z-index: -1;
    position: absolute;
    left: 483.6px;
    top: -30%;
    background: linear-gradient(90deg, #f7e72f 0%, #ff7444 100%);

    filter: blur(168px);
  }
  .BgLeft {
    width: 388.27px;
    height: 388.27px;
    border-radius: 50%;
    position: absolute;
    top: 17%;
    left: 250px;
    z-index: -1;
    background: linear-gradient(90deg, #f7e72f 0%, #ff7444 100%);

    filter: blur(200px);
  }
  .BgRight {
    width: 422.99px;
    height: 422.99px;
    z-index: -1;
    border-radius: 50%;
    background: linear-gradient(90deg, #34a853 0%, #5de18b 100%);
    position: absolute;
    top: 120px;
    left: 633.01px;
    filter: blur(232.0120391845703px);
  }
  .innerSection {
    // border: 1px solid red;
    width: 600px;
    height: 250px;
    margin: auto;
    position: relative;
    h1 {
      font-family: "Ubuntu";
      font-size: 2.81rem;
      font-weight: 400;
      line-height: 3rem;
      text-align: center;
      margin: auto;

      background: radial-gradient(
          166.44% 541.54% at 4.98% 17.16%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      width: fit-content;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
    p {
      font-family: "Ubuntu";
      font-size: 2rem;
      font-weight: 300;
      line-height: 2.2rem;
      text-align: center;
      background: radial-gradient(
          188.7% 614.96% at -27.57% -39.53%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      width: fit-content;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      margin: auto;
      margin-top: 2.5rem;
    }
    div {
      .icon1 {
        position: absolute;
        top: -25%;
        left: -25%;
      }
      .icon2 {
        position: absolute;
        right: -33%;
        top: -27%;
      }
      .icon3 {
        position: absolute;
        bottom: -59%;
        left: -14%;
      }
      .icon4 {
        position: absolute;
        bottom: -42%;
        right: -12%;
      }
    }
  }
}

@media (max-width: 1024px) {
  .OurExperts {
    padding: 4rem 0;
    .BgTopBox {
      width: 231.45px;
      height: 231.45px;
    }
    .BgLeft {
      width: 200.27px;
      height: 200.27px;
    }
    .BgRight {
      width: 250.99px;
      height: 250.99px;
    }
    .innerSection {
      width: 450px;
      height: 200px;
      h1 {
        font-size: 2.1rem;
        line-height: 2rem;
      }
      p {
        font-size: 1.5rem;
        line-height: 1.8rem;
        margin-top: 1.5rem;
      }
      div {
        .icon1,
        .icon2,
        .icon3,
        .icon4 {
          img {
            width: 100px;
            height: auto;
          }
        }
        .icon1 {
          top: -17%;
          left: -17%;
        }
        .icon2 {
          right: -21%;
          top: -14%;
        }
        .icon3 {
          bottom: -33%;
          left: -14%;
        }
        .icon4 {
          bottom: -19%;
          right: -8%;
        }
      }
    }
  }
}

@media (max-width: 615px) {
  .OurExperts {
    padding: 2rem 0;
    .BgTopBox {
      width: 111.45px;
      height: 100.45px;
    }
    .BgLeft {
      width: 100.27px;
      height: 100.27px;
    }
    .BgRight {
      width: 150.99px;
      height: 150.99px;
    }
    .innerSection {
      width: 350px;
      height: 100%;
      h1 {
        font-size: 1.7rem;
        line-height: 1.5rem;
      }
      p {
        font-size: 1.1rem;
        line-height: 1.4rem;
        margin-top: 1rem;
        br {
          display: none;
        }
      }
      div {
        .icon1,
        .icon2,
        .icon3,
        .icon4 {
          img {
            width: 50px;
          }
        }
        .icon1 {
          top: -13%;
          left: -7%;
        }
        .icon2 {
          right: -11%;
          top: -3%;
        }
        .icon3 {
          bottom: -33%;
          left: -4%;
        }
        .icon4 {
          bottom: -19%;
          right: -0%;
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .OurExperts {
    padding: 1rem 0;
    padding: 0 3rem;
    .innerSection {
      width: 100%;
      h1 {
        font-size: 1.7rem;
        line-height: 1.5rem;
      }
      p {
        font-size: 1.1rem;
        line-height: 1.4rem;
        margin-top: 1rem;
        br {
          display: none;
        }
      }
      div {
        .icon1,
        .icon2,
        .icon3,
        .icon4 {
          img {
            width: 50px;
          }
        }
        .icon1 {
          top: -13%;
          left: -7%;
        }
        .icon2 {
          right: -11%;
          top: -3%;
        }
        .icon3 {
          bottom: -33%;
          left: -4%;
        }
        .icon4 {
          bottom: -19%;
          right: -0%;
        }
      }
    }
  }
}
