.mainContainerPort {
  .portInfoHeader {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: url("../../assets/images/home/bgbbg.jpg");
    width: 100%;
    z-index: -1;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .innerHead {
      height: 100vh;
      display: flex;
      background: url("../../assets/images/home/bgbbg.jpg");
      width: 100%;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;
      justify-content: center;
      align-items: center;
      position: sticky;
      top: 0px;
      .position {
        position: absolute !important;
        top: 1% !important;
        left: 1% !important;
        z-index: 99 !important;
      }
      h1 {
        z-index: 22;
        font-size: 5rem;
        width: fit-content;
        background: radial-gradient(
          104.21% 376.56% at 50% 50%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        background-clip: text;
        color: transparent;
      }
    }
    .xsx {
      text-align: center;
    }
  }
  .containers {
    margin: 0 auto;
    padding: 30px;
    padding-top: 50px;
    display: flex;
    justify-content: space-between;
    background: #121212;
    overflow: hidden;

    height: auto;
    position: sticky;
    top: 0px;
    .content {
      text-align: start;
      width: 49%;
      height: 100%;
      .header {
        margin-bottom: 20px;

        h1 {
          font-size: 2.5rem;
        }

        p {
          font-size: 1.2rem;
          // color: #666;
        }
      }

      .features,
      .tech-stack,
      .customer-feedback {
        margin: 40px 0;

        h2 {
          font-size: 1.6rem;
          //   width: fit-content;
          //   background: radial-gradient(
          //     104.21% 376.56% at 50% 50%,
          //     #ffffff 0%,
          //     rgba(255, 255, 255, 0) 100%
          //   );
          //   background-clip: text;
          //   color: transparent;
        }

        ul {
          list-style: none;

          li {
            padding: 3px 0;
            line-height: normal;
            font-weight: 200;
            list-style-type: decimal;
          }
        }

        .grid {
          display: flex;
          flex-wrap: wrap;
          justify-content: start;
          gap: 10px;

          .tech-item {
            border: 1px solid rgb(255, 255, 255);
            padding: 7px 15px;
            border-radius: 5px;
            // font-size: 1rem;
            background: linear-gradient(to right, transparent 50%, #f3f3f3 50%);
            background-size: 200% 100%;
            transition: background-position 0.4s ease-in-out,
              color 0.3s ease-in-out, font-weight 0.3s ease-in-out;

            &:hover {
              background-position: -100% 0;
              color: black;
            }
          }
        }
      }

      .customer-feedback {
        blockquote {
          //   font-size: 1.3rem;
          line-height: normal;
          font-style: italic;
          // color: #555;
          margin-bottom: 10px;
        }

        .author {
          //   font-weight: bold;
          // color: #222;
        }
      }

      .contact-section {
        margin-top: 30px;
        display: flex;
        justify-content: space-between;
        padding-right: 1rem;
        gap: 20px;
        a {
          .btn-primary {
            display: inline-block;
            padding: 10px 20px;
            border-radius: 5px;
            // font-size: 1.2rem;
            color: white;
            background: linear-gradient(135deg, transparent 40%, #007bff 60%);
            background-size: 200% 200%;
            border: none;
            transition: background-position 0.1s ease-in-out,
              color 0.1s ease-in-out;

            &:hover {
              background-position: right bottom;
              color: white;
            }
          }
        }

        .contact-button {
          display: inline-block;
          padding: 10px 20px;
          border-radius: 5px;
          // font-size: 1.2rem;
          color: white;
          background: linear-gradient(135deg, transparent 40%, #007bff 60%);
          background-size: 200% 200%;
          border: none;
          transition: background-position 0.1s ease-in-out,
            color 0.1s ease-in-out;

          &:hover {
            background-position: right bottom;
            color: white;
          }
        }
      }
    }
    // bg ......
    .bg {
      background: aqua;
      height: 80%;
      width: 40%;
      position: absolute;
      right: 32%;
      top: -13%;
      border-radius: 50%;
      z-index: -1;
      filter: blur(250px);
    }
    .image-container {
      margin-top: 7rem;
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      flex-wrap: wrap;
      row-gap: 10px;

      img {
        width: 48%;
        height: auto;
        object-fit: cover;
        // border-radius: 8px;
      }
      .modal {
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.637);
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1000;
      }

      .modal-content {
        position: relative;
        background: rgba(255, 255, 255, 0.007);
        padding: 10px;
        background: white;
        border-radius: 10px;
        max-width: 90%;
        max-height: 100%;
        overflow: hidden;
        img {
          border-radius: 10px;

          height: 100%;
          width: 100%;
        }
      }

      button {
        background: #f44336;
        color: white;
        border: none;
        padding: 5px 8px;
        cursor: pointer;
      }
    }
  }
}

/* Responsive Design */
@media (max-width: 800px) {
  .mainContainerPort {
    .portInfoHeader {
      .innerHead {
        .techporthead {
          font-size: 3rem;
        }
      }
      .xsx {
        text-align: center;
      }
    }
    .containers {
      padding: 20px;
      display: flex;
      justify-content: center;
      gap: 20px;
      flex-direction: column;

      .content {
        width: 100%;
        border: 1px solid red;
        .header {
          margin-bottom: 20px;

          h1 {
            font-size: 2.5rem;
          }

          p {
            font-size: 1.2rem;
            // color: #666;
          }
        }

        .features,
        .tech-stack,
        .customer-feedback {
          margin: 40px 0;

          h2 {
            font-size: 1.6rem;
            //   width: fit-content;
            //   background: radial-gradient(
            //     104.21% 376.56% at 50% 50%,
            //     #ffffff 0%,
            //     rgba(255, 255, 255, 0) 100%
            //   );
            //   background-clip: text;
            //   color: transparent;
          }

          ul {
            list-style: none;

            li {
              padding: 3px 0;
              line-height: normal;
              font-weight: 200;
              list-style-type: decimal;
            }
          }

          .grid {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            gap: 10px;

            .tech-item {
              border: 1px solid rgb(255, 255, 255);
              padding: 7px 15px;
              border-radius: 5px;
              // font-size: 1rem;
              background: linear-gradient(
                to right,
                transparent 50%,
                #f3f3f3 50%
              );
              background-size: 200% 100%;
              transition: background-position 0.4s ease-in-out,
                color 0.3s ease-in-out, font-weight 0.3s ease-in-out;

              &:hover {
                background-position: -100% 0;
                color: black;
              }
            }
          }
        }

        .customer-feedback {
          blockquote {
            //   font-size: 1.3rem;
            line-height: normal;
            font-style: italic;
            // color: #555;
            margin-bottom: 10px;
          }

          .author {
            //   font-weight: bold;
            // color: #222;
          }
        }

        .contact-section {
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          padding-right: 1rem;
          gap: 20px;
          a {
            .btn-primary {
              display: inline-block;
              padding: 10px 20px;
              border-radius: 5px;
              // font-size: 1.2rem;
              color: white;
              background: linear-gradient(135deg, transparent 40%, #007bff 60%);
              background-size: 200% 200%;
              border: none;
              transition: background-position 0.1s ease-in-out,
                color 0.1s ease-in-out;

              &:hover {
                background-position: right bottom;
                color: white;
              }
            }
          }

          .contact-button {
            display: inline-block;
            padding: 10px 20px;
            border-radius: 5px;
            // font-size: 1.2rem;
            color: white;
            background: linear-gradient(135deg, transparent 40%, #007bff 60%);
            background-size: 200% 200%;
            border: none;
            transition: background-position 0.1s ease-in-out,
              color 0.1s ease-in-out;

            &:hover {
              background-position: right bottom;
              color: white;
            }
          }
        }
      }
      // bg ......
      .bg {
        background: aqua;
        height: 80%;
        width: 40%;
        position: absolute;
        right: 32%;
        top: -13%;
        border-radius: 50%;
        z-index: -1;
        filter: blur(250px);
      }
      .image-container {
        margin-top: 7rem;
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 10px;

        img {
          width: 48%;
          height: auto;
          object-fit: cover;
          // border-radius: 8px;
        }
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.637);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          position: relative;
          background: rgba(255, 255, 255, 0.007);
          padding: 10px;
          background: white;
          border-radius: 10px;
          max-width: 90%;
          max-height: 100%;
          overflow: hidden;
          img {
            border-radius: 10px;

            height: 100%;
            width: 100%;
          }
        }

        button {
          background: #f44336;
          color: white;
          border: none;
          padding: 5px 8px;
          cursor: pointer;
        }
      }
    }
  }

  /* Responsive Design */

  .mainContainerPort {
    .portInfoHeader {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      background: url("../../assets/images/home/bgbbg.jpg");
      width: 100%;
      z-index: -1;
      background-repeat: no-repeat;
      background-position: center;
      background-size: cover;

      .innerHead {
        height: 100vh;
        display: flex;
        background: url("../../assets/images/home/bgbbg.jpg");
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        justify-content: center;
        align-items: center;
        position: sticky;
        top: 0px;
        .position {
          position: absolute !important;
          top: 1% !important;
          left: 1% !important;
          z-index: 99 !important;
        }
        h1 {
          z-index: 22;
          font-size: 5rem;
          width: fit-content;
          background: radial-gradient(
            104.21% 376.56% at 50% 50%,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
          );
          background-clip: text;
          color: transparent;
        }
      }
      .xsx {
        text-align: center;
      }
    }
    .containers {
      margin: 0 auto;
      padding: 30px;
      padding-top: 50px;
      display: flex;
      justify-content: space-between;
      background: #121212;
      overflow: hidden;

      height: auto;
      position: sticky;
      top: 0px;
      .content {
        text-align: start;
        width: 49%;
        height: 100%;
        .header {
          margin-bottom: 20px;

          h1 {
            font-size: 2.5rem;
          }

          p {
            font-size: 1.2rem;
            // color: #666;
          }
        }

        .features,
        .tech-stack,
        .customer-feedback {
          margin: 40px 0;

          h2 {
            font-size: 1.6rem;
            //   width: fit-content;
            //   background: radial-gradient(
            //     104.21% 376.56% at 50% 50%,
            //     #ffffff 0%,
            //     rgba(255, 255, 255, 0) 100%
            //   );
            //   background-clip: text;
            //   color: transparent;
          }

          ul {
            list-style: none;

            li {
              padding: 3px 0;
              line-height: normal;
              font-weight: 200;
              list-style-type: decimal;
            }
          }

          .grid {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            gap: 10px;

            .tech-item {
              border: 1px solid rgb(255, 255, 255);
              padding: 7px 15px;
              border-radius: 5px;
              // font-size: 1rem;
              background: linear-gradient(
                to right,
                transparent 50%,
                #f3f3f3 50%
              );
              background-size: 200% 100%;
              transition: background-position 0.4s ease-in-out,
                color 0.3s ease-in-out, font-weight 0.3s ease-in-out;

              &:hover {
                background-position: -100% 0;
                color: black;
              }
            }
          }
        }

        .customer-feedback {
          blockquote {
            //   font-size: 1.3rem;
            line-height: normal;
            font-style: italic;
            // color: #555;
            margin-bottom: 10px;
          }

          .author {
            //   font-weight: bold;
            // color: #222;
          }
        }

        .contact-section {
          margin-top: 30px;
          display: flex;
          justify-content: space-between;
          padding-right: 1rem;
          gap: 20px;
          a {
            .btn-primary {
              display: inline-block;
              padding: 10px 20px;
              border-radius: 5px;
              // font-size: 1.2rem;
              color: white;
              background: linear-gradient(135deg, transparent 40%, #007bff 60%);
              background-size: 200% 200%;
              border: none;
              transition: background-position 0.1s ease-in-out,
                color 0.1s ease-in-out;

              &:hover {
                background-position: right bottom;
                color: white;
              }
            }
          }

          .contact-button {
            display: inline-block;
            padding: 10px 20px;
            border-radius: 5px;
            // font-size: 1.2rem;
            color: white;
            background: linear-gradient(135deg, transparent 40%, #007bff 60%);
            background-size: 200% 200%;
            border: none;
            transition: background-position 0.1s ease-in-out,
              color 0.1s ease-in-out;

            &:hover {
              background-position: right bottom;
              color: white;
            }
          }
        }
      }
      // bg ......
      .bg {
        background: aqua;
        height: 80%;
        width: 40%;
        position: absolute;
        right: 32%;
        top: -13%;
        border-radius: 50%;
        z-index: -1;
        filter: blur(250px);
      }
      .image-container {
        margin-top: 7rem;
        width: 50%;
        height: 100%;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-wrap: wrap;
        row-gap: 10px;

        img {
          width: 48%;
          height: auto;
          object-fit: cover;
          // border-radius: 8px;
        }
        .modal {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(0, 0, 0, 0.637);
          display: flex;
          justify-content: center;
          align-items: center;
          z-index: 1000;
        }

        .modal-content {
          position: relative;
          background: rgba(255, 255, 255, 0.007);
          padding: 10px;
          background: white;
          border-radius: 10px;
          max-width: 90%;
          max-height: 100%;
          overflow: hidden;
          img {
            border-radius: 10px;

            height: 100%;
            width: 100%;
          }
        }

        button {
          background: #f44336;
          color: white;
          border: none;
          padding: 5px 8px;
          cursor: pointer;
        }
      }
    }
  }

  /* Responsive Design */
}
