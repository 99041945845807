* {
  color: white;
}
.FAQ {
  h1 {
    font-family: "Ubuntu";
    font-size: 2.81rem;
    font-weight: 400;
    line-height: 3rem;
    text-align: center;
    background: radial-gradient(
        85.42% 277.92% at 40.86% 30.88%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: fit-content;
    margin: auto;
  }
  .accordion {
    gap: 0;
    margin-top: 2rem;
    background: transparent;
    .accordion-item {
      margin-top: 15px;
      background: #f6f6f61a;
      border: none;
      border: 1px solid #f6f6f633;
      outline: none;
      border-radius: 7px;
      // padding: 10px;

      .accordion-header {
        .accordion-button {
          color: #ffffff;
          font-family: "Ubuntu";
          font-size: 16px;
          font-weight: 400;
          line-height: 18.38px;
          text-align: left;
          background: transparent;
          display: flex;
          justify-content: space-between;
          // Remove Bootstrap's default arrow if not needed
          &::after {
            display: inline-block; /* Ensures the arrow is visible */
            margin-left: auto; /* Push arrow to the right */
            width: 10px;
            height: 10px;
            border: solid white; /* Set arrow color */
            border-width: 0 2px 2px 0;
            transform: rotate(45deg); /* Default pointing down */
            transition: transform 0.3s ease, color 0.3s ease; /* Smooth rotation and color change */
            content: ""; /* Necessary to display the pseudo-element */
          }

          &:focus {
            box-shadow: none;
            outline: none;
            border: none;
          }

          &:not(.collapsed)::after {
            border: none;

            transform: rotate(
              -135deg
            ); /* Rotate to pointing up when expanded */
          }

          &:not(.collapsed) {
            background-color: transparent; /* Optional: no background */
          }
        }
        .custom-accordion-button {
          border-top: none !important; /* Removes the top border */
          box-shadow: none !important; /* Ensures no shadow is applied */
        }

        .custom-accordion-button:focus,
        .custom-accordion-button:active {
          outline: none !important;
          border-top: none !important;
          box-shadow: none !important;
        }
      }

      .accordion-collapse {
        .accordion-body {
          p {
            font-family: "Maven Pro";
            font-size: 14px;
            font-weight: 400;
            line-height: 16.45px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .FAQ {
    h1 {
      font-size: 2.1rem;
      line-height: 2.3rem;
    }
  }
}
@media (max-width: 500px) {
  .FAQ {
    h1 {
      font-size: 1.7rem;
      line-height: 2rem;
    }
    .accordion {
      margin-top: 1rem;

      .accordion-item {
        .accordion-header {
          .accordion-button {
            font-size: 15px;
          }
        }
        .accordion-collapse {
          .accordion-body {
            p {
              font-size: 12px;
              line-height: 15.45px;
            }
          }
        }
      }
    }
  }
}
