.AllBlogs {
  .news-event {
    display: flex;
    justify-content: center;
    gap: 30px;

    .news-heading {
      font-family: "Ubuntu";
      font-size: 2.78rem;
      font-weight: 400;
      display: inline;
      margin: 0;
      padding: 0;
      background: radial-gradient(
        157.47% 1012.75% at 6.55% 17.65%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
    .news-line {
      width: 90%;
      display: inline-block;
      height: 1px;
      border: 1px solid #f6f6f633;
      margin-top: 40px;
    }
  }
  .allblog-section {
    .Blogs-Header ul {
      width: 100%;
      margin: auto;
      margin-top: 3rem;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-left: 0;
      li {
        cursor: pointer;
        padding-bottom: 10px;
        list-style: none;
        position: relative;
        text-align: center;
        width: 12%;
        @media (max-width: 1300px) {
          width: fit-content;
        }
      }
     
    }

    .Blogs-Header ul li.active {
      font-weight: bold;
      // border-bottom: 2px solid #4285f4;
      color: #4285f4;
    }
    .Blogs-Header ul li.active::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 2px; /* Same height as the bottom border */
      background-color: #3d7ab9; /* Same color as the border */
      box-shadow: 0 0 10px #3d7ab9; /* Apply the shadow here */
    }
    .allblog-card {
      overflow: auto;
      margin-top: 20px;
      .allcard-inner {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        gap: 25px;
        flex-wrap: wrap;

        .allblog-single-card {
          cursor: pointer;
          width: 30%;
          padding: 10px 0;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          border-radius: 14px;
          @media (max-width: 768px) {
            width: 300px;
          }
          &:hover {
            transform: translateY(-5px);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
          }
          img {
            width: 100%;
          }
          .allblog-text {
            padding: 10px 10px;
            .allblog-title {
              font-family: "Ubuntu";
              font-size: 1.4rem;
              font-weight: 400;
              line-height: 27.58px;
            }
            .allauther-container {
              justify-content: space-between;
              .allauther,
              .alldate {
                font-family: "Maven Pro";
                font-size: 14px;
                font-weight: 400;
                line-height: 16.45px;
                color: #f6f6f66e;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .AllBlogs {
    .news-event {
      gap: 30px;

      .news-heading {
        margin: 0;
        font-size: 2.1rem;
      }
      .news-line {
        width: 100%;
        margin: 0;
        margin-top: 35px;
        margin-left: -15px;
      }
    }
    .allblog-section {
      .Blogs-Header ul {
        margin-top: 3rem;
      }

      .allblog-card {
        overflow: auto;
        margin-top: 20px;
        .allcard-inner {
          gap: 0;
          column-gap: 20px;

          .allblog-single-card {
            width: 30%;
            height: 100%;
            padding: 0;

            @media (max-width: 768px) {
              width: 45%;
            }

            img {
              width: 100%;
            }
            .allblog-text {
              padding: 10px 10px;
              .allblog-title {
                font-size: 1.2rem;

                line-height: 23.58px;
              }
              .allauther-container {
                .allauther,
                .alldate {
                  font-size: 12px;

                  line-height: 14.45px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .AllBlogs {
    .news-event {
      gap: 30px;
      .news-heading {
        margin: 0;
        font-size: 1.5rem;
      }
      .news-line {
        width: 100%;

        margin-top: 18px;
        margin-left: -15px;
      }
    }
    .allblog-section {
      .Blogs-Header ul {
        padding: 0;
        margin-top: 1rem;
        li {
          font-size: 14px;
        }
      }

      .allblog-card {
        overflow: auto;
        margin-top: 25px;
        .allcard-inner {
          gap: 0;
          column-gap: 0px;
          justify-content: space-between;
          .allblog-single-card {
            // width: 30%;
            height: 100%;
            padding: 0;

            @media (max-width: 768px) {
              width: 49%;
            }

            img {
              width: 100%;
            }
            .allblog-text {
              padding: 10px 10px;
              .allblog-title {
                font-size: 1.1rem;

                line-height: 19.58px;
              }
              .allauther-container {
                margin-top: 0;
                .allauther,
                .alldate {
                  font-size: 12px;

                  line-height: 14.45px;
                }
              }
            }
          }
        }
      }
    }
  }
}
@media (max-width: 420px) {
  .AllBlogs {
    .news-event {
      gap: 30px;
      .news-heading {
        margin: 0;
        font-size: 1.5rem;
      }
      .news-line {
        width: 100%;

        margin-top: 18px;
        margin-left: -15px;
      }
    }
    .allblog-section {
      .Blogs-Header ul {
        padding: 0;
        margin-top: 1rem;
        li {
          font-size: 14px;
        }
      }

      .allblog-card {
        overflow: auto;
        margin-top: 25px;
        .allcard-inner {
          gap: 0;
          column-gap: 0px;
          justify-content: space-between;
          .allblog-single-card {
            height: 100%;
            padding: 0;

            img {
              width: 100%;
            }
            .allblog-text {
              padding: 8px;
              .allblog-title {
                font-size: 1rem;

                line-height: 19.58px;
              }
              .allauther-container {
                margin-top: 0;
                .allauther,
                .alldate {
                  font-size: 12px;

                  line-height: 14.45px;
                }
              }
            }
          }
        }
      }
    }
  }
}
