.fullStack {
  h1 {
    width: fit-content;
    margin: auto;
    text-align: center;
    background: var(--gradient-text);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .heading-dis {
    font-size: 14px;
    margin-top: 1.6rem;
    text-align: center;
    line-height: 1rem;
    color: #f6f6f6;
  }

  .fullStackOuter {
    width: 90%;
    margin: auto;
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 15px;
    // .whitebg {
    //   height: 60px;
    //   width: 60px;
    //   border-radius: 50%;
    //   background: #f6f6f6;
    //   filter: blur(30px);
    // }

    .whitebg {
      position: absolute;
      height: 80px;
      width: 80px;
      background: #f6f6f6;
      filter: blur(30px);
      border-radius: 50%;
      transition: transform 0.3s ease;
      pointer-events: none;
    }
    .fullstackCard {
      width: 352px;
      height: 194px;
      border-image-slice: 1;
      background: #c2c2c2;
      border-radius: 27px;
      backdrop-filter: blur(65px);
      border: 1px solid #f6f6f633;
      background: #f6f6f612;
      display: flex;
      justify-content: center;
      align-items: start;
      flex-direction: column;
      overflow: hidden;
      gap: 10px;
      padding: 1.5rem;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
      }
      h4 {
        font-size: 1.4rem;
        font-family: var(--headingFont);
        font-family: "Ubuntu";
      }
      .dis {
        color: #ffffffcc;
        text-align: start;
        font-family: var(--paragraphFont);
        font-size: 14px;
        line-height: 1rem;
        font-family: "Maven Pro";
      }
    }
  }
}

@media (max-width: 1200px) {
  .fullStack {
    .fullStackOuter {
      width: 90%;
      .whitebg {
        display: none;
      }
    }
  }
}

@media (max-width: 1024px) {
  .fullStack {
    h1 {
      font-size: 2.1rem;
      line-height: 2rem;
    }
    .heading-dis {
      font-size: 14px;
      margin-top: 1rem;
    }

    .fullStackOuter {
      width: 96%;
      margin: auto;
      margin-top: 2rem;

      gap: 20px;
      .whitebg {
        height: 50px;
        width: 50px;
      }
      .fullstackCard {
        height: 154px;
        width: 36%;
        height: 200px;
        gap: 5px;
        padding: 1.5rem;
        @media (max-width: 750px) {
          width: 46%;
        }

        h4 {
          font-size: 1.2rem;
        }
        .dis {
          font-size: 13px;
          line-height: 1rem;
        }
      }
    }
  }
}

@media (max-width: 580px) {
  .fullStack {
    h1 {
      font-size: 1.7rem;
      line-height: 1.7rem;
    }
    .heading-dis {
      font-size: 13px;
      margin-top: 1rem;
      width: 80%;
      margin: auto;
      margin-top: 10px;
      br {
        display: none;
      }
    }
  }
}

@media (max-width: 492px) {
  .fullStack {
    .fullStackOuter {
      gap: 20px;
      .fullstackCard {
        height: 164px;
        width: 80%;
        gap: 5px;
        .whitebg {
          display: none;
        }

        h4 {
          font-size: 1.2rem;
        }
        .dis {
          font-size: 13px;
          line-height: 1rem;
        }
      }
    }
  }
}
