.gourneycontainer {
  position: relative;
  z-index: 1;
  height: 500px;
  padding: 0;

  .leftbg {
    width: 446px;
    height: 446px;
    left: -344px;
    gap: 0px;
    background: #ea8335;
    filter: blur(267px);
    border-radius: 50%;
    position: absolute;
    top: 10%;
    z-index: -1;
    .innerbg {
      width: 257.98px;
      height: 257.98px;
      top: 2131.01px;
      left: -249.99px;
      gap: 0px;
      background: #ea4335;
      filter: blur(324px);
      filter: blur(194px);
    }
  }
  .GrowthJourney {
    z-index: 5;
    position: relative;

    h1 {
      padding-left: 4rem;

      font-family: "Ubuntu";
      font-size: 2.75rem;
      font-weight: 400;
      line-height: 3.78rem;
      text-align: left;

      background: radial-gradient(
          166.44% 541.54% at 4.98% 17.16%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
      width: fit-content;
      line-height: 51px;
    }
    .cardOuter {
      // overflow-x: auto;
      // scrollbar-width: none;
      margin-top: 4rem;
      z-index: 22;
      padding-bottom: 2rem;
      padding-left: 4rem;

      .card-section {
        display: flex;
        width: max-content;
        gap: 40px;
        padding: 2rem 0;

        
        border-bottom: 2px solid #f6f6f633;
        .cardcontainers {
          position: relative;
          
          .card-item {
            display: flex;
            align-items: center;
            gap: 30px;
            width: 477px;
            height: 155px;
            overflow: hidden;
            padding: 10px;
            position: relative;
            border: 1px solid rgba(255, 0, 0, 0.199);
            border-radius: 27px;
            border: 1px solid #f6f6f633;
            background: #f6f6f612;

            .bgwhite {
              width: 80px;
              height: 80px;
              position: absolute;
              top: -29%;
              left: -5%;
              background: #f6f6f6;
              filter: blur(64px);
              top: 0;
            }

            img {
              height: 100px;
              width: 100px;
              object-fit: cover;
            }
            .information {
              h3 {
                color: #ffffff;
                font-size: 1.4rem;
                font-family: "Ubuntu";
                font-weight: 400;
              }
              p {
                font-size: 14px;
                color: #ffffffcc;
                font-family: "Maven Pro";
                font-weight: 400;
                line-height: 16.45px;
              }
            }
          }
          .phase {
            z-index: 2;
            height: 24px;
            width: 95px;
            font-family: "Ubuntu";
            font-size: 14px;
            font-weight: 400;
            color: #ffffff;
            background: radial-gradient(
                78.17% 203.96% at 6.34% 25.68%,
                rgba(255, 255, 255, 0.3) 0%,
                rgba(255, 255, 255, 0.1) 100%
              )
              /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
            border-radius: 8px;
            border: 1px solid #ffffff33;
            backdrop-filter: blur(15px);
            display: flex;
            justify-content: center;
            align-items: center;
            margin: auto;
            position: absolute;
            bottom: -29%;
            margin-left: 40%;
          }
        }
      }

      .whiteline {
        margin-top: 1rem;
        width: 2093px;
        height: 15px;

        display: flex;
        align-items: center;

        z-index: -1;

        // .lineOuter {
        //   display: flex;
        //   align-items: center;
        //   width: 492px;
        //   width: 100%;

        //   .line {
        //     height: 2px;
        //     background: #f6f6f68c;
        //     width: 50%;
        //   }
        //   button {
        //     z-index: 2;
        //     height: 24px;
        //     width: 95px;
        //     font-family: "Ubuntu";
        //     font-size: 14px;
        //     font-weight: 400;
        //     color: #ffffff;
        //     background: radial-gradient(
        //         78.17% 203.96% at 6.34% 25.68%,
        //         rgba(255, 255, 255, 0.3) 0%,
        //         rgba(255, 255, 255, 0.1) 100%
        //       )
        //       /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        //     border-radius: 8px;
        //     border: 1px solid #ffffff33;
        //     backdrop-filter: blur(15px);
        //     display: flex;
        //     justify-content: center;
        //     align-items: center;

        //   }
        // }
      }
    }
  }

  .rightbg {
    position: absolute;
    width: 314px;
    height: 314px;
    width: 446px;
    height: 446px;
    top: 20%;
    right: -30%;
    z-index: -1;
    background: #4285f4;
    filter: blur(267px);
    border-radius: 50%;
  }
}

@media (max-width: 1024px) {
  .gourneycontainer {
    .leftbg {
      width: 300px;
      height: 300px;
      background: #ea8335;
      filter: blur(194px);
      border-radius: 50%;
      position: absolute;
      top: 10%;
      z-index: -1;
      .innerbg {
        width: 157.98px;
        height: 157.98px;
        // top: 2131.01px;
        // left: -299.99px;
        gap: 0px;
        background: #ea4335;
        filter: blur(324px);
        filter: blur(194px);
      }
    }
    .GrowthJourney {
      z-index: 5;

      h1 {
        padding-left: 4rem;
        font-size: 2.1rem;
        line-height: 2rem;
        line-height: 41px;
      }
      .cardOuter {
        margin-top: 3rem;
        .card-section {
          gap: 40px;
          .cardcontainers {
            .card-item {
              gap: 20px;
              width: 400px;
              height: 155px;
            }
          }
        }

        .whiteline {
          width: 1780px;
        }
      }
    }

    .rightbg {
      position: absolute;
      width: 300px;
      height: 300px;
      top: 20%;
      right: -15%;
      z-index: -1;
      background: #4286f493;
      filter: blur(194px);
      border-radius: 50%;
    }
  }
}

@media (max-width: 768px) {
  .gourneycontainer {
    height: 100%;
    .GrowthJourney {
      .cardOuter {
        overflow: hidden;
        overflow-x: auto !important;
        .whiteline {
          width: 1720px;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .gourneycontainer {
    .leftbg {
      width: 246px;
      height: 246px;
      gap: 0px;

      background: #ea8335;
      filter: blur(194px);
      border-radius: 50%;
      position: absolute;
      top: 10%;
      z-index: -1;
      .innerbg {
        width: 257.98px;
        height: 257.98px;
        gap: 0px;
        background: #ea4335;
        filter: blur(324px);
        filter: blur(194px);
      }
    }
    .GrowthJourney {
      z-index: 5;

      h1 {
        padding-left: 0;
        font-size: 1.7rem;
        line-height: 1.9rem;
        margin: auto;
      }
      .cardOuter {
        margin-top: 1rem;
        padding-left: 2rem;
        overflow-y: hidden;

        .card-section {
          gap: 20px;
          justify-content: center;
          padding: 0;
          margin-top: 1.5rem;
          padding-bottom: 2rem;

          .cardcontainers {
            padding: 0;
            margin: 0;
            .card-item {
              padding: 1rem;
              border-radius: 20px;
              gap: 20px;
              width: 250px;
              height: 100%;
              flex-direction: column;
              .bgwhite {
                width: 80px;
                height: 80px;
                position: absolute;
                top: -29%;
                left: -5%;
                background: #f6f6f6;
                filter: blur(64px);
                top: 0;
              }
              img {
                border-radius: 15px;
                // height: 50%;
                // width: 50%;
                object-fit: cover;
              }
            }
            .phase {
             
              bottom: -16%;
              margin-left: 30%;
              @media (max-width: 390px) {
                margin-left: 32%;
                
              }
            }
          }
        }

        .whiteline {
          width: 1060px;
          .lineOuter {
            button {
              font-size: 12px;
            }
          }
        }
      }
    }

    .rightbg {
      width: 246px;
      height: 246px;
      top: 20%;
      right: -15%;
      z-index: -1;
      background: #4286f493;
      filter: blur(84px);
      filter: blur(194px);
      border-radius: 50%;
    }
  }
}
