.tech-list-main {
  height: 100%;
  width: 100%;
  margin-top: 4rem;

  .single-tech-outer {
    width: 95%;
    height: 176px;
    border-bottom: 1px solid #f6f6f633;
    border-width: 10%;
    margin: auto;
    z-index: 1;
    position: relative;
    &:hover {
      .white-glow-bg {
        opacity: 1;
      }
    }
    .single-tech-inner {
      width: 105%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: auto;
      position: relative;
      padding: 1.1rem 10px;
      border-radius: 10px;
      z-index: 999;
      transition: all 0.9s ease;
      cursor: pointer;
      // overflow: hidden;

      &:hover {
        background: #f6f6f61a;
        .whitebgoverlay {
          opacity: 1;
        }
      }
      .whitebgoverlay {
        overflow-y: hidden;
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translate(-50%);
        height: 302px;
        width: 100%;
        height: 100%;
        z-index: -1;
        opacity: 1;
        transition: all 0.9s ease;

        .white-glow-bg {
          position: absolute;
          top: -60%;
          left: 50%;
          transform: translate(-50%);
          height: 302px;
          width: 302px;
          height: 100%;
          border-radius: 50%;
          background: #f6f6f6;
          filter: blur(135px);
        }
      }

      .tech-list-number {
        padding-right: 10px;
        display: flex;
        justify-content: start;
        .list-single-number {
          font-family: "Nanum Gothic Coding", sans-serif;
          font-size: 1.2rem;
          font-weight: 400;
          text-align: left;
          color: #f6f6f699;
        }
      }
      .tech-list-heading {
        width: 35%;
        display: flex;
        z-index: 2;
        position: relative;
        transition: all 0.3s linear;
        .single-heading {
          z-index: 2;

          font-family: "Ubuntu", sans-serif;
          font-size: 44.59px;
          font-weight: 400;
          line-height: 51.24px;
          text-align: left;
          background: radial-gradient(
            157.47% 1012.75% at 6.55% 17.65%,
            #ffffff 0%,
            rgba(255, 255, 255, 0) 100%
          );
          background-clip: text;
          color: transparent;
        }
      }

      .hover-overlay {
        width: 270px;
        // display: Block;
        // position: relative;
        // opacity: 0;
        // z-index: 10;
        transition: opacity 0.3s linear;
        // ofter in line style
        position: absolute;
        transform: translate(-40%, -50%);
        pointer-events: none;
        .imageOnHover {
          z-index: 1;
          position: absolute;
          top: -39%;
          height: 210px;
          width: 270px;
          transform: scale(0);
          object-fit: fit-content;
          transition: transform 0.3s ease !important;
        }
      }
      .tech-list-content {
        display: flex;
        flex-direction: column;

        .tech-content-inner {
          width: 365px;
          height: 20px;
          gap: 0px;
          opacity: 1;
          display: flex;
          margin-bottom: 1rem;
          .circle-icon {
            width: 20px;
            height: 20px;
            padding: 1.67px;
            margin-right: 10px;
          }
          .tech-para {
            font-family: "Maven Pro", sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 18.8px;
            text-align: left;
            color: #f6f6f6;
          }
        }
      }
    }

    .single-tech-inner:hover {
      .hover-overlay {
        opacity: 1;
        display: inline-block;
        .imageOnHover {
          transform: scale(1);
        }
      }
    }
  }
  .single-tech-outer:nth-child(1) {
    border-top: 1px solid #f6f6f633;
  }
}

@media (max-width: 1200px) {
  .tech-list-main {
    height: 100%;
    width: 100%;
    margin-top: 8rem;
    .single-tech-outer {
      width: 95%;
      height: 100%;

      .single-tech-inner {
        width: 105%;
        padding: 1.1rem 20px;
        position: relative;
        .tech-list-number {
          .list-single-number {
            font-size: 1.5rem;
            margin: 0;
          }
        }
        .tech-list-heading {
          width: 100%;
          padding: 0;

          .single-heading {
            font-size: 2.1rem;
            line-height: 35.24px;
            margin: 0;

            br {
              display: none;
            }
          }
        }
        .hover-overlay {
          height: 100%;
          width: 170px;
          // position: absolute;
          top: 90% !important;
          width: 250px;
          // right: 5%;
          .imageOnHover {
            // top: 0;
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
          @media (max-width: 850px) {
            width: 190px;
            height: 190px;
            top: 0%;
          }
        }
        .tech-list-content {
          margin-top: 20px;

          .tech-content-inner {
            width: 100%;
            height: 25px;

            margin-bottom: 0px;
            .circle-icon {
              width: 20px;
              height: 20px;
              padding: 1.67px;
              margin-right: 10px;
            }
            .tech-para {
              font-size: 16px;
              line-height: 18.8px;
            }
          }
        }
      }
    }
    //
    .single-tech-outer:nth-child(1) {
      border-bottom: none;
    }
    .single-tech-outer:nth-child(2) {
      margin-top: 10px;
      .tech-list-heading{
        border-bottom: 1px solid #f6f6f633;
        padding-bottom: 10px;

      }
    }
    .single-tech-outer:nth-child(3) {
      margin-top: 10px !important;
    }
    .single-tech-outer:nth-child(4) {
      border-bottom: none;
      margin-top: 10px;
    }
  }
}
@media (max-width: 745px) {
  .tech-list-main {
    height: 100%;
    width: 100%;
    margin-top: 8rem;
    .single-tech-outer {
      width: 95%;
      height: 100%;

      .single-tech-inner {
        width: 105%;
        padding: 1.1rem 20px;
        border-radius: 10px;
        overflow: hidden;

        .tech-list-number {
          .list-single-number {
            font-size: 1.5rem;
            margin: 0;
          }
        }
        .tech-list-heading {
          width: 100%;
          padding: 0;

          .single-heading {
            font-size: 2.1rem;
            line-height: 35.24px;
            margin: 0;

            br {
              display: none;
            }
          }
        }
        .hover-overlay {
          height: 100%;
          width: 200px;
          position: relative;
          top: 2%;
          left: 2%;
          opacity: 1;

          .imageOnHover {
            transform: scale(1);
            height: 100%;
            width: 200px;
            position: relative;
            top: 0;
            left: 0;
            object-fit: contain;
          }
        }
        .tech-list-content {
          margin-top: 20px;

          .tech-content-inner {
            width: 100%;
            height: 25px;

            margin-bottom: 0px;
            .circle-icon {
              width: 20px;
              height: 20px;
              padding: 1.67px;
              margin-right: 10px;
            }
            .tech-para {
              font-size: 16px;
              line-height: 18.8px;
            }
          }
        }
      }
    }
    .single-tech-outer:nth-child(1) {
      border-bottom: none;
    }
    .single-tech-outer:nth-child(2) {
      margin-top: 15px;
    }
    .single-tech-outer:nth-child(3) {
      margin-top: 15px !important;
    }
    .single-tech-outer:nth-child(4) {
      margin-top: 15px;
    }
  }
}

@media (max-width: 768px) {
  .tech-list-main {
    margin-top: 1rem;
    text-align: start;
    position: relative;
    .single-tech-outer {
      position: relative !important;
      width: 100%;
      height: 100%;
      border: none !important;
      border-bottom: 1px solid #f6f6f633;

      .single-tech-inner {
        margin: 0 !important;
        padding: 0;
        gap: 10px;
        width: 100%;
        height: 100%;
        padding: 1rem 1rem;
        padding-right: 1.5rem;
        align-items: start;
        justify-content: start;
        transform: none;
        left: 0;
        transition: all 0.5s ease;
        .tech-list-number {
          .list-single-number {
            font-size: 1.5rem;
            margin: 0;
          }
        }
        .hover-overlay {
          position: relative !important;
          top: 70px !important;
          left: 85px !important;
          @media (max-width: 480px) {
            top: 100px !important;
            left: 130px !important;
          }
          .imageOnHover {
            height: 100%;
            width: 100%;
          }
          @media (max-width: 480px) {
            height: 100%;
            width: 95%;
          }
        }
        .tech-list-heading {
          text-align: start !important;

          .single-heading {
            width: 100%;
            text-align: start;
            font-size: 1.5rem;
            line-height: 26.24px;
            padding-bottom: 10px;
            border-bottom: 1px solid #ffffff6c;

            br {
              display: none;
            }
          }
        }
        .tech-list-content {
          margin-top: 10px;
          width: 100%;

          .tech-content-inner {
            width: 100%;
            height: 25px;
            height: 100%;

            margin-bottom: 0px;
            .circle-icon {
              width: 20px;
              height: 20px;
              padding: 1.67px;
              margin-right: 10px;
            }
            .tech-para {
              font-size: 16px;
              line-height: 18.8px;
            }
          }
        }
      }
    }
    .single-tech-inner:hover {
      padding-left: 1.5rem;
      .hover-overlay {
        margin-top: 10px;
      }
    }

    .single-tech-inner {
      top: 120%;
    }
    .single-tech-outer:nth-child(3) {
      margin-top: 2.5rem;
    }
  }
}
@media (max-width: 430px) {
  .tech-list-main {
    .single-tech-outer {
      .single-tech-inner {
        .hover-overlay {
          top: 75px !important;
          left: 110px !important;

          .imageOnHover {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 370px) {
  .tech-list-main {
    .single-tech-outer {
      .single-tech-inner {
        .hover-overlay {
          top: 65px !important;
          left: 98px !important;

          .imageOnHover {
            height: 100%;
            width: 100%;
          }
        }
      }
    }
  }
}
