.ChallengeAccept {
  .boxSection {
    width: 100%;
    height: 398px;
    // overflow: hidden;
    position: relative;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2.2rem;

    .heading {
      font-size: 3.37rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 3;
      background: radial-gradient(
          166.44% 541.54% at 4.98% 17.16%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        )
        /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
      width: fit-content;
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }

    .upperdiv {
      height: 100%;
      width: 60%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: auto;
      border-radius: 50%;
      z-index: 4;
      // box-shadow: 0 0 10px 10px rgba(0, 0, 0, 0.5);
      filter: blur(2px);

      .round {
        width: 95%;
        height: 95%;
        position: relative;
        border-radius: 40%;
        overflow: hidden;

        z-index: 2;
        .boxOuter {
          position: absolute;
          top: -10%;
          margin: auto;
          display: flex;
          flex-wrap: wrap;
          transform: rotate(10deg);
          z-index: -1;

          .boxes {
            height: 40px;
            width: 40px;
            opacity: 0.3;
            border: 0.1px solid rgba(128, 128, 128, 0.384);
          }
        }
      }
    }
  }

  .content-container {
    width: 90%;
    margin: 0 auto;

    .inner-section {
      display: flex;
      column-gap: 50px;
      justify-content: space-around;
      &.left-right {
        flex-direction: row;
        padding-bottom: 5rem;
      }

      &.right-left {
        flex-direction: row-reverse;
        padding-bottom: 4rem;
      }

      .image {
        flex: 1;
        .video {
          width: 95%;
          height: 95%;
          object-fit: fit-content;
          border-radius: 20px;
        }
      }

      .content {
        flex: 1;
        padding: 10px;
        margin-top: 5px;

        h2 {
          margin-top: 0;
          font-family: var(--headingFon);
          font-weight: 400;
          font-size: 2.75rem;
          line-height: 3rem;
          background: radial-gradient(
              166.44% 541.54% at 4.98% 17.16%,
              #ffffff 0%,
              rgba(255, 255, 255, 0) 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          width: fit-content;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          span {
            font-size: 2.75rem;
            border: none;
          }
        }

        p {
          margin: 2rem 0;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.1rem;
          color: #f6f6f6;
          font-family: var(--paragraphFont);
        }
        ul {
          list-style-type: none;

          li {
            margin-top: 12px;
            display: flex;
            align-items: center;
            font-family: var(--paragraphFont);
            font-size: 14px;
            color: #f6f6f6;
          }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .boxSection {
      width: 100%;
      height: 250px;
      padding: 1rem 0;
      margin-bottom: 2.8rem;

      img {
        width: 90%;
      }
      .heading {
        font-size: 2.1rem;
      }
    }
    .content-container {
      .inner-section {
        margin: 0;
        .image {
          padding: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 100%;
            height: 100%;
          }
        }

        .content {
          flex: 1;

          h2 {
            margin-top: 0;
            font-size: 2.1rem;
          }

          p {
            margin: 10px 0;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }
  @media (max-width: 768px) {
    .boxSection {
      width: 100%;
      height: 300px;
      margin-bottom: 0.8rem;

      img {
        width: 100%;
      }
      .heading {
        font-size: 1.7rem;
      }
      .upperdiv {
        width: 60%;
        .round {
          .boxOuter {
            .boxes {
              height: 30px;
              width: 30px;
            }
          }
        }
      }
    }

    .content-container {
      .inner-section {
        margin: 0;
        &.left-right {
          flex-direction: column; // Default layout: Image on the left, Content on the right
        }

        &.right-left {
          flex-direction: column; // Reversed layout for even divs: Image on the right, Content on the left
        }

        .image {
          padding: 0;
          height: 90% !important;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
          margin-bottom: 20px;
        }

        .content {
          flex: 1;
          padding: 10px;
          text-align: justify;
          margin: 0;
          margin-top: -10px;

          h2 {
            margin-top: 0;
            font-size: 1.7rem;
            line-height: 1.9rem;
            text-align: start;
            span {
              display: inline-block;
              font-size: 22px;
              outline: none;
              border: none;
              box-shadow: none;
              img {
                display: inline-block;
                height: 100%;
                width: 100%;
              }
            }
          }

          p {
            margin: 10px 0;
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 530px) {
    margin: 0;
    margin-top: -4rem;

    .boxSection {
      width: 100%;
      height: 250px;
      padding: 0;
      overflow: hidden;
      img {
        width: 130%;
        object-fit: cover;
        @media (max-width: 420px) {
          width: 170%;
        }
      }
      .heading {
        width: 100%;
        text-align: center;
      }
      .upperdiv {
        width: 80%;
      }
    }
  }
}
