.NewsAndEvent {
  .news-event {
    display: flex;
    justify-content: center;
    gap: 20px;
    height: 50px;
    margin: 2rem 0rem;
    .news-heading {
      font-family: "Ubuntu";
      font-size: 2.78rem;
      font-size: 3.3vw;
      font-weight: 400;
      display: inline;
      margin: 0;
      padding: 0;
      width: 350px;
      background: radial-gradient(
        157.47% 1012.75% at 6.55% 17.65%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
    .news-line {
     
      width: 75vw;
      display: inline-block;
      height: 1px;
      border: 1px solid #f6f6f633;
      margin-top: 40px;
      // margin-left: -25px;
      @media (max-width: 1100px) {
        margin-top: 30px;
        margin-left: -20px;
      }
    }
  }
  .news-event-content {
    .newsEvent-card {
      overflow: auto;
      .newsEvent-inner {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 20px;
        .newsEvent-single-card {
          cursor: pointer;
          width: 30%;
          // height: 326px;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          &:hover {
            border-radius: 10px;
            transform: translateY(-5px);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
          }
          @media (max-width: 768px) {
            width: 300px;
          }
          img {
            width: 100%;
          }
          .newsEvent-text {
            padding: 10px 10px;
            .newsEvent-title {
              font-family: "Ubuntu";
              font-size: 1.4rem;
              font-weight: 400;
              line-height: 27.58px;
            }
            .newsEvent-container {
              justify-content: space-between;
              .newsEvent-heading,
              .date {
                font-family: "Maven Pro";
                font-size: 14px;
                font-weight: 400;
                line-height: 16.45px;
                color: #f6f6f66e;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .NewsAndEvent {
    .news-event {
      justify-content: space-between;
      gap: 0px;
      .news-heading {
        font-size: 2.1rem;
      }
      .news-line {
      
        height: 1px;
        border: 1px solid #f6f6f633;
      }
    }
    .news-event-content {
      .newsEvent-card {
        overflow: auto;
        .newsEvent-inner {
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: space-between;
          flex-wrap: wrap;
          // margin-top: 20px;
          gap: 0;
          .newsEvent-single-card {
            width: 30%;
            @media (max-width: 768px) {
              // width: 300px;
              width: 48%;
            }
            img {
              width: 100%;
            }
            .newsEvent-text {
              padding: 10px 10px;
              .newsEvent-title {
                font-family: "Ubuntu";
                font-size: 1.2rem;
                font-weight: 400;
                line-height: 22.58px;
              }
              .newsEvent-container {
                justify-content: space-between;
                .newsEvent-heading,
                .date {
                  font-family: "Maven Pro";
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16.45px;
                  color: #f6f6f66e;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .NewsAndEvent{
    .news-event{
      .news-line {
        width: 60%;
        display: inline-block;
        height: 1px;
        border: 1px solid #f6f6f633;
        margin-top: 40px;
       
       
      }
    }
  }

}

@media (max-width: 550px) {
  .NewsAndEvent {
    .news-event {
      justify-content: space-between;
      gap: 0px;
      .news-heading {
        font-size: 2.1rem;
      }
    }
    .news-event-content {
      .newsEvent-card {
        overflow: auto;
        .newsEvent-inner {
          justify-content: space-between;
          flex-wrap: wrap;
          gap: 0;

          // margin-top: 20px;
          .newsEvent-single-card {
            width: 49%;

            img {
              width: 100%;
            }
            .newsEvent-text {
              padding: 8px;
              .newsEvent-title {
                font-family: "Ubuntu";
                font-size: 1rem;
                font-weight: 400;
                line-height: 18.58px;
              }
              .newsEvent-container {
                justify-content: space-between;
                .newsEvent-heading,
                .date {
                  font-family: "Maven Pro";
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 16.45px;
                  color: #f6f6f66e;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 450px) {
  .NewsAndEvent{
    .news-event{
      .news-line {
        width: 40%;
        display: inline-block;
        height: 1px;
        border: 1px solid #f6f6f633;
        margin-top: 30px;
       @media (max-width:352px) {
        display: none;
        
       }
       
      }
    }
  }
  
}
