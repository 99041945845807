.BlogHome {
  height: 100%;
  width: 100%;
  padding: 5rem 0;
  padding-top: 3rem;

  .service-btn {
    padding: 4px 12px;
    background: radial-gradient(
        78.17% 203.96% at 6.34% 25.68%,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.1) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    width: fit-content;
    border-radius: 5px;
    border: 1px solid #ffffff33;
  }
  .blog-heading {
    font-size: 2.75rem;
    font-weight: 400;
    font-family: "ubuntu";
    width: fit-content;
    background: radial-gradient(
      157.47% 1012.75% at 6.55% 17.65%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .blog-info {
    margin: 2rem 0rem;
    font-weight: 400;
    font-size: 1.4rem;
    color: #f6f6f6;
  }
  .blog-section {
    .blog-card {
      // overflow: auto;
      .card-inner {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        gap: 35px;

        .blog-single-card {
          width: 30%;
          // height: 326px;
          
          cursor: pointer;
          transition: transform 0.3s ease, box-shadow 0.3s ease;
          &:hover {
            border-radius: 10px;
            transform: translateY(-5px);
            box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
          }
          @media (max-width: 768px) {
            width: 300px;
          }
          img {
            width: 100%;
          }
          .blog-text {
            padding: 10px 10px;
            .blog-title {
              font-family: "Ubuntu";
              font-size: 1.4rem;
              font-weight: 400;
              line-height: 27.58px;
            }
            .auther-container {
              justify-content: space-between;
              .auther,
              .date {
                font-family: "Maven Pro";
                font-size: 14px;
                font-weight: 400;
                line-height: 16.45px;
                color: #f6f6f66e;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .BlogHome {
    height: 100%;
    width: 100%;
    padding: 5rem 0;
    padding-top: 3rem;

    .service-btn {
      padding: 4px 12px;
    }
    .blog-heading {
      font-size: 2.1rem;
    }
    .blog-info {
      margin-top: 10px;
      font-size: 1.1rem;
    }
    .blog-section {
      .blog-card {
        .card-inner {
          display: flex;
          justify-content: start;
          // flex-wrap: wrap;
          gap: 25px;

          .blog-single-card {
            width: 30%;

            @media (max-width: 768px) {
              width: 46%;
            }
            img {
              width: 100%;
            }
            .blog-text {
              padding: 10px 10px;
              .blog-title {
                font-size: 1.2rem;

                line-height: 24.58px;
              }
              .auther-container {
                .auther,
                .date {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 550px) {
  .BlogHome {
    padding: 5rem 0;
    padding-top: 3rem;

    .service-btn {
      padding: 1px 12px;
      font-size: 14px;
    }
    .blog-heading {
      font-size: 1.7rem;
    }
    .blog-info {
      margin-top: -4px;
      font-size: 14px;
    }
    .blog-section {
      .blog-card {
        .card-inner {
          gap: 0px;
          justify-content: space-between;
          .blog-single-card {
            @media (max-width: 768px) {
              width: 49%;
            }
            img {
              width: 100%;
            }
            .blog-text {
              padding: 8px;
              .blog-title {
                font-size: 1rem;

                line-height: 18.58px;
              }
              .auther-container {
                .auther,
                .date {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
