.HomeService {
  padding: 1rem 0;
  padding-bottom: 15rem;

  .Headingbtn {
    width: 116px;
    height: 27px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    font-size: 14px;
    border-radius: 5px;
    background: radial-gradient(
        78.17% 203.96% at 6.34% 25.68%,
        rgba(255, 255, 255, 0.3) 0%,
        rgba(255, 255, 255, 0.1) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    border: 1px solid #ffffff33;
    font-size: 1rem;
    font-weight: 400;
    font-family: var(--paragraphFont);
  }
  .heading {
    font-size: 2.75rem;
    font-family: "Ubuntu";
    width: fit-content;
    background: radial-gradient(
      104.21% 376.56% at 50% 50%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    margin: auto;
    margin-top: 10px;
  }
  .para {
    margin-top: 2rem;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 1rem;
    color: #f6f6f6;
  }
  //
  .brandSolution {
    display: flex;
    margin-top: 2.5rem;

    .information {
      width: 60%;
      display: flex;
      align-items: center;
      flex-direction: column;
      .mainContainer {
        width: 590px;
        display: flex;
        gap: 40px;
        background: transparent;
        border: none;
        padding: 10px 20px;
        border-radius: 18px;
        position: relative;
        // &:hover {
        //   height: none !important;
        // }
        .whitebgcolor {
          position: absolute;
          height: 150px;
          width: 150px;
          top: -50%;
          left: -10%;
          background: white;
          z-index: -1;
          filter: blur(130px);
        }

        .check {
          color: #5de18b;
          font-size: 1.5rem;
          font-weight: 500;
        }
        .checkimg {
          width: 18px;
          height: 18px;
          margin-top: 6px;
        }
        .textSection {
          display: flex;
          flex-direction: column;
          gap: 20px;
          // background: #f6f6f61a;

          .headingIcon {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .icon {
              display: flex;
              justify-content: center;
              align-items: center;

              .accordion-header {
                width: 500px;
                font-size: 1.6rem;
                font-weight: 500;
                font-family: "Ubuntu";
                color: #ffffff;
                .bi-arrow-rights {
                  padding: 6px 20px;
                  padding-left: 0;
                  display: flex;
                  justify-content: space-between;
                  width: inherit;
                  background: transparent;
                  border: none;
                  color: inherit;
                  .Circle_around_arrow {
                    position: relative;
                    cursor: pointer;
                    width: 40px;
                    height: 40px;
                    border-radius: 50%;
                    background-color: transparent; // Start as transparent
                    transition: all 0.1s ease; // Smooth animation for all changes
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transform: scale(0.8);
                    margin-top: -5px;

                    span {
                   
                      margin-top: -15px;
                      margin-right: -22px;
                      opacity: 1; // Initially hidden
                      transition: opacity 0.2s ease; // Fade in smoothly
                      width: 20px;

                    }

                    &:hover {
                      width: 40px;
                      height: 40px;
                      transform: scale(1);
                      opacity: 1;
                      background: white;

                      span {
                        margin-top: 0px;
                        margin-right: 2px;
                        transform: rotate(-45deg);
                        opacity: 1;
                        // font-size: 25px;

                        // transition: opacity 0.6s ease;
                      }
                    }
                  }
                }
              }
            }
            .faright {
              color: #5de18b;
              font-size: 1.1rem;
              font-weight: 500;
            }
          }
          .position-section {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            .text-inf {
              margin: 0;
              font-size: 14px;
              font-weight: 400;
              padding: 4px 19px;
              line-height: 1.1rem;
              background: radial-gradient(
                  78.17% 203.96% at 6.34% 25.68%,
                  rgba(255, 255, 255, 0.3) 0%,
                  rgba(255, 255, 255, 0.1) 100%
                )
                /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
              border: 1px solid #ffffff33;
              border-radius: 8px;
              color: #ffffff;
            }
          }
          .details {
            margin-top: 20px;
            font-size: 14px;
            font-weight: 400;
            line-height: 1rem;
            color: #f6f6f6;
          }
        }
      }
      .mainContainer.active {
        background-color: #ffffff33; /* Active background color */
        transition: background-color 0.3s ease-in-out; /* Smooth transition */
        border: 1px solid #ffffff33;
        background: #f6f6f61a;
        height: 50% !important;
      }
      .accordion {
        .mainContainer {
          height: 50px;
          transition: all 0.6s ease-in-out;
          overflow: hidden;

          &:hover {
            height: 56%;
          }

          .textSection {
            .accordion-collapse {
              transition: max-height 0.24s ease-out, opacity 0.3s ease-in-out;
            }
          }
        }
      }
    }
    .images {
      width: 400px;
      height: 401px;
      margin-left: 2rem;
      margin-top: 2rem;

      .video {
        height: 100%;
        width: 100%;
        border-radius: 23px;
        object-fit: cover;
        overflow: hidden;
        border: 0.095rem solid #ffffff71;

        // border-image-source: linear-gradient(
        //     0deg,
        //     rgba(255, 255, 255, 0.2),
        //     rgba(255, 255, 255, 0.2)
        //   ),
        //   linear-gradient(
        //     314.79deg,
        //     rgba(255, 255, 255, 0) 86.75%,
        //     #ffffff 103.58%
        //   );
        // border-image-slice: 1;
      }
    }
  }
}

@media (max-width: 1124px) {
  .HomeService {
    .brandSolution {
      display: flex;
      margin-top: 1rem;
      .information {
        width: 60%;
        display: flex;
        align-items: center;
        flex-direction: column;
        .mainContainer {
          width: 500px;

          .textSection {
            .headingIcon {
              .icon {
                .accordion-header {
                  width: 400px;
                }
              }
            }
          }
        }
      }
      .images {
        width: 350px;
        height: 350px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .HomeService {
    padding-top: 1rem;

    .heading {
      font-size: 2.1rem;
    }
    .para {
      margin-top: 10px;
    }
  }
}

@media (max-width: 970px) {
  .HomeService {
    .brandSolution {
      .information {
        .mainContainer {
          width: 450px;

          .textSection {
            .headingIcon {
              .icon {
                .accordion-header {
                  width: 370px;
                }
              }
            }
          }
        }
      }
      .images {
        width: 300px;
        height: 300px;
        margin-left: 0;
      }
    }
  }
}
@media (max-width: 788px) {
  .HomeService {
    .heading {
      font-size: 1.7rem;
    }
    .brandSolution {
      flex-direction: column-reverse;
      gap: 20px;
      .information {
        width: 100%;
        .mainContainer {
          width: 80vw;
          margin: auto;
          .textSection {
            .headingIcon {
              .icon {
                .accordion-header {
                  width: 68vw;
                }
              }
            }
          }
        }
      }
      .images {
        width: 80%;
        height: 300px;
        margin-left: 0;
        margin: auto;
      }
    }
  }
}
@media (max-width: 520px) {
  .HomeService {
    .heading {
      font-size: 1.7rem;
    }
    .para {
      padding: 0 2rem;
      br {
        display: none;
      }
    }
    .brandSolution {
      flex-direction: column-reverse;
      gap: 20px;
      .information {
        width: 100%;
        .mainContainer {
          width: 90vw;
          margin: auto;
          gap: 10px;
          .textSection {
            .headingIcon {
              .icon {
                .accordion-header {
                  width: 75vw;
                }
              }
            }
          }
        }
      }
      .images {
        width: 88%;
        height: 250px;
        margin-left: 0;

        margin: 10px auto;
      }
    }
  }
}
