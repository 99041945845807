.OurPortPolio {
  padding-bottom: 3rem;
  backdrop-filter: blur(94px);
  border: 3px solid rgba(255, 0, 0, 0);

  .divheader {
    margin: auto;
    width: 90%;
  }
  .flylayer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    width: 90%;
    margin: auto;
    margin-top: 3rem;

    .flyinner {
      text-align: start !important;

      h1 {
        font-size: 2.45rem;
        text-align: start;
        color: transparent;
        width: fit-content;
        font-weight: 400;
        background: var(--gradient-text);
        -webkit-background-clip: text;
        background-clip: text;
        margin: auto;
      }
      p {
        margin-top: 10px;
        // text-align: start;
        font-size: 14px;
        font-weight: 400;
        line-height: 1rem;
      }
    }

   
    .serchbar {
      margin-top: 1.5rem;
      background: rgb(255, 255, 255);
      border-radius: 30px;
      padding-left: 25px;
      padding-right: 2px;
      overflow: hidden;
      height: 40px;
      width: 330px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @media (max-width: 420px) {
        width: 100%;
      }
      input {
        width: 100%;
        height: 100%;
        font-size: 1.1rem;
        color: #17242a;
        outline: none;
        border: none;
      }
      .serchicon {
        background: #000;
        color: white;
        padding: 0.4rem;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        cursor: pointer;
      }
    }

    .CheckSerachbar {
      width: 90%;
      margin: auto;
      margin-top: 1.5rem;
      display: flex;
      justify-content: start;
      gap: 10px;
      flex-wrap: wrap;

      .portfolio-item {
       
        width: 35%;
        height: 50%;
        display: flex;
        flex-wrap: wrap;
        position: relative;
        overflow: hidden;
        border-radius: 20px;

        &:hover {
          .client-details {
            top: 0;
            opacity: 1;
          }
        }
        .image-overlay {
          height: 50%;
          width: 100%;
          .portfolio-image {
            height: 100%;
            width: 100%;
            object-fit: fit-content;
          }
        }
        .client-details {
          height: 100%;
          width: 100%;
          padding: 0 3rem;
          position: absolute;
          top: 100%;
          backdrop-filter: blur(9px);
          text-align: start;
          transition: all 0.5s linear;
          display: flex;
          flex-direction: column;
          justify-content: center;
          @media (max-width: 1100px) {
            justify-content: start;
            padding-top: 2rem;
          }
          .client-title {
            font-family: "Poppins", sans-serif;
            font-size: 2.1rem;
            font-weight: 400;
            line-height: 35px;
            background: radial-gradient(
              87.64% 653.71% at 4.4% 28.57%,
              #ffffff 0%,
              rgba(255, 255, 255, 0.4) 100%
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .client-para {
            margin-top: 5px;
            font-family: "Poppins", sans-serif;
            font-size: 14px;
            font-weight: 400;
            line-height: 21px;
            text-align: start;
            background: white;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        
          }
          .buttons {
            position: absolute;
            bottom: 5%;
            width: 80%;

            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            gap: 10px;
            padding: 0;
            @media (max-width: 1100px) {
              justify-content: start;
            }
            .batan {
              margin-top: -20px;
              font-family: "Poppins", sans-serif;
              font-size: 12px;
              font-weight: 400;
              line-height: 18px;
              background: radial-gradient(
                  78.17% 203.96% at 6.34% 25.68%,
                  rgba(255, 255, 255, 0.3) 0%,
                  rgba(255, 255, 255, 0.1) 100%
                )
                /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

              border: 1px solid #ffffff33;
              border-radius: 8px;
              padding: 4px 16px;
            }
          }
        }
      }
      .smallImg {
        width: 29%;
        height: 50%;
        @media (max-width: 600px) {
          width: 48%;
        }
      }
    }
  }

  .portHeader ul {
    width: 89%;
    margin: auto;
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0;
    li {
      cursor: pointer;
      padding: 0;
      margin: 0;
      padding-bottom: 10px;

      list-style: none;
    }
  }

  .portHeader ul li.active {
    font-weight: bold;
    border-bottom: 2px solid transparent; /* Set actual border to transparent */
    color: #4285f4;
    position: relative;
  }
  .portHeader ul li.active::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 2px; /* Same as the border-bottom */
    background-color: #4285f4; /* Match the border color */
    box-shadow: 0 2px 5px rgba(66, 133, 244, 0.5); /* Apply shadow to this pseudo-element */
    z-index: -1; /* Ensure it stays behind the text */
  }

  .portfolio {
    width: 90%;
    margin: auto;
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;

    .portfolio-item {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      overflow: hidden;
      border-radius: 20px;

      &:hover {
        .client-details {
          top: 0;
          opacity: 1;
        }
      }
      .image-overlay {
        height: 100%;
        width: 100%;
        .portfolio-image {
          height: 100%;
          width: 100%;
          object-fit: fit-content;
        }
      }
      .client-details {
        height: 100%;
        width: 100%;
        padding: 0 3rem;
        position: absolute;
        top: 100%;
        backdrop-filter: blur(9px);
        text-align: start;
        transition: all 0.5s linear;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 1100px) {
          justify-content: start;
          padding-top: 2rem;
        }
        .client-title {
          font-family: "Poppins", sans-serif;
          font-size: 2.1rem;
          font-weight: 400;
          line-height: 35px;
          background: radial-gradient(
            87.64% 653.71% at 4.4% 28.57%,
            #ffffff 0%,
            rgba(255, 255, 255, 0.4) 100%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .client-para {
          margin-top: 5px;
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: start;
          background: white;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .buttons {
          position: absolute;
          bottom: 5%;
          width: 80%;

          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 10px;
          padding: 0;
          @media (max-width: 1100px) {
            justify-content: start;
          }
          .batan {
            margin-top: -20px;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            background: radial-gradient(
                78.17% 203.96% at 6.34% 25.68%,
                rgba(255, 255, 255, 0.3) 0%,
                rgba(255, 255, 255, 0.1) 100%
              )
              /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

            border: 1px solid #ffffff33;
            border-radius: 8px;
            padding: 4px 16px;
          }
        }
      }
    }
    .smallImg {
      width: 49%;
      height: 100%;
      @media (max-width: 600px) {
        width: 48%;
      }
    }
  }

  .button {
    margin: 5rem 0;

    button {
      border: 1px solid rgba(128, 128, 128, 0.486);

      background: radial-gradient(
          110.37% 306.48% at -30.59% -224.07%,
          rgba(255, 255, 255, 0.89) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(0deg, #131313, #131313);

      border-radius: 1rem;
      padding: 1.2rem 3rem;
      font-size: 1.1rem;
      color: white;
      font-family: "Maven Pro", sans-serif;
      display: flex;
      justify-content: center;
      align-content: center;
      margin: auto;
      .arrow {
        margin-left: 10px;

        margin-top: 3px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .OurPortPolio {
    h1 {
      font-size: 2.1rem;
    }

    p {
      margin-top: 1rem;
      line-height: 1rem;
    }

    .portHeader ul {
      margin: 1.7rem auto;
      font-size: 14px;
      li {
        cursor: pointer;
        padding-bottom: 10px;
        list-style: none;
      }
    }
    .portfolio {
      width: 90%;
      margin-top: 1.5rem;

      .portfolio-item {
        &:hover {
          .client-details {
            top: 0;
            opacity: 1;
          }
        }

        .client-details {
          padding: 0 2rem;

          padding-top: 3rem;

          .client-title {
            font-size: 1.5rem;

            line-height: 25px;
          }
          .client-para {
            margin-top: 5px;

            font-size: 12px;

            line-height: 13px;
          }
          .buttons {
            bottom: 5%;
            width: 80%;

            gap: 10px;
            padding: 0;

            .batan {
              margin-top: -20px;

              line-height: 13px;
              font-size: 12px;
              border-radius: 5px;
              padding: 4px 10px;
            }
          }
        }
      }
      .smallImg {
        width: 49%;
        height: 100%;
        @media (max-width: 600px) {
          width: 48%;
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .OurPortPolio {
    h1 {
      font-size: 2.1rem;
    }

    p {
      margin-top: 1rem;
      line-height: 1rem;
    }

    .portHeader ul {
      margin: 1.7rem auto;
      font-size: 14px;
      li {
        cursor: pointer;
        padding-bottom: 10px;
        list-style: none;
      }
    }
    //
    .portfolio {
      width: 85%;

      margin-top: 1.5rem;

      .portfolio-item {
        &:hover {
          .client-details {
            top: 0;
            opacity: 1;
          }
        }

        .client-details {
          padding: 1.5rem;

          .client-title {
            font-size: 1.5rem;

            line-height: 25px;
          }
          .client-para {
            margin-top: 5px;

            font-size: 12px;

            line-height: 13px;
          }
          .buttons {
            bottom: 5%;
            width: 80%;

            gap: 10px;
            padding: 0;

            .batan {
              margin-top: -20px;

              line-height: 13px;
              font-size: 12px;
              border-radius: 5px;
              padding: 4px 10px;
            }
          }
        }
      }
      .smallImg {
        width: 49%;
        height: 100%;
        @media (max-width: 600px) {
          width: 48%;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .OurPortPolio {
    //
    .portfolio {
      width: 85%;
      .portfolio-item {
        .image-overlay {
          height: 250px;
          width: 100%;
          .portfolio-image {
            height: 100%;
            width: 100%;
            object-fit: fit-content;
          }
        }

        .client-details {
          padding: 1.5rem;

          .client-title {
            font-size: 1.5rem;

            line-height: 25px;
          }
          .client-para {
            margin-top: 5px;

            font-size: 12px;

            line-height: 13px;
          }
          .buttons {
            bottom: 5%;
            width: 80%;

            gap: 10px;
            padding: 0;

            .batan {
              margin-top: -20px;

              line-height: 13px;
              font-size: 12px;
              border-radius: 5px;
              padding: 4px 10px;
            }
          }
        }
      }
      .smallImg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 530px) {
  .OurPortPolio {
    padding: 0 1.5rem;
    h1 {
      font-size: 1.7rem;
    }

    p {
      margin-top: 1rem;
      line-height: 1rem;
      font-size: 14px;
      width: 100%;
      margin: 10px auto;
      br {
        display: none;
      }
    }

    .portHeader ul {
      flex-wrap: wrap;
      gap: 15px;
      padding: 0;
      width: 100%;
      margin: auto;
      margin-top: 15px;
      font-size: 14px;
      li {
        cursor: pointer;
        padding-bottom: 10px;
        list-style: none;
      }
    }

    .portfolio {
      width: 100%;
      .portfolio-item {
        .image-overlay {
          height: 100%;
          width: 100%;
          .portfolio-image {
            height: 100%;
            width: 100%;
            object-fit: fit-content;
          }
        }

        .client-details {
          padding: 1.5rem;

          .client-title {
            font-size: 1.5rem;

            line-height: 25px;
          }
          .client-para {
            margin-top: 5px;

            font-size: 12px;

            line-height: 13px;
          }
          .buttons {
            bottom: 10%;
            width: 90%;
            gap: 10px;

            flex-wrap: wrap;
            display: flex;
            align-items: start;
            justify-content: start !important;
            text-align: start;
            .batan {
              width: fit-content;
              margin: 0;
              margin-top: -5px;
              line-height: 13px;
              font-size: 12px;
              border-radius: 5px;
              padding: 4px 10px;
            }
          }
        }
      }
      .smallImg {
        width: 100%;
        height: 100%;
      }
    }
    .button {
      margin: 3rem 0;
      button {
        padding: 10px 1.2rem;
        font-size: 14px;
      }
    }
  }
}
