.RevinewFocus {

  .fucus-head {
    font-family: "Ubuntu";
    font-size: 2.81rem;
    font-weight: 400;
    text-align: center;
    background: radial-gradient(
        166.44% 541.54% at 4.98% 17.16%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    span {
      background: linear-gradient(90deg, #34a853 0%, #5de18b 100%);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
  }
  .it {
    font-family: "Ubuntu";
    font-size: 2.2rem;
    margin-top: -15px;
    font-weight: 400;
    text-align: center;
    background: radial-gradient(
        166.44% 541.54% at 4.98% 17.16%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
  }
  .paratexts {
    margin-top: 1rem;
    font-family: "Maven Pro";
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.1rem;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  .CardSection {
    display: flex;
    justify-content: space-evenly;
    margin-top: 5rem;

    .card-container {
      width: 384px;
      height: 351px;
      position: relative;
      z-index: 10;
      .iconImg {
        height: 170px;
        width: 170px;
        z-index: 15;
        position: absolute;
        top: -16.5%;
      }
      .singlecard {
        z-index: 2;
        width: 384px;
        height: 351px;
        padding: 40px;
        border: 1px solid;
        position: relative;
        border: 1px solid;

        border-image-source: linear-gradient(
            0deg,
            rgba(246, 246, 246, 0.2),
            rgba(246, 246, 246, 0.2)
          ),
          linear-gradient(
            239.28deg,
            rgba(255, 255, 255, 0) 80.74%,
            #ffffff 107.91%
          );
        border-image-slice: 1;
        border: 1px solid rgba(246, 246, 246, 0.2);
        background: #f6f6f612;
        backdrop-filter: blur(65px);
        border-radius: 27px;
        position: relative;
        overflow: hidden;
        border-radius: 27px;
        z-index: 1;

        .topbg {
          width: 400.31px;
          height: 258px;
          z-index: 1;
          position: absolute;
          top: -30%;
          left: -40%;
          z-index: 1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 50%;
          filter: blur(57px);
          box-shadow: 2px 2px;
          z-index: -1;
        }
        .centerbg {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 266.88px;
          border-radius: 50%;
          height: 172px;
          filter: blur(32px);
          z-index: 10;
          position: absolute;
          box-shadow: 2px 2px;

          top: -20%;
          left: -40%;
        }
        .circle {
          width: 127.88px;
          height: 82.42px;
          border-radius: 50%;
          filter: blur(22px);
          position: absolute;
          top: -6%;
          left: -8%;
          z-index: 31;
          box-shadow: 2px 2px;
        }

        .headings {
          width: 70%;
          font-family: "Ubuntu";
          font-size: 1.5rem;
          font-weight: 400;
          line-height: 1.6rem;
          text-align: left;
          margin-top: 5rem;
          z-index: 20;
        }
        .texts {
          margin-top: 1.5rem;
          font-family: "Maven Pro";
          font-size: 14px;
          font-weight: 400;
          line-height: 1rem;
          text-align: left;
          color: #ffffffcc;
        }
        //
        .bottomColor {
          width: 284.13px;
          height: 183.12px;
          position: absolute;
          border-radius: 50%;
          transform: rotate(-24deg);
          right: -22%;
          bottom: -25%;
          filter: blur(57px);
        }
        .bottomCenter {
          width: 189.42px;
          height: 122.08px;
          border-radius: 50%;
          transform: rotate(-24deg);
          filter: blur(32px);

          position: absolute;
          right: -12%;
          bottom: -15%;
        }
        .bottomInner {
          width: 90.76px;
          height: 58.5px;
          border-radius: 50%;
          transform: rotate(-24deg);
          filter: blur(22px);

          position: absolute;
          right: -7%;
          bottom: -5%;
        }
      }

      .bg1 {
        width: 284.13px;
        height: 183.12px;
        // background: #0aa3e9;
        filter: blur(114px);
        z-index: 1;
        .bg2 {
          z-index: 2;
          width: 189.42px;
          height: 122.08px;

          //   background: #26dcff;

          filter: blur(64px);
          .bg3 {
            z-index: 3;
            width: 90.76px;
            height: 58.5px;
            background: #a9e9ff;
            // backdrop-filter: blur(44px);
            border: 3px solid red;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .RevinewFocus {
    .fucus-head {
      font-size: 2.1rem;
      line-height: 3rem;
    }
    .it {
      font-size: 1.6rem;
    }
    .paratexts {
      margin-top: 1.5rem;
      font-size: 14px;
      line-height: 1rem;
    }

    .CardSection {
      margin-top: 3rem;

      .card-container {
        width: 384px;
        height: 351px;
        width: 20rem;
        height: 19rem;

        .iconImg {
          height: 110px;
          width: 110px;
          position: absolute;
          top: -12.5%;
        }
        .singlecard {
          width: 100%;
          height: 100%;
          padding: 25px;
          .topbg {
            display: none;
            width: 200.31px;
            height: 158px;
            top: -20%;
            left: -20%;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            filter: blur(57px);
            box-shadow: 2px 2px;
            z-index: -1;
          }
          .centerbg {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 166.88px;
            border-radius: 50%;
            height: 100px;
            filter: blur(32px);
            z-index: 10;
            position: absolute;
            box-shadow: 2px 2px;

            top: -20%;
            left: -40%;
          }
          .circle {
            width: 107.88px;
            height: 82.42px;
            border-radius: 50%;
            filter: blur(22px);
            position: absolute;
            top: -6%;
            left: -8%;
            z-index: 31;
            box-shadow: 2px 2px;
          }

          .headings {
            width: 100%;
            font-size: 1.5rem;
            line-height: 1.6rem;
            margin-top: 4rem;
          }
          .texts {
            margin-top: 1.5rem;
            line-height: 1rem;
          }
          .bottomColor {
            width: 184.13px;
            height: 100.12px;
            transform: rotate(-24deg);
            right: -15%;
            bottom: -10%;
            filter: blur(50px);
          }
          .bottomCenter {
            width: 100.42px;
            height: 80.08px;
            border-radius: 50%;
            transform: rotate(-24deg);
            filter: blur(32px);

            position: absolute;
            right: -12%;
            bottom: -15%;
          }
          .bottomInner {
            width: 40.76px;
            height: 18.5px;
            border-radius: 50%;
            transform: rotate(-24deg);
            filter: blur(22px);

            position: absolute;
            right: -7%;
            bottom: -5%;
          }
        }

        .bg1 {
          width: 284.13px;
          height: 183.12px;
          // background: #0aa3e9;
          filter: blur(114px);
          z-index: 1;
          .bg2 {
            z-index: 2;
            width: 189.42px;
            height: 122.08px;

            //   background: #26dcff;

            filter: blur(64px);
            .bg3 {
              z-index: 3;
              width: 90.76px;
              height: 58.5px;
              background: #a9e9ff;
              // backdrop-filter: blur(44px);
              border: 3px solid red;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 700px) {
  .RevinewFocus {
    .CardSection {
      .card-container {
        width: 17rem;
        height: 19rem;
      }
    }
  }
}

@media (max-width: 600px) {
  .RevinewFocus {
    padding: 0;
    width: 100%;
    .fucus-head {
      font-size: 1.7rem;
      // line-height: 1.5rem;
    }
    .it {
      font-size: 1.3rem;
    }
    .paratexts {
      width: 100%;
      margin-top: 1rem;
      font-size: 14px;
      line-height: 1.2rem;
      br {
        display: none;
      }
    }

    .CardSection {
      margin-top: 3rem;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 35px;
      .card-container {
        width: 80%;
        height: 17rem;
        @media (max-width: 415px) {
          height: 100%;
          width: 100%;
        }
        .iconImg {
          height: 130px;
          width: 130px;
          position: absolute;
          top: -17.5%;
          left: -1%;
        }
        .singlecard {
          width: 100%;
          height: 100%;
          padding: 22px;
          background: #00000046;
          .topbg {
            display: none;
            width: 150.31px;
            height: 118px;
            top: -20%;
            left: -10%;
            filter: blur(67px);
          }
          .centerbg {
            width: 106.88px;

            height: 70px;
            filter: blur(42px);
            top: -10%;
            left: -20%;
          }
          .circle {
            width: 80.88px;
            height: 62.42px;

            position: absolute;
            top: -4%;
            left: -1%;
          }

          .headings {
            width: 100%;
            font-size: 1.5rem;
            line-height: 1.6rem;
            margin-top: 3.5rem;
          }
          .texts {
            margin-top: 1.5rem;
            line-height: 1rem;
            color: white;
          }
          .bottomColor {
            width: 114.13px;
            height: 80.12px;

            right: -15%;
            bottom: -10%;
          }
          .bottomCenter {
            width: 100.42px;
            height: 80.08px;
            border-radius: 50%;
            transform: rotate(-24deg);
            filter: blur(32px);

            position: absolute;
            right: -12%;
            bottom: -15%;
          }
          .bottomInner {
            width: 40.76px;
            height: 18.5px;
            border-radius: 50%;
            transform: rotate(-24deg);
            filter: blur(22px);

            position: absolute;
            right: -7%;
            bottom: -5%;
          }
        }

        .bg1 {
          display: none;
          width: 104.13px;
          height: 103.12px;
          // background: #0aa3e9;
          filter: blur(114px);
          z-index: 1;
          .bg2 {
            z-index: 2;
            width: 109.42px;
            height: 80.08px;

            //   background: #26dcff;

            filter: blur(64px);
            .bg3 {
              z-index: 3;
              width: 40.76px;
              height: 18.5px;
              background: #a9e9ff;
              // backdrop-filter: blur(44px);
            }
          }
        }
      }
    }
  }
}
