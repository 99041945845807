.main-box {
  height: 100%;
  width: 100%;
  border: 1px solid gray;
  border-radius: 13px;
  position: relative;
  overflow: hidden;
  z-index: 3;

  .box-div {
    width: 264px;
    height: 156px;
    
    background: #131313;
    padding: 0.96rem; // 6% of 16px is approximately 0.96rem
    z-index: -1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .top-div {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 5px;
      .box-heading {
        font-family: "Ubuntu", sans-serif;
        font-size: 18px;
        font-weight: 400;
        text-align: left;
        margin: 0;
      }
      .specification {
        display: flex;
        justify-content: space-between;
        .box-1 {
          padding: 4px 16px;
          font-size: 0.7rem;
          border: 1px solid #ffffff33;
          border-radius: 5px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "ubuntu", sans-serif;
          background: radial-gradient(
              78.17% 203.96% at 6.34% 25.68%,
              rgba(255, 255, 255, 0.3) 0%,
              rgba(255, 255, 255, 0.1) 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          .box-specifications {
            font-family: "Maven Pro", sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 14.1px;
            text-align: center;
            margin: 0;
          }
        }
      }
    }
  }
  .bottom-div {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      padding: 10px 20px;
      display: inline;
      border-radius: 8px;
      font-size: 12px;
      font-weight: 700;
      line-height: 14.1px;
      font-family: "Maven Pro", sans-serif;
      text-align: center;
      color: white;
      border: none;
      cursor: pointer;
      background: radial-gradient(
        256.66% 747.37% at 0% 0%,
        #ffffff 0%,
        #4285f4 16.41%,
        rgba(66, 133, 244, 0) 100%
      );
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
    }

    .applied-number {
      font-size: 0.7rem;
    }
  }
  .box-background-effect {
    width: 334px;
    height: 334px;
    top: 150px;
    left: 160px;
    background: white;
    filter: blur(100px);
    z-index: 2;
    border-radius: 50%;
    position: absolute;
    border: 2px solid white;
  }
}

@media (max-width: 1024px) {
  .main-box {
    height: 100%;
    width: 100%;
    border: 1px solid gray;
    border-radius: 13px;
    position: relative;
    overflow: hidden;
    z-index: 3;
  
    .box-div {
      width: 205px;
      height: 126px;
      .top-div {
        gap: 2px;
        .box-heading {
          font-size: 14px;
        }
        .specification {
          .box-1 {
            padding: 2px 7px;
            font-size: 10px;
            .box-specifications {
              font-size: 10px;
              // line-height: 14.1px;
            }
          }
        }
      }
    }
    .bottom-div {
      button {
        padding: 7px 9px;
        display: inline;
        border-radius: 8px;
        line-height: 14.1px;
      }
  
      .applied-number {
        font-size: 10px;
      }
    }
    .box-background-effect {
      width: 334px;
      height: 334px;
      top: 150px;
      left: 160px;
      background: white;
      filter: blur(100px);
      z-index: 2;
      border-radius: 50%;
      position: absolute;
      border: 2px solid white;
    }
  }
  
}

