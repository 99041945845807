.Careermain {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 3rem;
  backdrop-filter: blur(94px);

  .career-header {
    position: absolute;
    top: 37px;
    width: 93%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 99 !important;

  }
  .careerHero{
    // border: 2px solid red;
  }

  .companys {

    margin-top: 5rem;
  }
  .joblistings {
    position: relative;
    z-index: 1;
    .leftTopGreenBg {
      width: 197px;
      height: 197px;
      border-radius: 50%;
      background: #58ff92;
      z-index: -1;
      position: absolute;
      top: 45%;
      left: -7%;
      filter: blur(127px);
    }
    .TopBlueBg {
      width: 291px;
      height: 291px;
      border-radius: 50%;
      background: #4285f4;
      z-index: -1;
      position: absolute;
      top: 60%;
      left: -12%;
      filter: blur(127px);
    }
  }
}
