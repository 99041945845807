.footer {

  background: #0000001a;
  backdrop-filter: blur(74px);
  z-index: 2;
  position: relative;
  .bgimg {
    width: 100%;
    height: 30%;
    position: absolute;
    top: -17%;
    z-index: -1;
  }
  .line-one {
    z-index: -1;
    width: 100%;
    height: 15px;
    .borders {
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #fff 50%,
        rgba(255, 255, 255, 0) 100%
      );
      height: 2px;
      width: 100%;
    }

    background: linear-gradient(
      90deg,
      #183678 0%,
      #273f8f 10.18%,
      #45489f 21.37%,
      #6e61cd 32.12%,
      #9f71e5 43.64%,
      #e8649a 55.48%,
      #f5656a 65.91%,
      #f97c54 76.01%,
      #faa336 85.14%,
      #fcf433 93.28%,
      #e2d026 100%
    );
    .line-two {
      width: 100%;
      height: 22px;
      background: linear-gradient(
        90deg,
        #183678 0%,
        #273f8f 10.18%,
        #45489f 21.37%,
        #6e61cd 32.12%,
        #9f71e5 43.64%,
        #e8649a 55.48%,
        #f5656a 65.91%,
        #f97c54 76.01%,
        #faa336 85.14%,
        #fcf433 93.28%,
        #e2d026 100%
      );
      filter: blur(27px);
    }
    .line-three {
      width: 100%;
      height: 22px;
      background: linear-gradient(
        90deg,
        #183678 0%,
        #273f8f 10.18%,
        #45489f 21.37%,
        #6e61cd 32.12%,
        #9f71e5 43.64%,
        #e8649a 55.48%,
        #f5656a 65.91%,
        #f97c54 76.01%,
        #faa336 85.14%,
        #fcf433 93.28%,
        #e2d026 100%
      );
      filter: blur(27px);
    }
  }

  .footer-container-main {
    background: #1313136c;
    backdrop-filter: blur(174px);
    padding: 3rem 4rem 3rem 4rem;
    .logo-section {
      width: 280px;
      height: 50px;

      img {
        width: 100%;
        height: 100%;
        object-fit: fit-content;
      }
    }
    .inner-main {
      height: 100%;
      width: 100%;

      .main-content {
        width: 100%;
        display: flex;
        justify-content: start;
        padding-top: 2.2rem;
        .links-section {
          display: flex;
          justify-content: space-between;
          align-items: start;
          width: 100%;
          padding-right: 5rem;
          height: 100%;

          .social-links {
            .direct-links {
              display: flex;
              justify-content: space-between;
              margin-bottom: 2rem;

              .media-icons {
                .icon-heading {
                  text-align: start;
                  line-height: 20px;
                  font-size: 18px;
                  font-weight: 500;
                  font-family: "Ubuntu" sans-serif;
                }

                .social-icons {
                  margin-top: 0.5rem;
                  display: flex;
                  gap: 10px;
                  a {
                    cursor: pointer;
                  }
                  .icons {
                    color: white;
                    font-size: 23px;
                    cursor: pointer;
                  }
                }
              }
            }
            .links {
              width: 100%;
              display: flex;
              justify-content: center;
              align-items: start;
              flex-direction: column;
              flex-wrap: wrap;
              margin: 0;
              gap: 0px;
              p {
                font-size: 18px;
                font-weight: 400;
              }
            }
          }
          .quick-links {
            display: flex;
            justify-content: center;
            align-items: start;
            flex-direction: column;

            h4 {
              font-size: 18px;
              font-weight: 700;
              padding-bottom: 0px;
              text-align: start;
            }
            .linkListOfFooter {
              width: 100%;
              display: flex;
              gap: 30px;
              // justify-content: space-between;

              .firstList {
                display: flex;
                flex-direction: column;
                align-items: start;
                .quick-link {
                  border: none;
                  background: transparent;
                  margin: 0;
                  margin-top: 5px;
                  font-size: 16px;
                  font-weight: 400;
                  font-family: "Ubuntu";
                  cursor: pointer;
                }
              }
              .SecondList {
                display: flex;
                flex-direction: column;
                align-items: start;

                .quick-link {
                  border: none;
                  background: transparent;
                  margin: 0;
                  padding: 0;
                  cursor: pointer;
                  margin: 0;
                  margin-top: 5px;
                  font-size: 16px;
                  font-weight: 400;
                  font-family: "Ubuntu";
                }
              }
            }
          }
        }

        .address-section {
          width: 50%;
          font-size: 14px;
          font-weight: 400;

          .email {
            margin: 2rem 0rem;
          }

          .address,
          .c21,
          .indore,
          .email,
          .mobile-number {
            font-family: "Ubuntu";
            font-size: 14px;
            font-weight: 400;
            margin: 0;
            line-height: 22px;
            display: inline;
          }
          .email,
          .mobile-number {
            display: block;
          }
          .email {
            margin: 12px 0px;
          }
        }
      }
    }
    .copyright-section {
      text-align: end;
      .copyright-content {
        font-size: 18px;
        font-weight: 400;
      }
    }
  }
}

//
@media (max-width: 1024px) {
  .footer {
    .bgimg {
      height: 10%;
      top: -6%;
    }
    .footer-container-main {
      padding: 2rem;
      .logo-section {
        width: 170px;
        height: 30px;

        img {
          width: 100%;
          height: 100%;
          object-fit: fit-content;
        }
      }
      .inner-main {
        .main-content {
          flex-direction: column;
          .links-section {
            width: 100%;
            padding-right: 0rem;

            .quick-links {
              margin: auto;
            }
          }

          .address-section {
            width: 100%;
            padding-left: 0;
            margin-top: 2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 570px) {
  .footer {
    margin-top: 0rem;
    .bgimg {
      height: 5%;
      top: -3%;
    }
    .footer-container-main {
      padding: 1.2rem;
      .logo-section {
        width: 200px;
        height: 35px;

        margin-bottom: 10px;
        img {
          width: 100%;
          height: 100%;
          object-fit: fit-content;
        }
      }
      .inner-main {
        .main-content {
          padding: 10px 0;
          flex-direction: column;
          gap: 0;
          .links-section {
            width: 100%;
            flex-direction: column;
            justify-content: start;
            align-items: start;
            gap: 20px;
            padding-bottom: 2rem;

            .social-links {
              gap: 0;
              width: 100%;
              .direct-links {
                justify-content: start;
                margin: 0;
              }
              .links {
                // border: 1px solid red;
                flex-direction: row;
                justify-content: start;
                gap: 15px;
                margin: 0;
                margin-top: 10px;
                padding: 0 7px;
              }
            }

            .quick-links {
              width: 100%;
              gap: 10px;
              margin: 0 !important;
align-self: flex-start;
              .linkListOfFooter {
                justify-content: start;
                gap: 70px;
              }

              h4 {
                width: 100%;
                // border: 1px solid rgb(0, 255, 76);
                margin: 0 !important;
              }
              .quick-link {
                margin: 0;
              }
            }
           
          }

          .address-section {
            margin-top: 0;
            // border: 1px solid rgb(0, 255, 76);

            .address,
            .c21,
            .indore,
            .email,
            .mobile-number {
              margin: 0;
              line-height: 22px;
              display: inline;
            }
            .email,
            .mobile-number {
              display: block;
            }
            .email {
              margin-top: 10px;
            }
          }
        }
      }
      .copyright-section {
        text-align: start;
        margin-top: 1rem;
      }
    }
  }
}
