.TechService {
  position: relative;
  padding-top: 7rem;
  overflow: hidden;
  // backdrop-filter: blur(94px);

  .tech-header {
    width: 96%;
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate(-50.9%, -50%);
  }

  .tech-hero,
  .tech-service,
  .reletedwork-section,
  .ms-section {
    padding: 5rem 2.5rem;
  }
  .tech-hero {
    padding: 0 2.5rem;
  }
  .service-perfomanceWorkflow {
    width: 100%;
    margin: 10rem 0;
  }
  .ms-section {
    .afterHeader {
      width: 100%;
      margin: auto;

      .firstPart {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .headingOfContact {
          width: 50%;

          h3 {
            // padding-top: 2.8rem;
            font-weight: 400;
            font-size: 45px;
            line-height: 51.71px;
            text-align: left;
            background: radial-gradient(
              87.64% 653.71% at 4.4% 28.57%,
              #ffffff 0%,
              rgba(255, 255, 255, 0.4) 100%
            );
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            width: fit-content;

            font-weight: 400;
            .contact-heading-mid {
              background: linear-gradient(90deg, #4285f4 0%, #9cc2ff 100%);

              background-clip: text;
              color: transparent;
              font-size: 45px;
              font-weight: 400;
              line-height: 51.71px;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 1340px) {
}

@media (max-width: 1024px) {
  .TechService {
    padding-top: 4rem;
    .tech-hero,
    .tech-service,
    .reletedwork-section,
    .ms-section {
      padding: 2rem 2.5rem;
    }
    .tech-hero {
      padding: 0 2.5rem;
    }
    .ms-section {
      .afterHeader {
        width: 100%;
        margin: auto;

        .firstPart {
          width: 100%;
          .headingOfContact {
            width: 50%;
            h3 {
              // padding-top: 2.1rem;
              font-weight: 400;
              font-size: 2.1rem;
              line-height: 31.71px;

              .contact-heading-mid {
                background: linear-gradient(90deg, #4285f4 0%, #9cc2ff 100%);

                background-clip: text;
                color: transparent;
                font-size: 2.1rem;
                font-weight: 400;
                line-height: 50.71px;
              }
            }
          }
          .ms-contactForm {
            width: 50%;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .TechService {
    padding-top: 0rem;
    padding-bottom: 3rem;

    .service-perfomanceWorkflow {
      margin-top: 2rem;
    }
    .ms-section {
      .afterHeader {
        width: 100%;
        margin: auto;

        .firstPart {
          width: 100%;
          flex-direction: column;
          gap: 20px;
          .headingOfContact {
            width: 100%;
            h3 {
              padding-top: 1.5rem;
              font-weight: 400;
              font-size: 1.5rem;
              line-height: 31.71px;
              br {
                display: none;
              }

              .contact-heading-mid {
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 20.71px;
              }
            }
          }
          .ms-contactForm {
            width: 100%;
          }
        }
      }
    }
  }
}
