.MainCharPart {
  width: 100%;
  .ChartTopPart {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    .headingOfPara {
      text-align: center;
      width: fit-content;
      font-family: "Ubuntu" sans-serif;
      font-size: 45px;
      font-weight: 400;
      // line-height: 45.71px;
      margin-top: -7px;
      text-align: center;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
      background: radial-gradient(
        157.47% 1012.75% at 6.55% 17.65%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
    .paragraphOfChart {
      text-align: center;
      margin: 2rem 0rem;
      width: 50%;
    }
  }

  .StartageryCharPart {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: start;
    gap: 3rem;
    .SingleStategryCharPart {
      width: 45%;
      .imageCharPart {
        display: flex;
        justify-content: space-between;
        border-radius: 41px;
        border: 1px solid rgba(246, 246, 246, 0.2);
        background: rgba(19, 19, 19, 0.1);
        backdrop-filter: blur(17.5px);
        margin: 2rem 0rem;
        width: 100%;
        height: 15rem;
        padding: 2rem;
        .fireSolidLeft {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          border-radius: 24px;
          border: 1px solid rgba(246, 246, 246, 0.2);
          background: rgba(246, 246, 246, 0.1);
          backdrop-filter: blur(20px);
          width: 30%;
          height: 100%;

          .svg-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 0;
            .icon {
              width: 40px;
              height: 40px;
              &__circle {
                fill: #3498db;
                stroke: #2980b9;
                stroke-width: 5;
              }

              &__line {
                stroke: #e74c3c;
                stroke-width: 3;
              }

              &__rect {
                fill: #2ecc71;
                stroke: #27ae60;
                stroke-width: 4;
              }
            }
          }
          .bgbg {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 7px;
            margin-top: -20px;

            .dayhead,
            span {
              display: inline-block;
              font-family: "Ubuntu";
              font-size: 26px;
              font-weight: 400;
              line-height: 29.87px;
              margin: 0;
              background: #f6f6f6;
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }
          .grth {
            font-family: "Maven Pro";
            font-size: 14px;
            font-weight: 400;
            line-height: 16.45px;
            background: #f6f6f6a6;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
        }

        .fireSolidRight {
          display: flex;
          flex-direction: column;
          align-items: start;
          font-family: Arial, sans-serif;
          width: 65%;

          .hh {
            font-size: 1.2rem;
            margin-bottom: 1rem;
            color: #333;
            span {
              font-family: "Ubuntu";
              font-size: 2rem;
              font-weight: 400;
              line-height: 36.77px;
            }
          }

          .LineToShowValue {
            position: relative;
            width: 100%;
            background-color: #d3d3d3;
            // overflow: hidden;
            border-radius: 24px;
            background: rgba(246, 246, 246, 0.2);
            height: 23px;
            flex-shrink: 0;
            margin-top: -5px;
            .progressLine {
              position: absolute;
              left: 0;
              height: 100%;
              background-color: white;
              box-shadow: 0 0 15px white;
              border-radius: 54px;
              transition: width 2s linear;
            }
          }
          .belowToShowfire {
            width: 100%;
            height: 100%;
            border-radius: 24px;
            border: 1px solid rgba(246, 246, 246, 0.2);
            background: rgba(246, 246, 246, 0.2);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            gap: 12px;
            margin-top: 15px;
            padding-bottom: 25px;
            .dayContainer {
              display: flex;
              flex-direction: column;
              align-items: center;
              height: 20%;

              .tickCircle {
                width: 26px;
                height: 26px;
                flex-shrink: 0;
                border-radius: 50%;
                background-color: #d3d3d3;
                // transition: background-color 1s ease-in-out, transform 1s ease;
              }

              p {
                // margin-top: 0.5rem;
                // font-size: 0.9rem;
                // color: #666;
                color: #f6f6f6;
                font-family: "Maven Pro";
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
              }

              &.highlighted {
                .tickCircle {
                  background-color: red;
                }
              }
            }
          }
        }
      }

      .lineChart {
        width: 100%;
      }
      .DetailsOfStategryPart {
        .NumberStategry {
          color: #f6f6f6;
          font-family: "Maven Pro";
          font-size: 22px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .StategryHeading {
          margin: 0.5rem 0rem 1rem 0rem;
          font-family: Ubuntu;
          font-size: 36px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          background: radial-gradient(
            541.57% 169.64% at 4.98% 17.16%,
            #fff 0%,
            rgba(255, 255, 255, 0) 100%
          );
          width: fit-content;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .StategeryDetaisls {
          margin: 1rem 0rem;
          color: #f6f6f6;
          font-family: "Maven Pro";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
        .StategryPoit {
          .StategryPoit {
            display: flex;
            flex-direction: column;
            gap: 10px;
            position: relative;
          }

          .strategy-item {
            display: flex;
            align-items: center;
            position: relative;
          }

          .tick-circle {
            font-size: 16px;
            color: green;
            margin-right: 10px;
          }

          .strategy-text {
            font-size: 14px;
          }

          .line {
            position: absolute;
            width: 2px;
            background-color: #ccc;
            height: 30px;
            left: 10px; /* Align with the tick-circle */
            top: 30px; /* Adjust for spacing */
          }
        }
      }
      .machinePart {
        position: relative;
        width: 550px;
        height: 410px;
        border: none;
        .bigCloud,
        .smallCloud {
          position: absolute;
          animation: moveClouds 6s linear infinite alternate;
        }

        .bigCloud {
          top: 75px;
          left: 115px;
          width: 100px;
        }

        .smallCloud {
          top: 150px;
          left: 420px;
          width: 81px;
        }

        @keyframes moveClouds {
          0% {
            transform: translateX(0);
          }
          100% {
            transform: translateX(30px);
          }
        }

        .bigGeare,
        .Geare1,
        .Geare2,
        .Geare3 {
          z-index: -1;
          position: absolute;
          animation: rotateGear 4s linear infinite;
        }

        .bigGeare {
          top: 1px;
          right: 10.5rem;
          width: 80px;
          animation: leftrotateGear 6s linear infinite;
        }

        .Geare1 {
          top: 40px;
          left: 259px;
          width: 60px;
        }

        .Geare2 {
          top: 85px;
          left: 19.3rem;
          width: 80px;
          z-index: -1;
          animation: leftrotateGear 3s linear infinite;
        }

        .Geare3 {
          top: 80px;
          left: 260px;
          width: 60px;
        }

        @keyframes rotateGear {
          from {
            transform: rotate(-0deg);
          }
          to {
            transform: rotate(-360deg);
          }
        }
        @keyframes leftrotateGear {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }

        .mainMachine {
          position: absolute;
          z-index: 20;
          top: 130px;
          left: 240px;
          width: 200px;
          filter: saturate(100px);
        }

        .Coint1,
        .Coint2,
        .Coint3,
        .CoinBig1,
        .CoinBig2 {
          position: absolute;
          height: 50px;
          width: 50px;
          border-radius: 50%;
        }

        .Coint1 {
          top: 350px;
          left: 270px;
        }

        .Coint2 {
          height: 30px;
          width: 30px;
          top: 330px;
          left: 350px;
        }

        .Coint3 {
          width: 30px;
          height: 30px;
          top: 320px;
          left: 325px;
          z-index: -1;
        }

        /* Keyframes for glowing effect with individual colors */
        @keyframes glowEffect1 {
          0% {
            box-shadow: 0 0 10px 3px rgba(243, 156, 18, 0.3); // Match .Coint1 color
          }
          100% {
            box-shadow: 0 0 20px 8px rgba(243, 156, 18, 0.8);
          }
        }

        @keyframes glowEffect2 {
          0% {
            box-shadow: 0 0 10px 3px rgba(52, 152, 219, 0.3); // Match .Coint2 color
          }
          100% {
            box-shadow: 0 0 20px 8px rgba(52, 152, 219, 0.8);
          }
        }

        @keyframes glowEffect3 {
          0% {
            box-shadow: 0 0 10px 3px rgba(231, 76, 60, 0.3); // Match .Coint3 color
          }
          100% {
            box-shadow: 0 0 20px 8px rgba(231, 76, 60, 0.8);
          }
        }

        /* Keyframes for floating effect (shared across all coins) */
        @keyframes floatEffect {
          0% {
            transform: translateY(0);
          }
          50% {
            transform: translateY(-10px); // Move up by 10px
          }
          100% {
            transform: translateY(0);
          }
        }

        .CoinBig1 {
          top: 357px;
          left: 340px;
        }

        .CoinBig2 {
          top: 357px;
          left: 300px;
        }
      }
    }
    .ss1 {
      .lineChart {
        padding: 0;
        border: 0;
      }
    }
    .parts3 {
      .imageCharPart {
        height: 100%;
        border: none !important;
        .img33 {
          height: 90%;
          width: 90%;
          object-fit: fit-content;
        }
      }
    }
  }
}

@media (max-width: 1130px) {
  .MainCharPart {
    .StartageryCharPart {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .SingleStategryCharPart {
        width: 555px;
        .machinePart {
          width: 550px;
          height: 410px;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .MainCharPart {
    width: 100%;
    .ChartTopPart {
      width: 100%;

      .headingOfPara {
        text-align: center;
        font-size: 1.7rem;
        margin: auto;
        width: 100%;
      }
      .paragraphOfChart {
        text-align: justify;
        margin: 10px;
        width: 100%;
      }
    }

    .StartageryCharPart {
      width: 100%;
      align-items: center;
      gap: 2rem;
      margin: 0;
      // justify-content: center !important;

      .SingleStategryCharPart {
        width: 100%;
        .imageCharPart {
          display: flex;
          justify-content: space-between;
          border-radius: 41px;
          border: 1px solid rgba(246, 246, 246, 0.2);
          background: rgba(19, 19, 19, 0.1);
          backdrop-filter: blur(17.5px);
          margin: 2rem 0rem;
          width: 100%;
          height: 12rem;
          padding: 1rem;
          .fireSolidLeft {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: 24px;
            border: 1px solid rgba(246, 246, 246, 0.2);
            background: rgba(246, 246, 246, 0.1);
            backdrop-filter: blur(20px);
            width: 30%;
            height: 100%;

            .svg-container {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0;
              width: 90px;
              height: 90px;
              .icon {
                &__circle {
                  fill: #3498db;
                  stroke: #2980b9;
                  stroke-width: 5;
                }

                &__line {
                  stroke: #e74c3c;
                  stroke-width: 3;
                }

                &__rect {
                  fill: #2ecc71;
                  stroke: #27ae60;
                  stroke-width: 4;
                }
              }
            }
            .bgbg {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 7px;
              margin-top: -20px;

              .dayhead,
              span {
                display: inline-block;
                font-family: "Ubuntu";
                font-size: 26px;
                font-weight: 400;
                line-height: 29.87px;
                margin: 0;
                margin-top: 20px;
                background: #f6f6f6;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
            .grth {
              font-family: "Maven Pro";
              font-size: 14px;
              font-weight: 400;
              line-height: 16.45px;
              background: #f6f6f6a6;
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          .fireSolidRight {
            display: flex;
            flex-direction: column;
            align-items: start;
            font-family: Arial, sans-serif;
            width: 65%;

            .hh {
              font-size: 1.2rem;
              margin-bottom: 1rem;
              color: #333;
              span {
                font-family: "Ubuntu";
                font-size: 2rem;
                font-weight: 400;
                line-height: 36.77px;
              }
            }

            .LineToShowValue {
              position: relative;
              width: 100%;
              background-color: #d3d3d3;
              // overflow: hidden;
              border-radius: 24px;
              background: rgba(246, 246, 246, 0.2);
              height: 23px;
              flex-shrink: 0;
              margin-top: -5px;
              .progressLine {
                position: absolute;
                left: 0;
                height: 100%;
                background-color: white;
                box-shadow: 0 0 15px white;
                border-radius: 54px;
                transition: width 2s linear;
              }
            }
            .belowToShowfire {
              width: 100%;
              height: 100%;
              border-radius: 24px;
              border: 1px solid rgba(246, 246, 246, 0.2);
              background: rgba(246, 246, 246, 0.2);
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: space-evenly;
              gap: 0;
              margin-top: 15px;
              padding-bottom: 25px;
              .dayContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 20%;

                .tickCircle {
                  width: 16px;
                  height: 16px;
                  flex-shrink: 0;
                  border-radius: 50%;
                  background-color: #d3d3d3;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  // transition: background-color 1s ease-in-out, transform 1s ease;
                }

                p {
                  // margin-top: 0.5rem;
                  // font-size: 0.9rem;
                  // color: #666;
                  color: #f6f6f6;
                  font-family: "Maven Pro";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                &.highlighted {
                  .tickCircle {
                    background-color: red;
                  }
                }
              }
            }
          }
        }

        .lineChart {
          width: 100%;
        }
        .DetailsOfStategryPart {
          .NumberStategry {
            color: #f6f6f6;
            font-family: "Maven Pro";
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .StategryHeading {
            margin: 0.5rem 0rem 1rem 0rem;
            font-family: Ubuntu;
            font-size: 36px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: radial-gradient(
              541.57% 169.64% at 4.98% 17.16%,
              #fff 0%,
              rgba(255, 255, 255, 0) 100%
            );
            width: fit-content;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .StategeryDetaisls {
            margin: 1rem 0rem;
            color: #f6f6f6;
            font-family: "Maven Pro";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .StategryPoit {
            .StategryPoit {
              display: flex;
              flex-direction: column;
              gap: 10px;
              position: relative;
            }

            .strategy-item {
              display: flex;
              align-items: center;
              position: relative;
            }

            .tick-circle {
              font-size: 16px;
              color: green;
              margin-right: 10px;
            }

            .strategy-text {
              font-size: 14px;
            }

            .line {
              position: absolute;
              width: 2px;
              background-color: #ccc;
              height: 30px;
              left: 10px; /* Align with the tick-circle */
              top: 30px; /* Adjust for spacing */
            }
          }
        }
        .machinePart {
          position: relative;
          width: 550px;
          height: 410px;
          border: none;
          .bigCloud,
          .smallCloud {
            position: absolute;
            animation: moveClouds 6s linear infinite alternate;
          }

          .bigCloud {
            top: 75px;
            left: 115px;
            width: 100px;
          }

          .smallCloud {
            top: 150px;
            left: 420px;
            width: 81px;
          }

          @keyframes moveClouds {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(30px);
            }
          }

          .bigGeare,
          .Geare1,
          .Geare2,
          .Geare3 {
            z-index: -1;
            position: absolute;
            animation: rotateGear 4s linear infinite;
          }

          .bigGeare {
            top: 1px;
            right: 10.5rem;
            width: 80px;
            animation: leftrotateGear 6s linear infinite;
          }

          .Geare1 {
            top: 40px;
            left: 259px;
            width: 60px;
          }

          .Geare2 {
            top: 85px;
            left: 19.3rem;
            width: 80px;
            z-index: -1;
            animation: leftrotateGear 3s linear infinite;
          }

          .Geare3 {
            top: 80px;
            left: 260px;
            width: 60px;
          }

          @keyframes rotateGear {
            from {
              transform: rotate(-0deg);
            }
            to {
              transform: rotate(-360deg);
            }
          }
          @keyframes leftrotateGear {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }

          .mainMachine {
            position: absolute;
            z-index: 20;
            top: 130px;
            left: 240px;
            width: 200px;
            filter: saturate(100px);
          }

          .Coint1,
          .Coint2,
          .Coint3,
          .CoinBig1,
          .CoinBig2 {
            position: absolute;
            height: 50px;
            width: 50px;
            border-radius: 50%;
          }

          .Coint1 {
            top: 350px;
            left: 270px;
          }

          .Coint2 {
            height: 30px;
            width: 30px;
            top: 330px;
            left: 350px;
          }

          .Coint3 {
            width: 30px;
            height: 30px;
            top: 320px;
            left: 325px;
            z-index: -1;
          }

          /* Keyframes for glowing effect with individual colors */
          @keyframes glowEffect1 {
            0% {
              box-shadow: 0 0 10px 3px rgba(243, 156, 18, 0.3); // Match .Coint1 color
            }
            100% {
              box-shadow: 0 0 20px 8px rgba(243, 156, 18, 0.8);
            }
          }

          @keyframes glowEffect2 {
            0% {
              box-shadow: 0 0 10px 3px rgba(52, 152, 219, 0.3); // Match .Coint2 color
            }
            100% {
              box-shadow: 0 0 20px 8px rgba(52, 152, 219, 0.8);
            }
          }

          @keyframes glowEffect3 {
            0% {
              box-shadow: 0 0 10px 3px rgba(231, 76, 60, 0.3); // Match .Coint3 color
            }
            100% {
              box-shadow: 0 0 20px 8px rgba(231, 76, 60, 0.8);
            }
          }

          /* Keyframes for floating effect (shared across all coins) */
          @keyframes floatEffect {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px); // Move up by 10px
            }
            100% {
              transform: translateY(0);
            }
          }

          .CoinBig1 {
            top: 357px;
            left: 340px;
          }

          .CoinBig2 {
            top: 357px;
            left: 300px;
          }
        }
      }
      .ss1 {
        .lineChart {
          padding: 0;
          border: 0;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .MainCharPart {
    width: 100%;
    .ChartTopPart {
      width: 100%;

      .headingOfPara {
        text-align: center;
        font-size: 1.7rem;
        margin: auto;
        width: 100%;
      }
      .paragraphOfChart {
        text-align: justify;
        margin: 10px;
        width: 100%;
      }
    }

    .StartageryCharPart {
      width: 100%;
      align-items: center;
      gap: 3rem;
      margin: 0;

      .SingleStategryCharPart {
        width: 100%;

        .imageCharPart {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          border-radius: 41px;
          border: 1px solid rgba(246, 246, 246, 0.2);
          background: rgba(19, 19, 19, 0.1);
          backdrop-filter: blur(17.5px);
          margin: 0rem;
          width: 100%;
          height: 12rem;
          height: 100%;

          padding: 1rem;
          .fireSolidLeft {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            border-radius: 24px;
            border: 1px solid rgba(246, 246, 246, 0.2);
            background: rgba(246, 246, 246, 0.1);
            backdrop-filter: blur(20px);
            width: 150px;
            height: 100%;

            .svg-container {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 0;
              width: 90px;
              height: 90px;
              .icon {
                &__circle {
                  fill: #3498db;
                  stroke: #2980b9;
                  stroke-width: 5;
                }

                &__line {
                  stroke: #e74c3c;
                  stroke-width: 3;
                }

                &__rect {
                  fill: #2ecc71;
                  stroke: #27ae60;
                  stroke-width: 4;
                }
              }
            }
            .bgbg {
              display: flex;
              justify-content: center;
              align-items: center;
              gap: 7px;
              margin-top: -20px;

              .dayhead,
              span {
                display: inline-block;
                font-family: "Ubuntu";
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 29.87px;
                margin: 0;
                margin-top: 20px;
                background: #f6f6f6;
                background-clip: text;
                -webkit-background-clip: text;
                -webkit-text-fill-color: transparent;
              }
            }
            .grth {
              font-family: "Maven Pro";
              font-size: 14px;
              font-weight: 400;
              line-height: 16.45px;
              background: #f6f6f6a6;
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
            }
          }

          .fireSolidRight {
            display: flex;
            flex-direction: column;
            align-items: start;
            font-family: Arial, sans-serif;
            width: 100%;

            .hh {
              font-size: 1rem;
              margin-bottom: 1rem;
              color: #333;
              span {
                font-family: "Ubuntu";
                font-size: 1.7rem;
                font-weight: 400;
                line-height: 36.77px;
              }
            }

            .LineToShowValue {
              position: relative;
              width: 98%;
              background-color: #d3d3d3;
              // overflow: hidden;
              border-radius: 24px;
              background: rgba(246, 246, 246, 0.2);
              height: 18px;
              flex-shrink: 0;
              margin: auto;
              margin-top: -5px;
              .progressLine {
                position: absolute;
                left: 0;
                height: 100%;
                background-color: white;
                box-shadow: 0 0 15px white;
                border-radius: 54px;
                transition: width 2s linear;
              }
            }
            .belowToShowfire {
              align-items: center;
              justify-content: space-evenly;
              gap: 0;
              padding: 0;
              .dayContainer {
                display: flex;
                flex-direction: column;
                align-items: center;
                height: 100%;
                padding: 0;
                margin: 0;
                margin-top: 19px;
                .tickCircle {
                  width: 16px;
                  height: 16px;

                  display: flex;
                  justify-content: center;
                  align-items: center;
                  // transition: background-color 1s ease-in-out, transform 1s ease;
                }

                p {
                  // margin-top: 0.5rem;
                  // font-size: 0.9rem;
                  // color: #666;
                  color: #f6f6f6;
                  font-family: "Maven Pro";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                }

                &.highlighted {
                  .tickCircle {
                    background-color: red;
                  }
                }
              }
            }
          }
        }

        .lineChart {
          width: 100%;
        }
        .DetailsOfStategryPart {
          .NumberStategry {
            color: #f6f6f6;
            font-family: "Maven Pro";
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .StategryHeading {
            margin: 0.5rem 0rem 1rem 0rem;
            font-family: "Ubuntu";
            font-size: 1.7rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            background: radial-gradient(
              541.57% 169.64% at 4.98% 17.16%,
              #fff 0%,
              rgba(255, 255, 255, 0) 100%
            );
            width: fit-content;
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
          }
          .StategeryDetaisls {
            margin: 1rem 0rem;
            color: #f6f6f6;
            font-family: "Maven Pro";
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .StategryPoit {
            .StategryPoit {
              display: flex;
              flex-direction: column;
              gap: 10px;
              position: relative;
            }

            .strategy-item {
              display: flex;
              align-items: center;
              position: relative;
            }

            .tick-circle {
              font-size: 16px;
              color: green;
              margin-right: 10px;
            }

            .strategy-text {
              font-size: 14px;
            }

            .line {
              position: absolute;
              width: 2px;
              background-color: #ccc;
              height: 30px;
              left: 10px; /* Align with the tick-circle */
              top: 30px; /* Adjust for spacing */
            }
          }
        }
        .machinePart {
          position: relative;
          width: 550px;
          height: 410px;
          border: none;
          margin-left: -100px;
          @media (max-width: 465px) {
            width: 500px;
            height: 410px;
            margin-left: -150px;
          }
          @media (max-width: 365px) {
            width: 500px;
            height: 410px;
            margin-left: -180px;
          }
          .bigCloud,
          .smallCloud {
            position: absolute;
            animation: moveClouds 6s linear infinite alternate;
          }

          .bigCloud {
            top: 105px;
            left: 165px;
            width: 50px;
          }

          .smallCloud {
            top: 150px;
            left: 420px;
            width: 41px;
          }

          @keyframes moveClouds {
            0% {
              transform: translateX(0);
            }
            100% {
              transform: translateX(30px);
            }
          }

          .bigGeare,
          .Geare1,
          .Geare2,
          .Geare3 {
            z-index: -1;
            position: absolute;
            animation: rotateGear 4s linear infinite;
          }

          .bigGeare {
            top: 1px;
            right: 10.5rem;
            width: 80px;
            animation: leftrotateGear 6s linear infinite;
            @media (max-width: 465px) {
              right: 7.5rem;
            }
          }

          .Geare1 {
            top: 40px;
            left: 259px;
            width: 60px;
          }

          .Geare2 {
            top: 85px;
            left: 19.3rem;
            width: 80px;
            z-index: -1;
            animation: leftrotateGear 3s linear infinite;
          }

          .Geare3 {
            top: 80px;
            left: 260px;
            width: 60px;
          }

          @keyframes rotateGear {
            from {
              transform: rotate(-0deg);
            }
            to {
              transform: rotate(-360deg);
            }
          }
          @keyframes leftrotateGear {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }

          .mainMachine {
            position: absolute;
            z-index: 20;
            top: 130px;
            left: 240px;
            width: 200px;

            filter: saturate(100px);
          }

          .Coint1,
          .Coint2,
          .Coint3,
          .CoinBig1,
          .CoinBig2 {
            position: absolute;
            height: 50px;
            width: 50px;
            border-radius: 50%;
          }

          .Coint1 {
            top: 350px;
            left: 270px;
          }

          .Coint2 {
            height: 30px;
            width: 30px;
            top: 330px;
            left: 350px;
          }

          .Coint3 {
            width: 30px;
            height: 30px;
            top: 320px;
            left: 325px;
            z-index: -1;
          }

          /* Keyframes for glowing effect with individual colors */
          @keyframes glowEffect1 {
            0% {
              box-shadow: 0 0 10px 3px rgba(243, 156, 18, 0.3); // Match .Coint1 color
            }
            100% {
              box-shadow: 0 0 20px 8px rgba(243, 156, 18, 0.8);
            }
          }

          @keyframes glowEffect2 {
            0% {
              box-shadow: 0 0 10px 3px rgba(52, 152, 219, 0.3); // Match .Coint2 color
            }
            100% {
              box-shadow: 0 0 20px 8px rgba(52, 152, 219, 0.8);
            }
          }

          @keyframes glowEffect3 {
            0% {
              box-shadow: 0 0 10px 3px rgba(231, 76, 60, 0.3); // Match .Coint3 color
            }
            100% {
              box-shadow: 0 0 20px 8px rgba(231, 76, 60, 0.8);
            }
          }

          /* Keyframes for floating effect (shared across all coins) */
          @keyframes floatEffect {
            0% {
              transform: translateY(0);
            }
            50% {
              transform: translateY(-10px); // Move up by 10px
            }
            100% {
              transform: translateY(0);
            }
          }

          .CoinBig1 {
            top: 357px;
            left: 340px;
          }

          .CoinBig2 {
            top: 357px;
            left: 300px;
          }
        }
      }
      .ss1 {
        .lineChart {
          width: 100%;
          height: 100%;
          padding: 0;
          border: 0;
        }
      }
    }
  }
}
