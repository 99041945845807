.contact-main {
  height: 100%;
  width: 100%;
  backdrop-filter: blur(174px);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5rem 0;

  .contactform-outer {
    width: 85%;
    height: 100%;
    margin: auto;
    border: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    border-radius: 42px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 2rem 0;
    overflow: hidden;
    z-index: 3;

    .form-above {
      .bg1 {
        position: absolute;
        width: 100%;
        z-index: -1;
        top: -1%;
        left: 0;
      }
      h1 {
        text-align: center;
        font-size: 3.68rem;
        font-weight: 400;
        width: fit-content;
        background: radial-gradient(
          104.21% 376.56% at 50% 50%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        background-clip: text;
        color: transparent;
        margin: auto;
      }
      .form-outer-content {
        text-align: center;
        z-index: 1;
        color: #f6f6f6;
        font-family: "Maven Pro";
        font-size: 14px;
        font-weight: 400;
        line-height: 16.45px;
        margin-top: 10px;
      }
    }

    .bg2 {
      position: absolute;
      bottom: -20%;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      margin-top: 20rem;
      width: 100%;
    }

    .blur {
      position: absolute;
      bottom: -20%;
      left: 50%;
      transform: translateX(-50%);
      z-index: -1;
      filter: blur(30px);
    }
  }

  .form {
    width: 60%;
    width: 700px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: auto;
    gap: 23px;
    border: 1px solid rgba(255, 255, 255, 0.2);
    padding: 4rem 1rem;
    background: rgba(43, 43, 43, 0.897);
    backdrop-filter: blur(4px);
    margin-top: 2rem;
    border-radius: 3rem;
    z-index: 99999;
    // background-color: black;

    input {
      width: 90%;
      font-size: 18px;
      font-weight: 400;
      font-family: "Maven Pro";
      padding: 1rem;
      color: white;
      outline: none;
      border-radius: 20px;
      background: rgba(246, 246, 246, 0.0705882353);
      border: 1px solid;
      border-image-source: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 5%,
        rgba(255, 255, 255, 0.5882352941) 25%,
        rgba(255, 255, 255, 0) 90%
      );
      border-image-slice: 1;
    }

    .number-section {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      input {
        width: 49%;
      }
    }
    .textaria {
      border-radius: 20px;
      background: rgba(246, 246, 246, 0.0705882353);
      width: 90%;
      padding: 1rem 2rem;
      border: 0;
      border: 1px solid;
      border-image-source: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 5%,
        rgba(255, 255, 255, 0.5882352941) 25%,
        rgba(255, 255, 255, 0) 90%
      );
      border-image-slice: 1;

      textarea {
        width: 100%;
        border: none;
        outline: none;
        color: white;
        background: transparent;
        font-size: 18px;
        font-weight: 400;
        font-family: "Maven Pro";
      }
      button {
        width: 100%;
        color: white;
        border: none;
        padding: 1.1rem 0;
        font-size: 1rem;
        font-weight: 700;
        font-family: "Maven Pro";
        border-radius: 17px;
        border: 0;
        border: 1px solid;
        border-image-source: linear-gradient(
            108.65deg,
            #ffffff -26.25%,
            rgba(255, 255, 255, 0) 31.16%
          ),
          linear-gradient(
            0deg,
            rgba(255, 255, 255, 0.2),
            rgba(255, 255, 255, 0.2)
          );
        background: radial-gradient(
            43.34% 122.55% at 0% -40.2%,
            rgba(255, 255, 255, 0.9) 0%,
            rgba(255, 255, 255, 0) 100%
          ),
          linear-gradient(0deg, #4285f4, #4285f4);
        background-blend-mode: normal;
      }
    }
  }
}

@media (max-width: 1024px) {
  .contact-main {
    padding: 5rem 0;

    .contactform-outer {
      width: 90%;
      height: 100%;

      gap: 20px;
      padding: 2rem 0;
      z-index: 3;

      .form-above {
        .bg1 {
          position: absolute;
          width: 100%;
          z-index: -1;
          top: -1%;
          left: 0;
        }
        h1 {
          text-align: center;
          font-size: 2.1rem;
        }
        .form-outer-content {
          z-index: 1;
        }
      }

      .bg2 {
        position: absolute;
        bottom: -20%;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        margin-top: 20rem;
        width: 100%;
      }

      .blur {
        position: absolute;
        bottom: -20%;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        filter: blur(30px);
      }
    }

    .form {
      width: 600px;
      padding: 4rem 1rem;
      margin-top: 2rem;

      input {
        width: 90%;
        font-size: 18px;

        padding: 10px 20px;
      }

      .number-section {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          width: 49%;
        }
      }
      .textaria {
        border-radius: 20px;
        background: rgba(246, 246, 246, 0.0705882353);
        width: 90%;
        padding: 10px 20px;

        button {
          width: 100%;
          padding: 14px 0;
          font-size: 1rem;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .contact-main {
    .contactform-outer {
      padding: 2rem 0;
      z-index: 3;
      .form-above {
        .bg1 {
          position: absolute;
          width: 100%;
          z-index: -1;
          top: -1%;
          left: 0;
        }
        h1 {
          text-align: center;
          font-size: 2.1rem;
        }
        .form-outer-content {
          z-index: 1;
          font-size: 14px;
          padding: 0 4rem;
          br {
            display: none;
          }
        }
      }
      .bg2 {
        position: absolute;
        bottom: -20%;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        margin-top: 20rem;
        width: 100%;
      }

      .blur {
        position: absolute;
        bottom: -20%;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        filter: blur(30px);
      }
    }

    .form {
      width: 500px;
      padding: 3rem 1rem;
      margin-top: 2rem;

      input {
        width: 90%;
        font-size: 18px;

        padding: 10px 20px;
      }

      .number-section {
        width: 90%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        input {
          width: 49%;
        }
      }
      .textaria {
        padding: 10px 20px;
        // height: 150px;

        button {
          width: 100%;
          padding: 14px 0;
          font-size: 1rem;
        }
      }
    }
  }
}

@media (max-width: 615px) {
  .contact-main {
    padding: 0;

    background: transparent;
    .contactform-outer {
      width: 90%;
      height: 100%;
      gap: 20px;
      padding: 0;
      z-index: 3;
      @media (max-width: 480px) {
        border: none;
      }

      .form-above {
        .bg1 {
          position: absolute;
          width: 100%;
          z-index: -1;
          top: -1%;
          left: 0;
        }
        h1 {
          text-align: center;
          font-size: 1.7rem;
        }
        .form-outer-content {
          z-index: 1;
        }
      }
      .bg1 {
        display: none;
      }
      .bg2 {
        display: none;
        position: absolute;
        bottom: -20%;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        margin-top: 20rem;
        width: 100%;
      }

      .blur {
        display: none;
        position: absolute;
        bottom: -20%;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        filter: blur(30px);
      }
    }

    .form {
      width: 80%;
      padding: 2rem 1rem;
      margin-top: 0rem;

      @media (max-width: 480px) {
        width: 95%;
        border-radius: 20px;
      }
      input {
        padding: 5px 20px;
        font-size: 14px;
      }

      .number-section {
        width: 90%;
        gap: 10px;
        flex-direction: column;
        input {
          width: 100%;
        }
      }
      .textaria {
        padding: 0;
        padding: 10px 20px;

        textarea {
          font-size: 14px;
        }
        button {
          padding: 5px 0;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
@media (max-width: 490px) {
  .contact-main {
    .contactform-outer {
      width: 90%;
      height: 100%;
      gap: 20px;
      padding: 2rem 0;
      z-index: 3;
      @media (max-width: 480px) {
        border: none;
      }

      .form-above {
        .bg1 {
          position: absolute;
          width: 100%;
          z-index: -1;
          top: -1%;
          left: 0;
        }
        h1 {
          text-align: center;
          font-size: 2.1rem;
        }
        .form-outer-content {
          z-index: 1;
          padding: 0;
        }
      }
      .bg1 {
        display: none;
      }
      .bg2 {
        display: none;
        position: absolute;
        bottom: -20%;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        margin-top: 20rem;
        width: 100%;
      }

      .blur {
        display: none;
        position: absolute;
        bottom: -20%;
        left: 50%;
        transform: translateX(-50%);
        z-index: -1;
        filter: blur(30px);
      }
    }

    .form {
      width: 80%;
      padding: 2rem 1rem;
      margin-top: 0rem;
      @media (max-width: 480px) {
        width: 95%;
        border-radius: 20px;
      }
      input {
        padding: 5px 20px;
        font-size: 14px;
      }

      .number-section {
        width: 90%;
        gap: 10px;
        flex-direction: column;
        input {
          width: 100%;
        }
      }
      .textaria {
        padding: 0;
        padding: 10px 20px;

        textarea {
          font-size: 14px;
        }
        button {
          padding: 5px 0;
          font-size: 14px;
          font-weight: 400;
        }
      }
    }
  }
}
