.our-clients {
  .our-clients-sub {
    padding-top: 3%;
    padding-bottom: 3%;
    width: 92%;
    margin: auto;
    // text-align: center;
    .heading {
      padding-right: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h2 {
        font-size: 45px;
        padding-bottom: 10px;
        // background: var(--bg-sec-gradient);
        background: radial-gradient(
          256.66% 747.37% at 0% 0%,
          #ffffff 0%,
          #4285f4 16.41%,
          rgba(66, 133, 244, 0) 100%
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        width: fit-content;
      }
      h6 {
        font-size: 20px;
      }
    }
    .logo-carousel {
      .item {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        height: 150px;
        // background: rgb(255, 255, 255);
        
        img {
          max-height: 90%;
          width: fit-content;
          max-width: 90%;
          mix-blend-mode:multiply;
        }
      }
      .scrollc {
        .slick-slider {
          .slick-list {
            .slick-track {
              display: flex;
              justify-content: center;
              align-items: center;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .our-clients {
    .our-clients-sub {
      padding-top: 10%;
      .heading {
        h2 {
          padding-bottom: 0;
          font-size: 23px;
        }
        h6 {
          font-size: 15px;
        }
      }
      .logo-carousel {
        .owl-carousel {
          .owl-item {
            padding: 0;
          }
        }
      }
    }
  }
}
