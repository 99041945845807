.Performance-Marketing-Workflow {
  position: relative;
  .bgbgbg {
    width: 334px;
    height: 334px;
    border-radius: 50%;
    background: #cf4158;

    filter: blur(104px);
    position: absolute;
    top: -20%;
    right: -10%;
    z-index: -1;
  }
  .bgbgbg1 {
    width: 258px;
    height: 258px;
    border-radius: 50%;
    background: #4285f4;
    filter: blur(134px);
    position: absolute;
    top: 8%;
    right: 9%;
    z-index: -1;
  }
  .Performance-hd {
    padding-left: 4rem;
    font-family: "Ubuntu";
    font-size: 2.75rem;
    font-weight: 400;
    line-height: 3.78rem;
    text-align: left;

    background: radial-gradient(
        166.44% 541.54% at 4.98% 17.16%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      )
      /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: fit-content;
    line-height: 51px;
    margin: auto;
  }
  .p-paragraph {
    font-family: "Maven Pro";
    font-size: 14px;
    font-weight: 400;
    line-height: 16.45px;
    text-align: center;
    background: #f6f6f6cc;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: fit-content;
    margin: auto;
    margin-top: 20px;
  }
  .cardOuter {
    overflow-x: auto;

    scrollbar-width: none;
    margin-top: 4rem;
    z-index: 22;
    padding-bottom: 2rem;
    padding-left: 4rem;
    height: 500px;
    position: relative;
    scroll-snap-type: x mandatory; /* Optional, for smoother scroll snap effect */
    -webkit-overflow-scrolling: touch;

    .card-section {
      display: flex;
      width: max-content;
      gap: 40px;
      padding: 2rem 0;
      border-bottom: 2px solid #f6f6f633;
      position: relative;
      .cardcontainers {
        // background: red;
        .card-item {
          display: flex;
          align-items: start;
          gap: 30px;
          width: 661px;
          height: 286px;
          overflow: hidden;
          padding: 40px 20px;
          position: relative;
          border: 1px solid rgba(255, 0, 0, 0.199);
          border-radius: 27px;
          border: 1px solid #f6f6f633;
          background: #f6f6f612;

          .bgwhite {
            width: 80px;
            height: 80px;
            position: absolute;
            top: -29%;
            left: -5%;
            background: #f6f6f6;
            filter: blur(64px);
            top: 0;
          }

          img {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            object-fit: cover;
          }
          .information {
            h3 {
              color: #ffffff;
              font-size: 1.4rem;
              font-family: "Ubuntu";
              font-weight: 400;
            }
            .item-details {
              font-size: 12px;
              color: #ffffffcc;
              font-family: "Maven Pro";
              font-weight: 400;
              line-height: 14.45px;
              background: #f6f6f6cc;
              -webkit-background-clip: text;
              background-clip: text;
              color: transparent;
              width: fit-content;
            }
            .work-involved {
              h5 {
                font-family: "Maven Pro";
                font-size: 12px;
                font-weight: 400;
                line-height: 14.1px;
                text-align: left;
                text-underline-position: from-font;
                text-decoration-skip-ink: none;
                background: #f6f6f6cc;
                -webkit-background-clip: text;
                background-clip: text;
                color: transparent;
                width: fit-content;
              }
              .work-container {
                display: flex;
                gap: 20px;
                .wk-type {
                  font-family: "Ubuntu";
                  font-size: 12px;
                  font-weight: 400;
                  line-height: 13.79px;
                  text-align: center;
                  border: 1px solid #f6f6f633;
                  border-radius: 5px;
                  background: radial-gradient(
                    78.17% 203.96% at 6.34% 25.68%,
                    rgba(255, 255, 255, 0.3) 0%,
                    rgba(255, 255, 255, 0.1) 100%
                  );
                  padding: 4px 10px;
                }
              }
            }
          }
        }
        .phase {
          z-index: 2;
          height: 24px;
          width: 95px;
          font-family: "Ubuntu";
          // overflow: visible;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          background: radial-gradient(
              78.17% 203.96% at 6.34% 25.68%,
              rgba(255, 255, 255, 0.3) 0%,
              rgba(255, 255, 255, 0.1) 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          border-radius: 5px;
          border: 1px solid #ffffff33;
          backdrop-filter: blur(15px);
          display: flex;
          justify-content: center;
          align-items: center;
          position: absolute;
          bottom: -13px;
          margin-left: 280px;
        }
      }
    }

    .whiteline {
      margin-top: 1rem;
      width: fit-content;
      height: 15px;
      display: flex;
      align-items: center;
      z-index: -1;
      // background: red;
      // width: 200%;
      .lineOuter {
        display: flex;
        align-items: center;
        width: 655px;
        .line {
          height: 2px;
          background: #f6f6f612;
          width: 45%;
        }
        button {
          z-index: 2;
          height: 24px;
          width: 95px;
          font-family: "Ubuntu";
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
          background: radial-gradient(
              78.17% 203.96% at 6.34% 25.68%,
              rgba(255, 255, 255, 0.3) 0%,
              rgba(255, 255, 255, 0.1) 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          border-radius: 8px;
          border: 1px solid #ffffff33;
          backdrop-filter: blur(15px);
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .Performance-Marketing-Workflow {
    .Performance-hd {
      padding-left: 0rem;
      font-size: 2.3rem;
      line-height: 2.78rem;
    }
    .p-paragraph {
      font-size: 14px;
      font-weight: 400;
      line-height: 16.45px;
      margin-top: 10px;
    }

    .cardOuter {
      margin-top: 30px;
    }
  }
}
@media (max-width: 768px) {
  .Performance-Marketing-Workflow {
    .Performance-hd {
      padding-left: 0rem;
      font-size: 1.6rem;
      line-height: 1.7rem;
      @media (max-width: 550px) {
        margin: auto !important;
        text-align: center;
      }
    }
    .p-paragraph {
      width: 90%;

      font-size: 14px;
      margin-top: 10px;
      br {
        display: none;
      }
    }
    .cardOuter {
      padding-left: 1.5rem;
      overflow-y: hidden;
      padding-right: 1.5rem;
      .card-section {
        gap: 20px;
        .cardcontainers {
          .card-item {
            flex-direction: column;
            gap: 30px;
            width: 400px;

            height: 100%;

            padding: 25px;
            .information {
              .work-involved {
                .work-container {
                  flex-wrap: wrap;
                  gap: 5px;
                }
              }
            }
          }
        }
      }

      .whiteline {
        margin-top: 1rem;

        height: 15px;
        display: flex;
        align-items: center;
        width: 1658px;
        z-index: -1;

        .lineOuter {
          display: flex;
          align-items: center;
          width: 458px;

          .line {
            height: 2px;
            background: #f6f6f612;
            width: 45%;
          }
          .pdd {
            padding-left: 5rem !important;
          }
        }
      }
    }
  }
}
@media (max-width: 470px) {
  .Performance-Marketing-Workflow {
    .cardOuter {
      padding-left: 1.5rem;
      padding-bottom: 1rem;
      height: 100%;
      .card-section {
        gap: 20px;
        .cardcontainers {
          .card-item {
            gap: 10px;
            width: 310px;
            height: 100%;
            padding: 25px;
            @media (max-width: 345px) {
              width: 280px;
            }
            .information {
              .work-involved {
                .work-container {
                  flex-wrap: wrap;
                  gap: 5px;
                }
              }
            }
            .information {
              .work-involved {
                .work-container {
                  .wk-type {
                    margin: 0;
                  }
                }
              }
            }
          }
          .phase {
            margin-left: 100px;
          }
        }
      }

      .whiteline {
        width: 1288px;

        .lineOuter {
          display: flex;
          align-items: center;
          width: 458px;

          .line {
            height: 2px;
            background: #f6f6f612;
            width: 45%;
          }
          .pdd {
            padding-left: 5rem !important;
          }
        }
      }
    }
  }
}
@media (max-width: 345px) {
  .Performance-Marketing-Workflow {
    .cardOuter {
      .card-section {
        .cardcontainers {
          .card-item {
            width: 280px;
          }
        }
      }
    }

    .whiteline {
      width: 988px;
      display: none !important;
      .lineOuter {
        display: flex;
        align-items: center;
        width: 358px;

        .line {
          height: 2px;
          background: #f6f6f612;
          width: 35%;
        }
        .pdd {
          padding-left: 5rem !important;
        }
      }
    }
  }
}
