.mainContactFormForEvery {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: start;
  padding: 0 4rem;

  .MessageOfContact {
    width: 40%;
    height: 100%;

    .QR-code {
      height: 220px;
      width: 220px;
      margin-bottom: 10px;
      margin-left: 4rem;

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    .letstalk {
      // margin-bottom: 3rem;

      background: radial-gradient(
        104.21% 376.56% at 50% 50%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      width: fit-content;
      background-clip: text;
      color: transparent;
      font-size: 2.9rem;
      font-weight: 400;
      line-height: 55px;
      text-align: left;

      span {
        background: radial-gradient(
          256.66% 747.37% at 0% 0%,
          #ffffff 0%,
          #4285f4 16.41%,
          rgba(66, 133, 244, 0) 100%
        );
        background-clip: text;
        color: transparent;
        font-weight: 400;
      }
    }
  }

  .ContactForm {
    width: 60%;
    height: 100%;
  }
}

@media (max-width: 1024px) {
  .mainContactFormForEvery {
    .MessageOfContact {
      .QR-code {
        height: 220px;
        width: 220px;
        margin-bottom: 10px;
        margin-left: 0;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .letstalk {
        font-size: 2.1rem;
        line-height: 33px;
      }
    }
  }
}

@media (max-width: 778px) {
  .mainContactFormForEvery {
    height: 100%;
    flex-direction: column;
    gap: 30px;
    padding: 0;
    padding-top: 2rem;
    padding-bottom: 4rem;
    .MessageOfContact {
      margin-top: 0;
      width: 100%;
      padding: 0;
      padding-left: 10px;
      gap: 20px;
      display: flex;
      justify-content: space-between;
      align-items: start;
      .QR-code {
        height: 150px;
        width: 150px;
        margin-bottom: 10px;
        order: 1;
      }
      .letstalk {
        margin-bottom: 0;
        font-size: 2.1rem;
        line-height: 36px;
        margin-top: 5px;
      }
    }
    .ContactForm {
      height: 100%;
      width: 100%;
      margin-right: 0;
      padding: 0 !important;
      margin: 0;
    }
  }
}
@media (max-width: 520px) {
  .mainContactFormForEvery {
    flex-direction: column;
    gap: 25px;
    .MessageOfContact {
     
      padding: 0;
      .letstalk {
        margin-bottom: 0;
        font-size: 1.7rem;
        line-height: 28px;
        text-align: start !important;
      }
    }
    .ContactForm {
      width: 100%;
      margin-right: 0;
      padding: 0;
    }
  }
}
