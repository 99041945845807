.BrandHome-container {
  .BrandHome {
    width: 100%;
    height: 40rem;
    height: 27.65rem;
    display: flex;
    justify-content: space-between;
    // border: 1px solid red;

    .brandLeft {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 20px;
      .brandButton {
        width: fit-content;
        font-family: "Maven Pro";
        font-size: 14px;
        font-weight: 400;
        padding: 4px 12px;
        border-radius: 5px;
        background: radial-gradient(
            78.17% 203.96% at 6.34% 25.68%,
            rgba(255, 255, 255, 0.3) 0%,
            rgba(255, 255, 255, 0.1) 100%
          )
          /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
        border: 1px solid #ffffff33;
      }
      .buildingHead {
        font-family: "Ubuntu";
        font-size: 2.78rem;
        font-weight: 400;
        line-height: 51.24px;
        background: radial-gradient(
          164.38% 728.43% at 0% 50%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        span {
          background: linear-gradient(
            90deg,
            #f68447 0%,
            #f36169 29.83%,
            #f36169 72.32%,
            #dd67b5 100%
          );
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
      }
    }
    .brandRight {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .brand-img-Section {
        display: flex;
        justify-content: end;
        .brandGif {
          width: 284px;
          height: 284px;
          border-radius: 50%;

          .brandGif {
            height: 100%;
            width: 100%;
            object-fit: cover;
          }
        }
      }

      .Brand-paragraph {
        margin-top: 20px;
        text-align: start !important;
        font-family: "Maven Pro";
        font-size: 22px;
        font-weight: 400;
        line-height: 25.85px;
        background: #f6f6f6;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        width: fit-content;
      }
    }
  }
  .discover-all-services {
    .dis-paragraph {
      font-family: "Maven Pro";
      font-size: 18px;
      font-weight: 400;
      line-height: 21.15px;
    }
    .marketing-services {
      display: flex;
      justify-content: space-between;
      align-items: end;
      position: relative;

      .service-card-con {
        width: 75%;
        display: flex;
        justify-content: start;
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 10px;

        .marketing-service-item {
          .name-of-service {
            width: fit-content;
            font-family: "Ubuntu";
            font-size: 18px;
            font-weight: 400;
            line-height: 20.68px;
            padding: 9px 19px;
            background: radial-gradient(
                78.17% 203.96% at 6.34% 25.68%,
                rgba(255, 255, 255, 0.3) 0%,
                rgba(255, 255, 255, 0.1) 100%
              )
              /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
            border: 1px solid #ffffff33;
            border-radius: 8px;
            margin: 0;
            &:hover {
              background: radial-gradient(
                78.17% 203.96% at 6.34% 25.68%,
                rgba(255, 255, 255, 0.1) 0%,
                rgba(255, 255, 255, 0) 100%
              );
              box-shadow: inset 0px 0px 30px rgba(255, 255, 255, 0.13);

              /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
            }
          }
        }
      }

      .lefticon {
        width: 35px;
        height: 35px;
        position: relative;
      }
    }
  }
}

@media (max-width: 1024px) {
  .BrandHome-container {
    .BrandHome {
      // height: 35.65rem;
      padding: 0;
      .brandLeft {
        gap: 10px;
        .brandButton {
          padding: 4px 12px;
        }
        .buildingHead {
          font-size: 2.1rem;
          line-height: 36.24px;
        }
      }
      .brandRight {
        .brand-img-Section {
          .brandGif {
            width: 224px;
            height: 224px;
          }
        }

        .Brand-paragraph {
          font-size: 18px;
          line-height: 21.85px;
          br {
            display: none !important;
          }
        }
      }
    }
    .discover-all-services {
      .dis-paragraph {
        font-size: 18px;

        line-height: 21.15px;
      }
      .marketing-services {
        .service-card-con {
          column-gap: 10px;
          row-gap: 8px;

          .marketing-service-item {
            .name-of-service {
              font-size: 16px;
              line-height: 20.68px;
              padding: 5px 19px;
            }
          }
        }
        .lefticon {
          width: 25px;
          height: 25px;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .BrandHome-container {
    padding-top: 3rem;
    .BrandHome {
      height: 25.65rem;
      .brandLeft {
        gap: 10px;
        .brandButton {
          padding: 2px 10px;
          font-size: 14px;
        }
        .buildingHead {
          font-size: 1.7rem;
          line-height: 29.24px;
        }
      }
      .brandRight {
        .brand-img-Section {
          .brandGif {
            width: 180px;
            height: 180px;
          }
        }

        .Brand-paragraph {
          font-size: 16px;
          line-height: 18.85px;
        }
      }
    }
  }
}
@media (max-width: 565px) {
  .BrandHome-container {
    width: 100%;

    .BrandHome {
      height: 100%;
      padding: 2.2rem 0;
      flex-direction: column;
      gap: 30px;
      width: 100%;
      // border: 1px solid red;
      .brandLeft {
        width: 100%;
        gap: 10px;
        align-items: center;

        .brandButton {
          padding: 2px 10px;
          font-size: 14px;
        }
        .buildingHead {
          font-size: 1.7rem;
          line-height: 29.24px;
        }
      }
      .brandRight {
        width: 100%;
        align-items: center;
        gap: 30px;
        flex-direction: row;
        .brand-img-Section {
          .brandGif {
            width: 120px;
            height: 120px;
            box-shadow: none;
          }
        }

        .Brand-paragraph {
          font-size: 16px;
          line-height: 18.85px;
          br {
            display: none;
          }
        }
      }
    }
    .discover-all-services {
      margin-top: 0;
      .dis-paragraph {
        font-size: 18px;
        margin: 0;
      }
      .marketing-services {
        margin-top: 6px;
        .service-card-con {
          column-gap: 5px;
          row-gap: 5px;

          .marketing-service-item {
            .name-of-service {
              font-size: 14px;
              border-radius: 5px;
              padding: 2px 10px;
            }
          }
        }
        .lefticon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
@media (max-width: 465px) {
  .BrandHome-container {
    width: 100%;
    .BrandHome {
      .brandRight {
        width: 100%;
        align-items: center;
        gap: 10px;
        flex-direction: column;
        .brand-img-Section {
          .brandGif {
            width: 250px;
            height: 250px;
            box-shadow: none;
          }
        }

        .Brand-paragraph {
          font-size: 16px;
          line-height: 18.85px;
          br {
            display: none;
          }
        }
      }
    }

    .discover-all-services {
      .dis-paragraph {
        font-size: 18px;
      }
      .marketing-services {
        .service-card-con {
          column-gap: 5px;
          row-gap: 5px;

          .marketing-service-item {
            .name-of-service {
              font-size: 14px;
              border-radius: 5px;
              padding: 2px 10px;
            }
          }
        }
        .lefticon {
          width: 15px;
          height: 15px;
        }
      }
    }
  }
}
