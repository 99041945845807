.Header {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 7px 1.5rem;
  background: linear-gradient(
      0deg,
      rgba(19, 19, 19, 0.2),
      rgba(19, 19, 19, 0.2)
    ),
    radial-gradient(
      42.07% 589.86% at 108.57% -489.85%,
      #f6f6f6 0%,
      rgba(246, 246, 246, 0) 100%
    );
  backdrop-filter: blur(54px);
  border-radius: 14px;

  .logo {
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 7px;
    cursor: pointer;
    img {
      height: 80%;
      object-fit: fit-content;
      cursor: pointer;
      object-fit: cover;
    }
  }

  .hamburger {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: var(--bg-light);

    @media (max-width: 1024px) {
      display: block;
    }
  }

  .innerHeader {
    .underline {
      display: flex;
      gap: 30px;
      list-style-type: none;
      padding: 0;
      margin: 0;
      z-index: 18;

      li {
        font-size: 1rem;
        font-weight: 400;
        cursor: pointer;
        color: var(--bg-light);
        transition: color 0.3s ease;
        z-index: 999;
      }
      li.active {
        font-weight: bold;
      }
    }

    @media (max-width: 1024px) {
      position: fixed;
      // position: absolute;
      z-index: 99999 !important;

      padding: 2rem;
      top: 0;
      height: 22rem;
      top: -900%;
      width: 100%;
      left: 0;
      background: #000000f5;
      background-image: url("../../assets/images//home/bgbbg.jpg");
      background-position: top;
      background-repeat: no-repeat;
      background-size: 110%;
      // background: linear-gradient(
      //   120deg,
      //   #f6e52e 16%,
      //   #ed6357 41%,
      //   #e05a81 51%,
      //   #836ee3 64%,
      //   #5251ae 88%,
      //   // #173575 82%
      // );
      // border-radius: 20px 0 0 20px;
      margin: auto;
      border-radius: 15px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: top 0.3s linear;

      // backdrop-filter: blur(40px);
      @media (max-width: 480px) {
        // width: 70%;
      }
      &.open {
        top: 0;
      }

      .underline {
        flex-direction: column;
        gap: 20px;
        text-align: center;
        z-index: 3333;
        z-index: 99999999999999999 !important;
        position: relative;
      }
    }
  }
}

@media (max-width: 480px) {
  .Header {
    padding: 7px 10px;
    .logo {
      height: 30px;

      img {
        height: 70%;
      }
      .hbutton {
        margin-top: 24px;
        font-size: 14px;
        b {
          font-size: 14px;
        }
      }
    }
  }
}
