.job-main {
  height: 100%;
  width: 100%;

  .form {
    width: 50%;
    background: #000000;
    margin: auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border: 1px solid rgb(171, 171, 171);
    border-radius: 15px;
    padding: 2rem;
    position: relative;
    .danger {
      color: red;
      position: absolute;
      right: 6%;
      cursor: pointer;
    }

    .formbox {
      z-index: 1;
      label {
        display: block;
        color: white;
      }

      input {
        width: 100%;
        box-sizing: border-box;
        cursor: text;
        pointer-events: auto;
        margin: 0;
        padding: 6px 6px;
        z-index: 2;
        border-radius: 5px;
        border: none;
        color: white;
        background: rgb(32, 32, 30);
      }
      textarea,
      input:focus {
        border: none;
        outline: none;
      }

      .btn {
        background: #45a0e5;
        border: none;
        font-weight: 700;
        padding: 0.7rem 2rem;
        border-radius: 9px;
      }

      textarea {
        padding: 5px;
        width: 100%;
        height: 7rem;
        color: white;
        background: rgb(32, 32, 30);

        box-sizing: border-box;
      }

      .resume {
        cursor: pointer;
      }
    }

    .box2 {
      display: flex;
    }
  }
}

@media (max-width: 990px) {
  .job-main {
    .form {
      height: 100%;
      width: 70%;
    }
  }
}

@media (max-width: 590px) {
  .job-main {
    .form {
      height: 100%;
      width: 80%;
    }
  }
}
