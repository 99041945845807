.vision-main {
  height: 100%;
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  .vision-heading {
    width: 70%;
    height: 100%;
    margin: auto;
    text-align: center;
    margin-bottom: 2rem;
    h1 {
      text-align: center;
      font-size: 2.8rem;
      font-weight: 400;
      width: fit-content;
      background: radial-gradient(
        104.21% 376.56% at 50% 50%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background-clip: text;
      color: transparent;
      margin: auto;
      margin-bottom: 1rem;
    }
    .vision-heading-para {
      color: #f6f6f6;
      font-size: 0.875rem;
      font-weight: 400;
    }
  }
  .vision-content {
    position: relative;
    overflow: hidden;
    width: 44rem;
    height: 65rem;
    margin: auto;
    border: 1px solid rgba(255, 255, 255, 0.2);
    text-align: center;
    border-radius: 42px;
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(94px);
    background: #f6f6f61a;
    flex-wrap: wrap;

    .inner-vision-content {
      width: 35rem;
      height: 62rem;
      display: flex;
      flex-direction: column;
      margin: auto;
      gap: 0.625rem;
      margin-top: 4rem;
      .vision-steps {
        width: 35rem;
        height: 8rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .steps-text {
          justify-content: flex-start;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          //gap: 0.625rem;
          padding: 1.25rem 0;
          .steps-heading {
            font-size: 1.375rem;
            font-weight: 400;
          }
          .steps-content {
            text-align: start;
            font-size: 0.875rem;
            font-weight: 400;
            color: #f6f6f6d0;
          }
        }
      }
    }
    .step-number-main {
      .step-number {
        padding: 0 0.625rem;
        font-size: 6.563rem;
        font-style: italic;
        font-weight: 700;
        background: radial-gradient(
          138.33% 1008.32% at 27.5% 42.15%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        background-clip: text;
        color: transparent;
      }
    }
    .top-background-center {
      position: absolute;
      width: 27.25rem;
      height: 27.25rem;
      top: -12.188rem;
      left: -12.438rem;
      z-index: -1;
      border-radius: 50%;
      background-color: #ea4335;
      filter: blur(5.25rem);
    }
    .top-background-right {
      position: absolute;
      width: 11.875rem;
      height: 11.875rem;
      top: -6.5rem;
      left: 7.875rem;
      border-radius: 50%;
      z-index: -1;
      background-color: #ff8b26;
      filter: blur(4rem);
    }
    .top-background-left {
      width: 8.875rem;
      height: 8.875rem;
      top: 7.813rem;
      left: -7.375rem;
      background-color: #ea35a8;
      position: absolute;
      border-radius: 50%;
      filter: blur(4rem);
      z-index: -1;
    }
    .bottom-background-center {
      width: 23.375rem;
      height: 23.375rem;
      top: 58.063rem;
      left: 33.875rem;
      position: absolute;
      border-radius: 50%;
      filter: blur(7.5rem);
      z-index: -1;
      background: #26ff4e;
    }
    .bottom-background-left {
      position: absolute;
      border-radius: 50%;
      filter: blur(5.25rem);
      z-index: -1;
      background: #7cadff;
      width: 11.25rem;
      height: 11.25rem;
      top: 52.063rem;
      left: 39.938rem;
    }
    .bottom-background-right {
      position: absolute;
      border-radius: 50%;
      filter: blur(5.25rem);
      z-index: -1;
      background: #4285f4;
      width: 11.625rem;
      height: 11.625rem;
      top: 65.938rem;
      left: 25.813rem;
    }
  }
  .comment {
    text-align: center;
    font-size: 2.8rem;
    font-weight: 400;
    width: fit-content;
    background: radial-gradient(
      104.21% 376.56% at 50% 50%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background-clip: text;
    color: transparent;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.6),
      0 0 20px rgba(255, 255, 255, 0.4), 0 0 30px rgba(255, 255, 255, 0.2);
    margin: auto;
    margin-bottom: 1rem;
    margin-top: 2rem;
  }
}

@media (max-width: 1024px) {
  .vision-main {
    .vision-heading {
      h1 {
        font-size: 2.1rem;
        margin-bottom: 10px;
      }
      .vision-heading-para {
        color: #f6f6f6;
        font-size: 0.875rem;
        font-weight: 400;
        br {
          display: none;
        }
      }
    }
  }
}

@media (max-width: 800px) {
  .vision-main {
    .vision-content {
      width: 70%;

      .inner-vision-content {
        width: 80%;

        .vision-heading h1 {
          font-size: 2.2rem;
        }
        .vision-heading .vision-heading-para {
          font-size: 0.75rem;
        }

        .step-number-main .step-number {
          font-size: 4rem;
        }
        .vision-steps {
          width: 100%;
        }
        .vision-steps .steps-text {
          .steps-heading {
            font-size: 1rem;
          }
          .steps-content {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
}

@media (max-width: 680px) {
  .vision-main {
    .vision-content {
      .inner-vision-content {
        width: 90%;
        .vision-steps {
          .steps-text {
            // display: flex;
            // flex-direction: column;
            text-align: start;
            .steps-heading {
              font-size: 1.2rem;
            }
          }
          .step-number-main {
            align-items: center;
            display: flex;
            justify-content: center;
            padding: 0;
            .step-number {
              font-size: 3rem;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .vision-main {
    .vision-heading {
      h1 {
        font-size: 1.7rem;
        margin-bottom: 10px;
        line-height: 1.7rem;
      }
      .vision-heading-para {
        font-size: 14px;
        line-height: 18px;
        br {
          display: none;
        }
      }
    }
    .vision-content {
      width: 90%;

      .inner-vision-content {
        margin: auto;
        .vision-steps {
          display: flex;
          justify-content: space-between;
          gap: 10px;
          .steps-text {
            text-align: start !important;
            padding: 0;

            .steps-heading {
              margin: 0;
            }
            .steps-content {
              margin-top: 5px;
              br {
                display: none;
              }
            }
          }
          .step-number-main {

            display: flex;
            align-items: start;
            margin-top: 0px;

            margin: 0;
            padding: 0;
            .step-number {
              font-size: 3rem;
              padding: 0;
              margin: 0;
              margin-top: -15px;
              padding: 0 5px;
            }
          }
          .step-number-main:nth-child(1) {
            //margin-left: -9px;
          }
        }
      }
    }
    .comment {
      font-size: 1.5rem;
      margin-bottom: 10px;
      margin-top: 20px;
      span{
        font-size: 1.2rem;
        margin-left: 5px;
      }
    }
  }
}
