.ReletedWork {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .releted-heading {
    font-family: "Ubuntu";
    font-size: 59px;
    font-weight: 400;
    line-height: 51.71px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: radial-gradient(
      157.47% 1012.75% at 6.55% 17.65%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: fit-content;
  }
  .releted-paragraph {
    margin-top: 10px;
    // font-family: "Maven Pro";
    font-size: 18px;
    font-weight: 400;
    line-height: 21.15px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background-color: #ffffff;
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: fit-content;
    padding: 1rem 0;
    padding-bottom: 2.5rem;
  }

  .releted-cards {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1rem;
    .releted-card {
      margin-top: 20px;
      width: 49%;
      height: 22rem;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border-radius: 26px;
      transition: all 0.1s ease;

      &:hover {
        .releted-details-overlay {
          backdrop-filter: blur(24px);
          opacity: 1;
          height: 100%;
          width: 100%;
        }
      }
      .releted-details-overlay {
        opacity: 0;
        width: 0%;
        height: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 3rem;
        transform: translate(-50%, -50%);
        transition: all 0.9s ease;
        // transform: scale(1);
        // transform: skew(10);

        .releted-card-heading {
          font-size: 36px;
          font-weight: 400;
          line-height: 35px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          background: radial-gradient(
            87.64% 653.71% at 4.4% 28.57%,
            #ffffff 0%,
            rgba(255, 255, 255, 0.4) 100%
          );
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          width: fit-content;
        }
        .releted-card-details {
          margin-top: 20px;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          background-color: #ffffff;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          width: fit-content;
          br {
            display: none !important;
          }
        }
        .releted-desig {
          display: flex;
          flex-wrap: wrap;
          gap: 20px;
          row-gap: 10px;
          .kkk {
            margin: 0;
            padding: 4px 19px;
            background: radial-gradient(
              78.17% 203.96% at 6.34% 25.68%,
              rgba(255, 255, 255, 0.3) 0%,
              rgba(255, 255, 255, 0.1) 100%
            );
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            text-align: center;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            border-radius: 8px;
            border: 1px solid #ffffff33;
            background: radial-gradient(
                78.17% 203.96% at 6.34% 25.68%,
                rgba(255, 255, 255, 0.3) 0%,
                rgba(255, 255, 255, 0.1) 100%
              )
              /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          }
        }
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: fit-content;
      }
    }
  }
}

@media (max-width: 1024px) {
  .ReletedWork {
    .releted-heading {
      font-size: 2.1rem;
      line-height: 51.71px;
      text-align: center;
    }
    .releted-paragraph {
      margin-top: 10px;
      font-size: 16px;
      font-weight: 400;
      line-height: 21.15px;
    }
    .releted-cards {
      .releted-card {
        margin-top: 20px;
        width: 49%;
        height: 20rem;

        .releted-details-overlay {
          width: 80%;

          gap: 20px;
          /* Add transition for transform */
          .releted-card-heading {
            font-size: 1.5rem;
            font-weight: 400;
            line-height: 25px;
          }
          .releted-card-details {
            margin-top: 0;
            font-size: 14px;
            font-weight: 400;
            line-height: 16px;
          }
          .releted-desig {
            display: flex;
            flex-wrap: wrap;
            gap: 10px;
            .kkk {
              padding: 2px 10px;

              /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */
            }
          }
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: fit-content;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .ReletedWork {
    .releted-heading {
      font-size: 1.7rem;
      line-height: 19.71px;
    }
    .releted-paragraph {
      font-size: 16px;
    }
    .releted-cards {
      .releted-card {
        margin-top: 20px;
        width: 49%;
        height: 100%;

        .releted-details-overlay {
          width: 90%;
          gap: 0;
          justify-content: space-evenly;
          /* Add transition for transform */
          .releted-card-heading {
            font-size: 1rem;

            line-height: 15px;
          }
          .releted-card-details {
            font-size: 12px;
            line-height: 13px;
          }
          .releted-desig {
            gap: 5px;
            .kkk {
              border-radius: 3px;
              font-size: 12px;
              padding: 0px 5px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 614px) {
  .ReletedWork {
    .releted-heading {
      font-size: 1.7rem;
      line-height: 30.71px;
    }
    .releted-paragraph {
      font-size: 16px;
    }
    .releted-cards {
      .releted-card {
        margin-top: 20px;
        width: 100%;
        height: 100%;

        .releted-details-overlay {
          padding: 1.5rem;
          width: 100%;
          gap: 0;
          justify-content: space-evenly;
          .releted-card-heading {
            font-size: 1.3rem;
            line-height: 15px;
          }
          .releted-card-details {
            font-size: 12px;
            line-height: 13px;
            max-width: 370px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            text-overflow: ellipsis;
            word-wrap: break-word;
          }
          .releted-desig {
            gap: 5px;
            .kkk {
              border-radius: 3px;
              font-size: 12px;
              padding: 0px 5px;
            }
          }
        }
      }
    }
  }
}
