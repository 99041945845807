.btn-button {
  background: transparent;
  border: none;
  padding: 1rem;
  font-size: 22px;
}
.hd {
  font-size: 2.75rem;
  font-weight: 400;
  font-family: "ubuntu";
  width: fit-content;
  background: radial-gradient(
    157.47% 1012.75% at 6.55% 17.65%,
    #ffffff 0%,
    rgba(255, 255, 255, 0) 100%
  );
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  margin: auto;
}

.overlay-singleblog {
  border: 1px solid #ddd;
  margin-top: 2rem;
  border-radius: 10px;
  padding: 20px;
  .single-blog {
    display: flex;
    justify-content: space-between;
    align-items: start;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;

    .image-container {
      .fristimg {
        width: 450px;
        height: auto;
        float: left;
        padding: 1rem;
        padding-right: 2rem;
        padding-left: 0;
        border-radius: 10px;
        object-fit: cover;
        @media (max-width: 700px) {
          float: none;
          width: 100%;
          margin: auto;

          object-fit: contain;
          padding-bottom: 2rem;
        }
      }
      .Secondimg {
        float: right;
        padding: 1rem;
        padding-right: 0;
        @media (max-width: 700px) {
          float: none;
          width: 100%;
          margin: auto;
          object-fit: contain;
          padding-bottom: 2rem;
        }
      }
      .b-head {
        font-size: 24px;
        margin-bottom: 10px;
        // font-family: "ubuntu";
        width: fit-content;
        background: radial-gradient(
          157.47% 1012.75% at 6.55% 17.65%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        margin: auto;
        text-align: center;

        margin-bottom: 1rem;
      }

      .blog-para {
        font-family: "ubuntu";
        font-size: 18px;
        color: #ffffffbd;
        text-align: justify;
      }
    }
  }
}
/* Responsive Styles */
@media (max-width: 768px) {
  .hd {
    font-size: 1.7rem;
  }
  .overlay-singleblog {
    margin: 0 1.5rem;
    padding: 0;
    border: none;
  }
}

@media (max-width: 480px) {
  .details-container {
    h2 {
      font-size: 18px;
    }

    p {
      font-size: 12px;
    }
  }
}
