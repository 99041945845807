.OurPortPolio {
  h1 {
    font-size: 3.68rem;
    text-align: center;
    color: transparent;
    width: fit-content;
    font-weight: 400;
    background: var(--gradient-text);
    -webkit-background-clip: text;
    background-clip: text;
    margin: auto;
  }
  p {
    margin-top: 1.5rem;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 1rem;
  }

  .portHeader ul {
    width: 83%;
    margin: auto;
    margin-top: 3rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    // li {
    //   cursor: pointer;
    //   padding-bottom: 10px;
    //   list-style: none;
    //   text-align: center;
    //   width: 10%;
    //   @media (max-width: 1300px) {
    //     width: fit-content;
    //   }
    // }

    li {
      cursor: pointer;
      padding-bottom: 10px;
      list-style: none;
      text-align: center;
      width: 10%;
      position: relative; /* Important for positioning the pseudo-element */
      background: linear-gradient(90deg, transparent, transparent);
      -webkit-background-clip: text;

      transition: color 0.5s ease-in-out;
      overflow: hidden;
    }

    li::before {
      content: "";
      position: absolute;
      left: -100%;
      bottom: 0;
      width: 100%;
      height: 2px;
      background-color: #4285f4; /* Same color as the border */
      transition: left 0.5s ease-in-out;
    }

    li:hover {
      color: transparent;
      background: linear-gradient(90deg, #4285f4, #4285f4);
      -webkit-background-clip: text;
    }

    li:hover::before {
      left: 0;
    }

    li:hover {
      text-shadow: 0 0 10px rgba(66, 133, 244, 0.6);
    }

    @keyframes blur-text {
      0% {
        color: #000;
        filter: blur(0px);
      }
      50% {
        color: transparent;
        filter: blur(5px);
      }
      100% {
        color: transparent;
        filter: blur(0px);
      }
    }

    @media (max-width: 1300px) {
      li {
        width: fit-content;
      }
    }
  }

  .portHeader ul li.active {
    font-weight: bold;
    // border-bottom: 2px solid transparent;
    color: #4285f4;
    position: relative;
    text-shadow: 0 0 10px #3d7ab9;
  }
  .portHeader ul li.active::after {
    content: "";
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 2px; /* Same height as the bottom border */
    background-color: #3d7ab9; /* Same color as the border */
    box-shadow: 0 0 10px #3d7ab9; /* Apply the shadow here */
    text-shadow: 0 0 10px #3d7ab9;
  }

  .portfolio {
    width: 85%;
    margin: auto;
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    flex-wrap: wrap;

    .portfolio-item {
      cursor: pointer;
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      position: relative;
      overflow: hidden;
      border-radius: 20px;

      &:hover {
        .client-details {
          background: rgba(0, 0, 0, 0.452);
          top: 0;
          opacity: 1;
        }
      }
      .image-overlay {
        height: 100%;
        width: 100%;
        .portfolio-image {
          height: 100%;
          width: 100%;
          object-fit: fit-content;
        }
      }
      .client-details {
        height: 100%;
        width: 100%;
        padding: 0 3rem;
        position: absolute;
        top: 100%;
        backdrop-filter: blur(9px);
        text-align: start;
        transition: all 0.5s linear;
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (max-width: 1100px) {
          justify-content: start;
          padding-top: 2rem;
        }
        .client-title {
          font-family: "Poppins", sans-serif;
          font-size: 2.1rem;
          font-weight: 400;
          line-height: 35px;
          background: radial-gradient(
            87.64% 653.71% at 4.4% 28.57%,
            #ffffff 0%,
            rgba(255, 255, 255, 0.4) 100%
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
        .client-para {
          margin-top: 5px;
          font-family: "Poppins", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 21px;
          text-align: start;
          background: white;
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* Maximum lines to show */
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .buttons {
          position: absolute;
          bottom: 5%;
          width: 80%;

          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          gap: 10px;
          padding: 0;
          @media (max-width: 1100px) {
            justify-content: start;
          }
          .batan {
            margin-top: -20px;
            font-family: "Poppins", sans-serif;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
            background: radial-gradient(
                78.17% 203.96% at 6.34% 25.68%,
                rgba(255, 255, 255, 0.3) 0%,
                rgba(255, 255, 255, 0.1) 100%
              )
              /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;

            border: 1px solid #ffffff33;
            border-radius: 8px;
            padding: 4px 16px;
          }
        }
      }
    }
    .smallImg {
      width: 49%;
      height: 100%;
      @media (max-width: 600px) {
        width: 48%;
      }
    }
  }

  .button {
    margin: 5rem 0;

    button {
      border: 1px solid rgba(128, 128, 128, 0.486);
      z-index: 99;
      background: radial-gradient(
          110.37% 306.48% at -30.59% -224.07%,
          rgba(255, 255, 255, 0.89) 0%,
          rgba(255, 255, 255, 0) 100%
        ),
        linear-gradient(0deg, #131313, #131313);

      border-radius: 1rem;
      padding: 1.2rem 3rem;
      font-size: 1.1rem;
      color: white;
      font-family: "Maven Pro", sans-serif;
      display: flex;
      justify-content: center;
      align-content: center;
      margin: auto;
      .arrow {
        margin-left: 10px;

        margin-top: 3px;
      }
    }
  }
}

@media (max-width: 1024px) {
  .OurPortPolio {
    h1 {
      font-size: 2.1rem;
    }

    p {
      margin-top: 1rem;
      line-height: 1rem;
    }

    .portHeader ul {
      margin: 1.7rem auto;
      font-size: 14px;
      li {
        cursor: pointer;
        padding-bottom: 10px;
        list-style: none;
      }
    }
    .portfolio {
      width: 85%;

      margin-top: 1.5rem;

      .portfolio-item {
        &:hover {
          .client-details {
            top: 0;
            opacity: 1;
          }
        }

        .client-details {
          padding: 0 2rem;

          padding-top: 3rem;

          .client-title {
            font-size: 1.5rem;

            line-height: 25px;
          }
          .client-para {
            margin-top: 5px;

            font-size: 12px;

            line-height: 13px;
          }
          .buttons {
            bottom: 5%;
            width: 80%;

            gap: 10px;
            padding: 0;

            .batan {
              margin-top: -20px;

              line-height: 13px;
              font-size: 12px;
              border-radius: 5px;
              padding: 4px 10px;
            }
          }
        }
      }
      .smallImg {
        width: 49%;
        height: 100%;
        @media (max-width: 600px) {
          width: 48%;
        }
      }
    }
  }
}
@media (max-width: 800px) {
  .OurPortPolio {
    h1 {
      font-size: 2.1rem;
    }

    p {
      margin-top: 1rem;
      line-height: 1rem;
    }

    .portHeader ul {
      margin: 1.7rem auto;
      font-size: 14px;
      li {
        cursor: pointer;
        padding-bottom: 10px;
        list-style: none;
      }
    }
    //
    .portfolio {
      width: 85%;

      margin-top: 1.5rem;

      .portfolio-item {
        &:hover {
          .client-details {
            top: 0;
            opacity: 1;
          }
        }

        .client-details {
          padding: 1.5rem;

          .client-title {
            font-size: 1.5rem;

            line-height: 25px;
          }
          .client-para {
            margin-top: 5px;

            font-size: 12px;

            line-height: 13px;
          }
          .buttons {
            bottom: 5%;
            width: 80%;

            gap: 10px;
            padding: 0;

            .batan {
              margin-top: -20px;

              line-height: 13px;
              font-size: 12px;
              border-radius: 5px;
              padding: 4px 10px;
            }
          }
        }
      }
      .smallImg {
        width: 49%;
        height: 100%;
        @media (max-width: 600px) {
          width: 48%;
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .OurPortPolio {
    //
    .portfolio {
      width: 85%;
      .portfolio-item {
        .image-overlay {
          height: 250px;
          width: 100%;
          .portfolio-image {
            height: 100%;
            width: 100%;
            object-fit: fit-content;
          }
        }

        .client-details {
          padding: 1.5rem;

          .client-title {
            font-size: 1.5rem;

            line-height: 25px;
          }
          .client-para {
            margin-top: 5px;

            font-size: 12px;

            line-height: 13px;
          }
          .buttons {
            bottom: 5%;
            width: 80%;

            gap: 10px;
            padding: 0;

            .batan {
              margin-top: -20px;

              line-height: 13px;
              font-size: 12px;
              border-radius: 5px;
              padding: 4px 10px;
            }
          }
        }
      }
      .smallImg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@media (max-width: 530px) {
  .OurPortPolio {
    padding: 0 1.5rem;
    h1 {
      font-size: 1.7rem;
    }

    p {
      margin-top: 1rem;
      line-height: 1rem;
      font-size: 14px;
      width: 100%;
      margin: 10px auto;
      br {
        display: none;
      }
    }

    .portHeader ul {
      flex-wrap: wrap;
      gap: 15px;
      padding: 0;
      width: 100%;
      margin: auto;
      margin-top: 15px;
      font-size: 14px;
      li {
        cursor: pointer;
        padding-bottom: 10px;
        list-style: none;
      }
    }

    .portfolio {
      width: 100%;
      .portfolio-item {
        .image-overlay {
          height: 250px;
          width: 100%;
          .portfolio-image {
            height: 100%;
            width: 100%;
            object-fit: fit-content;
          }
        }

        .client-details {
          padding: 1.5rem;

          .client-title {
            font-size: 1.5rem;

            line-height: 25px;
          }
          .client-para {
            margin-top: 5px;

            font-size: 12px;

            line-height: 13px;
          }
          .buttons {
            bottom: 10%;
            width: 90%;
            gap: 10px;

            flex-wrap: wrap;
            display: flex;
            align-items: start;
            justify-content: start !important;
            text-align: start;
            .batan {
              width: fit-content;
              margin: 0;
              margin-top: -5px;
              line-height: 13px;
              font-size: 12px;
              border-radius: 5px;
              padding: 4px 10px;
            }
          }
        }
      }
      .smallImg {
        width: 100%;
        height: 100%;
      }
    }
    .button {
      margin: 3rem 0;
      button {
        padding: 10px 1.2rem;
        font-size: 14px;
      }
    }
  }
}
