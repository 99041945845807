.BrandPage {
  scrollbar-width: none;
  -ms-overflow-style: none;
  padding-bottom: 3rem;
  scroll-behavior: smooth;
  padding-top: 2rem;
  // backdrop-filter: blur(94px);

  
  .brandNavHeader {
    width: 95%;
    position: absolute;
    top: 37px;
    left: 50.5%;
    transform: translate(-50%, -50%);
  }
  .BrandHomeInner {
    padding: 4rem;
  }
  .brand-second-section,
  .brand-third-section,
  .brand-fourth-section,
  .brand-fifth-section {
    height: 100%;
    width: 100%;
    padding: 6rem 4rem;
  }
  .brand-second-section {
    margin-top: 5rem;
  }
  .brand-fourth-section {
    margin: 9rem 0;
  }
  .brand-second-section {
    padding: 0;
  }

  .brand-fifth-section {
    padding: 0 4rem;
    .afterHeader {
      width: 100%;
      margin: auto;

      .firstPart {
        width: 100%;
        display: flex;
        justify-content: space-between;

        .headingOfContact {
          width: 40%;
          h3 {
            // padding-top: 2.8rem;
            font-weight: 400;
            font-size: 45px;
            line-height: 51.71px;
            text-align: left;
            background: radial-gradient(
              87.64% 653.71% at 4.4% 28.57%,
              #ffffff 0%,
              rgba(255, 255, 255, 0.4) 100%
            );
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            width: fit-content;

            font-weight: 400;
            .contact-heading-mid {
              background: linear-gradient(90deg, #4285f4 0%, #9cc2ff 100%);

              background-clip: text;
              color: transparent;
              font-size: 45px;
              font-weight: 400;
              line-height: 51.71px;
            }
          }
        }
        .contactForm {
          width: 60%;
        }
      }

      .secondPart {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        margin-top: 8rem;
        .contactdetail {
          display: flex;
          justify-content: space-between;
          gap: 20px;
          width: 40%;
          height: 100%;
          margin-top: 4rem;
          margin-left: 1rem;

          .leftdiv {
            //flex: 1; // Each side of the contact details (left and right) takes equal space
            display: flex;
            flex-direction: column;
            gap: 20px; // Space between address and phone numbers

            h3 {
              margin-bottom: 0.7rem;
              font-size: 20px;
              color: #f6f6f6;
            }

            p {
              margin: 0;
              font-size: 16px;
              color: #f6f6f6;
              line-height: 1.5;
            }

            .phoneNumbers {
              display: flex;
              flex-direction: column;
              gap: 5px;

              h4 {
                margin: 0;
                font-size: 24px;
                color: #f6f6f6;
              }
            }
          }

          .rightdiv {
            display: flex;
            flex-direction: column;
            gap: 40px;

            .emailSection {
              display: flex;
              flex-direction: column;
              gap: 40px;

              .generalEmail,
              .workEmail {
                h3 {
                  margin-bottom: 5px;
                  font-size: 18px;
                  color: rgba(246, 246, 246, 0.65);
                }

                p {
                  margin: 0;
                  font-size: 22px;
                  color: #f6f6f6;
                }
              }
            }

            .socialSection {
              h3 {
                margin-bottom: 10px;
                font-size: 18px;
                color: rgba(246, 246, 246, 0.65);
              }

              .socialLinks {
                display: flex;
                gap: 18px;
                font-size: 18px;
                color: #f6f6f6;
                a .social-media-link {
                  height: 20px;
                  width: 20px;
                }
              }
            }
          }
        }

        .map-main {
          width: 42%;
          height: 23rem;
          margin-right: 5.1rem;
          background: linear-gradient(
            0deg,
            rgba(246, 246, 246, 0.1),
            rgba(246, 246, 246, 0.1)
          );
          border: 1px solid #f6f6f633;
          border-radius: 16px;
          position: relative;
          .mapgreenbg {
            width: 238.77px;
            height: 238.77px;
            background: linear-gradient(90deg, #34a853 0%, #ff7444 100%);
            filter: blur(107px);
            position: absolute;
            border-radius: 50%;
            left: 28%;
            top: -15%;
            z-index: -1;
          }
          .mapYellobg {
            width: 238.77px;
            height: 238.77px;
            background: linear-gradient(90deg, #f7e72f 0%, #ff7444 100%);
            filter: blur(97px);

            position: absolute;
            border-radius: 50%;
            top: -15%;
            right: -9%;
            z-index: -1;
          }
          .mapdetails {
            width: 95%;
            height: 93%;
            margin: auto;
            margin-top: 0.8rem;
            border: 2px solid white;
            border-radius: 5px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .BrandPage {
    padding-top: 0;
    .brand-fifth-section {
      .afterHeader {
        width: 100%;
        margin: auto;

        .firstPart {
          width: 100%;
          .headingOfContact {
            width: 50%;
            h3 {
              // padding-top: 2.1rem;
              font-weight: 400;
              font-size: 2.1rem;
              line-height: 31.71px;

              .contact-heading-mid {
                background: linear-gradient(90deg, #4285f4 0%, #9cc2ff 100%);

                background-clip: text;
                color: transparent;
                font-size: 2.1rem;
                font-weight: 400;
                line-height: 50.71px;
              }
            }
          }
          .ms-contactForm {
            width: 50%;
          }
        }
      }
    }
  }
}
@media (max-width: 768px) {
  .BrandPage {
    .BrandHomeInner {
      padding-top: 0;
    }
    .brand-fifth-section {
      .afterHeader {
        width: 100%;
        margin: auto;

        .firstPart {
          width: 100%;
          flex-direction: column;
          gap: 20px;
          .headingOfContact {
            width: 100%;
            h3 {
              // padding-top: 1.5rem;
              font-weight: 400;
              font-size: 1.5rem;
              line-height: 31.71px;
              br {
                display: none;
              }

              .contact-heading-mid {
                font-size: 1.5rem;
                font-weight: 400;
                line-height: 20.71px;
              }
            }
          }
          .contactForm {
            width: 100%;
          }
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .BrandPage {
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none;
    padding-bottom: 3rem;
    .brandNavHeader {
      width: 95%;
      position: absolute;
      top: 37px;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    .BrandHomeInner,
    .brand-second-section,
    .brand-third-section,
    .brand-fourth-section,
    .brand-fifth-section {
      height: 100%;
      width: 100%;
      padding: 1.6rem;
    }
    .brand-second-section {
      padding: 0;
    }
  }
}
