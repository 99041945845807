.JoinForm {
  width: 100%;
  padding: 2rem 3rem;
  margin: auto;
  border-radius: 24px;
  // background: #d9d9d9;
  backdrop-filter: blur(47px);
  position: relative;
  border: 1px solid #d9d9d92d;

  z-index: 2;
  overflow: hidden;
  .white-bg-color {
    width: 178px;
    height: 178px;
    background: #f6f6f6ce;
    filter: blur(76px);
    position: absolute;
    border-radius: 50%;
    top: -20%;
    left: -15%;
    z-index: -1 !important;
  }
  .innerSection {
    display: flex;
    flex-direction: column;
    gap: 35px;
    .contact-heading-text {
      background: radial-gradient(
        104.21% 376.56% at 50% 50%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );

      background-clip: text;
      color: transparent;
      font-size: 36px;
      font-weight: 400;
      line-height: 41.36px;
      text-align: left;
    }
    .line {
      display: flex;
      gap: 16px;

      input {
        color: rgba(246, 246, 246, 0.5);
        font-size: 17px;
        font-weight: 300;
        flex: 1;
        border: none;
        border-bottom: 0.12rem solid  rgba(246, 246, 246, 0.5);
        padding: 1px 4px;
        outline: none;
        background: transparent;
        width: 100%;
        color: white; 
        font-family: "ubuntu", sans-serif !important;
        // background: #f6f6f680;
        // background-clip: text;
        // -webkit-background-clip: text;
        // -webkit-text-fill-color: transparent;
      }

      input::placeholder {
        font-size: 22px;
        font-weight: 300;
        line-height: 23.28px;
        background: #f6f6f680;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-family: "Ubuntu", sans-serif !important;
      }

      input:focus {
        border-bottom: 1px solid #007bff;
      }
    }
    .subscription label {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      font-size: 14px;

      .checkbx {
        width: 20px;
        height: 20px;
        border: 2px solid rgb(255, 255, 255);
        border-radius: 50%;
        appearance: none;
        cursor: pointer;
        position: relative;
        outline: none;
      }

      .checkbx:checked {
        // background-color: #4caf50; /* Background color on check */
        border-color: #ffffff; /* Border color on check */
      }

      .checkbx:checked::after {
        content: "✓";
        color: white;
        font-size: 10px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      p {
        margin: 0;
        font-size: 18px;
        font-weight: 100;
        line-height: 20px;
        text-align: left;
        display: inline-block;
        background: #f6f6f6;
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    .CheckBuutton {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      button {
        font-family: "Maven Pro", sans-serif;
        font-size: 16px;
        font-weight: 700;
        line-height: 18.8px;
        text-align: center;
        padding: 15px 17px;
        width: 80%;
        color: white;

        border: none;
        border-radius: 15px;
        cursor: pointer;
        background: radial-gradient(
          256.66% 747.37% at 0% 0%,
          #ffffff 0%,
          #4285f4 16.41%,
          rgba(66, 133, 244, 0) 100%
        );
        // border: 1px solid rgba(255, 255, 255, 0.151);

        // border-image-source: linear-gradient(
        //     0deg,
        //     rgba(255, 255, 255, 0.2),
        //     rgba(255, 255, 255, 0.2)
        //   ),
        //   linear-gradient(
        //     108.65deg,
        //     #ffffff -26.25%,
        //     rgba(255, 255, 255, 0) 31.16%
        //   );
        //   border-image-slice: 1;
      }

      button:hover {
        background-color: #0056b3;
      }
    }
  }
}

@media (max-width: 1024px) {
  .JoinForm {
    padding-left: 1.5rem;

    h1 {
      font-weight: 400;
      font-size: 2.1rem;
      line-height: 50px;

      input {
        font-size: 2.1rem;
      }
      button {
        margin-left: 1rem;
        font-size: 14px;
        color: #f6f6f6;
        padding: 11px 25px;
      }
    }
    .name {
      padding-left: 3.1rem;
    }
    .email {
      padding-left: 6rem;
    }
    // --------
    .innerSection {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .contact-heading-text {
        font-size: 30px;

        line-height: 41.36px;
      }
      .line {
        gap: 16px;

        input {
          font-size: 22px;

          border-bottom: 0.12rem solid #f6f6f665;
          padding: 1px 4px;
        }

        input::placeholder {
          font-size: 20px;
          font-weight: 300;
          line-height: 23.28px;
        }
      }
      .subscription label {
        .checkbx {
          width: 18px;
          height: 18px;
        }

        .checkbx:checked::after {
          content: "✓";
          color: white;
          font-size: 10px;
        }
        p {
          font-size: 16px;
        }
      }

      .CheckBuutton {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          font-size: 16px;

          line-height: 16.8px;

          padding: 8px 16px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .JoinForm {
    h1 {
      font-size: 1.5rem;
      line-height: 41px;

      input {
        font-size: 1.5rem;
      }
      button {
        margin-left: 1rem;
        font-size: 14px;
        color: #f6f6f6;
        padding: 7px 15px;
        .icon {
          padding-top: 4px;
        }
      }
    }
    .name {
      padding-left: 2.5rem;
    }
    .email {
      padding-left: 5rem;
    }
  }
}
@media (max-width: 450px) {
  .JoinForm {
    padding: 2rem 1rem;

    h1 {
      font-weight: 400;
      font-size: 2.1rem;
      line-height: 50px;

      input {
        font-size: 2.1rem;
      }
      button {
        margin-left: 1rem;
        font-size: 14px;
        color: #f6f6f6;
        padding: 11px 25px;
      }
    }
    .name {
      padding-left: 3.1rem;
    }
    .email {
      padding-left: 6rem;
    }
    // --------
    .innerSection {
      display: flex;
      flex-direction: column;
      gap: 24px;
      padding: 0;
      width: 100%;
      .contact-heading-text {
        font-size: 20px;
        line-height: 31.36px;
      }
      .line {
        gap: 26px;
        flex-direction: column;
        input {
          font-size: 1rem;
          border-bottom: 0.12rem solid #f6f6f665;
          padding: 0;
        }

        input::placeholder {
          font-size: 1rem;
          font-weight: 300;
          line-height: 23.28px;
        }
      }
      .subscription label {
        display: flex;
        align-items: start;
        .checkbx {
          width: 18px;
          height: 18px;
        }

        .checkbx:checked::after {
          content: "✓";
          color: white;
          font-size: 10px;
        }
        p {
          font-size: 16px;
        }
      }

      .CheckBuutton {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;

        button {
          font-size: 16px;

          line-height: 16.8px;

          padding: 8px 16px;
        }
      }
    }
  }
}
