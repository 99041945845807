.AboutStrategy {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  .strategy-auther {
    font-family: "Ubuntu";
    font-size: 45px;
    font-weight: 400;
    line-height: 51.71px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
    background: radial-gradient(
      157.47% 1012.75% at 6.55% 17.65%,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 100%
    );
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    width: fit-content;
    span {
      background: radial-gradient(
        351.67% 1064.88% at -39.28% -40.2%,
        #ffffff 0%,
        #4285f4 16.41%,
        rgba(66, 133, 244, 0) 100%
      );

      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .stragy-card-container {
    margin-top: 5rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    row-gap: 5rem;
    .strategy-card {
      height: 100vh;
      width: 46%;
      &:hover {
        transform: scale(1.01);
        transition: transform 0.3s ease-in-out;
        border-radius: 25px;
        padding: 5px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
      }
      .card-img {
        height: 45%;
        width: 100%;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .strategy-overlay {
        margin-top: 2rem;

        .number {
          font-family: "Maven Pro";
          font-size: 22px;
          font-weight: 400;
          line-height: 25.85px;
          text-align: left;

          background: #f6f6f6;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          width: fit-content;
          margin: 0;
        }
        .strategy-heading {
          font-family: "Ubuntu";
          font-size: 2.1rem;
          font-weight: 400;
          line-height: 41.36px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          background: radial-gradient(
              166.44% 541.54% at 4.98% 17.16%,
              #ffffff 0%,
              rgba(255, 255, 255, 0) 100%
            )
            /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          width: fit-content;
        }
        .strategy-details {
          padding: 2rem 0;
          font-family: "Maven Pro";
          font-size: 16px;
          font-weight: 400;
          line-height: 18.8px;
          text-align: left;
          text-underline-position: from-font;
          text-decoration-skip-ink: none;
          background: #f6f6f6;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          width: fit-content;
          br {
            display: none !important;
          }
        }
        ul {
          padding: 0;
          li {
            color: inherit;
            font-family: "Maven Pro";
            font-size: 16px;
            font-weight: 400;
            line-height: 18.8px;
            text-align: left;
            text-underline-position: from-font;
            text-decoration-skip-ink: none;
            background: #f6f6f6;
            -webkit-background-clip: text;
            background-clip: text;
            color: transparent;
            width: fit-content;
            margin-top: 15px;
          }
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .AboutStrategy {
    .strategy-auther {
      font-size: 2.1rem;

      line-height: 35.71px;
    }

    .stragy-card-container {
      margin-top: 3rem;

      row-gap: 2rem;
      .strategy-card {
        height: 49rem;
        width: 48%;
        .card-img {
          height: 30%;
          width: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .strategy-overlay {
          margin-top: 2rem;
          .number {
            font-size: 1rem;

            line-height: 17.85px;
          }
          .strategy-heading {
            font-size: 2.1rem;

            line-height: 41.36px;
          }
          .strategy-details {
            padding: 1.5rem 0;
            font-size: 16px;
            line-height: 18.8px;
          }
          ul {
            padding: 0;
            li {
              font-size: 16px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 700px) {
  .AboutStrategy {
    .strategy-auther {
      font-size: 1.6rem;
      line-height: 28.71px;
    }

    .stragy-card-container {
      margin-top: 3rem;
      row-gap: 1rem;
      flex-direction: column;
      .strategy-card {
        height: 100%;

        width: 100%;

        .card-img {
          height: 30%;
          width: 100%;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .strategy-overlay {
          margin-top: 1rem;

          .number {
            font-size: 1rem;

            line-height: 17.85px;
          }
          .strategy-heading {
            font-size: 1.5rem;
            line-height: 27.36px;
          }
          .strategy-details {
            padding: 10px 0;
            font-size: 14px;
            line-height: 18.8px;
          }
          ul {
            padding: 0;
            li {
              font-size: 14px;
              margin-top: 10px;
            }
          }
        }
      }
    }
  }
}
