.Firewall {
  .top-info {
    display: flex;
    width: 94%;
    margin: auto;
    .left-firewall-content {
      width: 50%;
      .f-head {
        font-family: "Ubuntu";
        font-size: 45px;
        font-weight: 400;
        line-height: 51.71px;
        text-align: left;
        text-underline-position: from-font;
        background: radial-gradient(
          157.47% 1012.75% at 6.55% 17.65%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );

        text-decoration-skip-ink: none;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
        span {
          background: linear-gradient(90deg, #34a853 0%, #5de18b 100%);
          text-decoration-skip-ink: none;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
        }
      }
    }
    .right-firewall-content {
      width: 50%;
      .fire-paragraph {
        text-align: end;
        font-family: "Maven Pro";
        font-size: 18px;
        font-weight: 400;
        line-height: 21.15px;
        text-align: right;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }

    overflow: hidden;
  }

  .firewall-section {
    position: relative;
    // overflow: hidden;
    height: 42rem;
    .bgfireimg {
      z-index: 1;
      width: 95%;
      object-fit: fit-content;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translate(-50%);
    }
    .bgfireimg-for-mobile {
      display: none;
    }

    .fires-section {
      width: 446px;
      height: 446px;
      position: absolute;
      top: 10%;
      right: 0;

      .oner-overlay {
        height: 100%;
        width: 100%;
        position: relative;

        .fire1,
        .fire2,
        .fire3,
        .fire4,
        .fire5,
        .fire6,
        .fire7,
        .fire8 {
          border-radius: 10%;
          box-shadow: none;
          text-shadow: none;
          background: #ffffff;
          text-decoration-skip-ink: none;
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          // height: 200%;
          transform: rotate(-40deg);
        }
        .fire1 {
          width: 150%;
          position: absolute;
          top: 35%;
          left: -30%;

          .marquee {
            position: relative;

            img {
              margin-right: -50px;
              transform: rotate(45deg);
              height: 100px;
              width: 100px;
              z-index: 20;
            }
          }
        }
        .fire2 {
          width: 230%;
          position: absolute;
          top: 5%;
          left: -80%;
          //   transform: rotate(0);
          .marquee {
            position: relative;
            img {
              margin-right: -50px;
              transform: rotate(45deg);
              height: 130px;
              width: 130px;
              z-index: 20;
            }
          }
        }
        .fire3 {
          width: 100%;
          position: absolute;
          top: 74%;
          left: 15%;
          //   transform: rotate(0);
          .marquee {
            position: relative;
            img {
              margin-right: -50px;
              transform: rotate(45deg);
              height: 130px;
              width: 130px;
              z-index: 20;
            }
          }
        }
        .fire4 {
          width: 165%;
          position: absolute;
          top: 40%;
          left: -18%;

          //   transform: rotate(0);
          .marquee {
            position: relative;
            img {
              margin-right: -50px;
              transform: rotate(45deg);
              height: 130px;
              width: 130px;
              z-index: 20;
            }
          }
        }

        .fire5 {
          width: 190%;
          position: absolute;
          top: 0%;
          right: 20%;
          .marquee {
            position: relative;
            img {
              margin-right: -50px;
              transform: rotate(45deg);
              height: 130px;
              width: 130px;
              z-index: 20;
            }
          }
        }
        .fire6 {
          width: 165%;
          position: absolute;
          top: -10%;
          right: 160%;

          // transform: rotate(230deg);
          .marquee {
            position: relative;
            img {
              margin-right: -50px;
              transform: rotate(45deg);
              height: 130px;
              width: 130px;
              z-index: 20;
            }
          }
        }
        .fire7 {
          width: 195%;
          position: absolute;
          top: 10%;
          right: 140%;

          // transform: rotate(0);
          .marquee {
            position: relative;
            img {
              margin-right: -50px;
              transform: rotate(45deg);
              height: 130px;
              width: 130px;
              z-index: 20;
            }
          }
        }
        .fire8 {
          width: 275%;
          position: absolute;
          top: 30%;
          right: 80%;

          // transform: rotate(0);
          .marquee {
            position: relative;
            img {
              margin-right: -50px;
              transform: rotate(45deg);
              height: 130px;
              width: 130px;
              z-index: 20;
            }
          }
        }
        .fire9 {
          width: 275%;
          position: absolute;
          top: 45%;
          right: 60%;

          transform: rotate(-40deg);
          .marquee {
            position: relative;
            img {
              margin-right: -50px;
              transform: rotate(45deg);
              height: 130px;
              width: 130px;
              z-index: 20;
            }
          }
        }

        .fire marquee {
          opacity: 0; /* Hide marquee content by default */
          transition: opacity 0.1s linear;
        }
      }
    }

    @keyframes marqueeScroll {
      from {
        transform: translateX(100%);
      }
      to {
        transform: translateX(-100%);
      }
    }

    .hover-circle {
      position: absolute;
      top: 60%;
      left: -50%;
      width: 300px;
      height: 300px;

      border-radius: 50%;
      background-color: #7cadff;
      filter: blur(62.404998779296875px);
      pointer-events: none; /* Allow interactions underneath */
      transform: translate(-50%, -50%);
    }

    .fire-img-info {
      z-index: 2;
      position: absolute;
      bottom: -5%;
      left: 50%;
      transform: translate(-50%, -50%);
      .ignite {
        font-family: "Ubuntu";
        font-size: 36px;
        font-weight: 400;
        line-height: 41.36px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
        background: radial-gradient(
          157.47% 1012.75% at 6.55% 17.65%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        text-decoration-skip-ink: none;
        -webkit-background-clip: text;
        background-clip: text;
        color: transparent;
      }
      .boost {
        margin-top: 20px;
        font-family: "Maven Pro";
        font-size: 18px;
        font-weight: 400;
        line-height: 21.15px;
        text-align: center;
        text-underline-position: from-font;
        text-decoration-skip-ink: none;
      }
    }
  }
}

@media (max-width: 1024px) {
  .Firewall {
    .top-info {
      width: 90%;

      .left-firewall-content {
        width: 50%;
        .f-head {
          font-size: 2.1rem;
          line-height: 35.71px;
        }
      }
      .right-firewall-content {
        width: 50%;
        .fire-paragraph {
          font-size: 16px;
          font-weight: 400;
          line-height: 20.15px;
          br {
            display: none;
          }
        }
      }
    }
    .firewall-section {
      height: 35rem;
      .fire-img-info {
        position: absolute;
        bottom: -12%;
        left: 50%;
        transform: translate(-50%, -50%);
        .ignite {
          font-size: 1.7rem;

          line-height: 31.36px;
        }
        .boost {
          margin-top: 10px;

          font-size: 16px;

          line-height: 15.15px;
        }
      }
    }
  }
}
@media (max-width: 850px) {
  .Firewall {
    .top-info {
      width: 90%;

      .left-firewall-content {
        width: 50%;
        .f-head {
          font-size: 2.1rem;
          line-height: 35.71px;
        }
      }
      .right-firewall-content {
        width: 50%;
        .fire-paragraph {
          font-size: 16px;
          font-weight: 400;
          line-height: 20.15px;
          br {
            display: none;
          }
        }
      }
    }
    .firewall-section {
      height: 30rem;
      .bgfireimg {
        display: none;
      }
      .bgfireimg-for-mobile {
        display: block;
        z-index: -1;
        width: 100%;
        height: 100%;
        object-fit: fit-content;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%);
      }
      .fire-img-info {
        position: absolute;
        bottom: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        .ignite {
          font-size: 1.7rem;

          line-height: 28.36px;
        }
        .boost {
          margin-top: 2px;

          font-size: 16px;

          line-height: 17.15px;
        }
      }
      .hover-circle {
        display: none;
        top: 50%;
        left: 10%;
        transform: translate(-50%, -50%);
        width: 200px;
        height: 200px;

        border-radius: 50%;
        background-color: #7cadff;
        filter: blur(62.404998779296875px);
        pointer-events: none; /* Allow interactions underneath */
        transform: translate(-50%, -50%);
      }
    }
  }
}

@media (max-width: 768px) {
  .Firewall {
    .top-info {
      width: 90%;
      flex-direction: column;
      gap: 10px;
      .left-firewall-content {
        width: 100%;
        .f-head {
          font-size: 2.1rem;
          line-height: 35.71px;
          display: inline-block;
          br {
            display: none;
          }
        }
      }
      .right-firewall-content {
        width: 100%;
        .fire-paragraph {
          font-size: 16px;
          text-align: start;
          font-weight: 400;
          line-height: 20.15px;
        }
      }
    }
  }
}
@media (max-width: 550px) {
  .Firewall {
    .top-info {
      width: 85%;
      .left-firewall-content {
        .f-head {
          font-size: 1.5rem;
          line-height: 29.71px;
          display: inline-block;
          br {
            display: none;
          }
        }
      }
      .right-firewall-content {
        width: 100%;
        .fire-paragraph {
          font-size: 16px;
          text-align: start;
          font-weight: 400;
          line-height: 20.15px;
        }
      }
    }
    .firewall-section {
      height: 20rem;
      margin-top: 3rem;
      .fire-img-info {
        bottom: 40%;
        width: 90%;
        .ignite {
          font-size: 1.5rem;
          line-height: 28.36px;
          br {
            display: none;
          }
        }
        .boost {
          text-align: center;
          margin-top: 2px;
          font-size: 16px;
          line-height: 17.15px;
        }
      }
    }
  }
}
