.reinvention-main {
  height: 100%;
  width: 100%;
  padding-top: 7rem;
  padding-bottom: 10rem;
  position: relative;
  // overflow-y: hidden;
  z-index: 1;
  .reinvention-inner-main {
    width: 90%;
    height: 100%;

    margin: auto;

    .reinvention-heading-content {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
      margin-bottom: 2rem;
      .reinvention-heading {
        background: radial-gradient(
          104.21% 376.56% at 50% 50%,
          #ffffff 0%,
          rgba(255, 255, 255, 0) 100%
        );
        width: fit-content;
        background-clip: text;
        color: transparent;
        font-size: 45px;
        font-weight: 400;
        //line-height: 46px;
        text-align: left;

        span {
          background: radial-gradient(
            256.66% 747.37% at 0% 0%,
            #ffffff 0%,
            #4285f4 16.41%,
            rgba(66, 133, 244, 0) 100%
          );
          background-clip: text;
          color: transparent;
          font-weight: 400;
        }
      }
      .reinvention-heading-para {
        font-size: 14px;
      }
    }
    .reinvention-content {
      width: 100%;
      height: 100%;
      margin: auto;
      display: flex;

      justify-content: space-between;
      flex-direction: row;

      .reinvention-card {
        width: 304px;
        height: 183px;

        gap: 1rem;
        display: flex;
        flex-direction: column;
        .reinvention-number {
          font-family: "Maven Pro", sans-serif;
          font-size: 22px;
          font-weight: 400;
          line-height: 25.85px;
          text-align: left;
          color: #f6f6f6a6;
        }
        .reinvention-content-head {
          font-size: 28px;
          font-weight: 400;
          line-height: 32.17px;
          text-align: left;
        }
        .reinvention-para {
          font-family: "Maven Pro", sans-serif;
          font-size: 14px;
          font-weight: 400;
          line-height: 16.45px;
          text-align: left;
        }
      }
    }
  }
  .reinvention-background-effect {
    position: absolute;
    width: 658px;
    height: 458px;
    top: 240px;
    right: -515px;
    opacity: 0.7;
    border-radius: 50%;
    background: linear-gradient(
      90deg,
      #f68447 0%,
      #f36169 29.83%,
      #f36169 72.32%,
      #dd67b5 100%
    );
    filter: blur(147px);
    z-index: -1;
  }
}

@media (max-width: 1050px) {
  .reinvention-main {
    .reinvention-inner-main {
      .reinvention-content {
        gap: 1rem;
        .reinvention-card {
          width: 250px;
          height: 90px;
        }
      }
    }
  }
}

@media (max-width:900px) {
  .reinvention-main{
    padding-top: 3rem;
    padding-bottom: 0;
  
  }
}

@media (max-width: 768px) {
  .reinvention-main {
    .reinvention-inner-main {
      .reinvention-heading-content {
        gap: 8px;
        margin-bottom: 1.5rem;
        align-items: center;
        text-align: center;
        .reinvention-heading {
          font-size: 32px;
          text-align: center;
          margin: 0;
        }
        .reinvention-heading-para {
          font-size: 12px;
          br {
            display: none;
          }
        }
      }

      .reinvention-content {
        display: flex;
        flex-direction: column;
        gap: 30px;
        .reinvention-card {
          width: 100%;
          height: 100%;
          gap: 0;
          text-align: center; // Center align text
          .reinvention-number {
            font-size: 2.1rem;
            margin-bottom: 7px;
          }
        }
        hr {
          margin: 0;
          margin-bottom: 5px;
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .reinvention-main {
    padding-top: 0;
    .reinvention-inner-main {
      .reinvention-heading-content {
        gap: 8px;
        margin-bottom: 1.5rem;
        align-items: center;
        text-align: center;
        .reinvention-heading {
          font-size: 1.7rem;
          text-align: center !important;
          line-height: 31px;
          margin: 0;
        }
        .reinvention-heading-para {
          text-align: center !important;
         
        }
      }

      .reinvention-content {

        gap: 20px;
        .reinvention-card {
    
          .reinvention-number {
            font-size: 1.5rem;
            margin-bottom: 5px;
          }
          .reinvention-content-head {
            font-size: 1.3rem;
            
            line-height: 32.17px;
           
          }
        }
        hr {
          margin: 0;
          margin-bottom: 3px;
        }
      
      }
      .reinvention-para {
        font-family: "Maven Pro", sans-serif;
        font-size: 14px;
        font-weight: 400;
        line-height: 16.45px;
        text-align: left;
      }
    }
  }
}


