.Home {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
  padding-bottom: 4rem;
  padding-top: 0;
  z-index: 1;
  backdrop-filter: blur(94px);

  .home-header {
    width: 90%;
    // margin: auto;
    // margin-top: -10px;
    position: absolute;
    top: 10px;
  }
  .section-one {
    height: 100vh;
    max-height: 55rem;
    z-index: 1;
    text-align: center;
    position: relative;
    padding: 1.5rem 0 3px 0;
    overflow: hidden;
    padding-bottom: 3px;
    background: #131313;
    // ,........
    display: flex;
    justify-content: center;
    align-items: center;
    // flex-direction: column;
    // border: 2px solid red;
    @media (max-width: 1200px) {
      height: 105vh;
    }
    .greenji,
    .yellowji {
      height: 160%;
      width: 100%;
      position: absolute;
      right: 0;
      top: -5%;
      z-index: -1;
    }

    // .ThreeJsTopLeft {
    //   z-index: -1;
    //   position: absolute;
    //   right: -80%;
    //   top: -5%;
    // }

    // .ThreeJs {
    //   z-index: -1;
    //   width: 100%;
    //   bottom: -15%;
    //   position: absolute;
    // }

    .digtaltext,
    .drivestext {
      font-weight: 400;
      background: var(--gradient-text);
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }

    .heroTopDiv {
      margin-top: -114px;
      .digtaltext {
        font-size: 2.77rem;
        // margin-top: 5.5rem;
        line-height: 3rem;
      }
      .drivestext {
        font-size: 3.68rem;
        line-height: 61px;
        margin-top: -8px;
        span {
          background: linear-gradient(90deg, #34a853 0%, #5de18b 100%);
          -webkit-background-clip: text;
          background-clip: text;
          color: transparent;
          font-style: italic;
          font-family: "ubuntu";
        }
      }
      p {
        text-align: center;
        font-size: 14px;
        font-style: 400;
        line-height: 1rem;
        margin-top: 2rem;
      }
      .searchbar {
        margin: auto;
        margin-top: 30px;
        width: 564px;
        height: 60px;
        padding: 3px;
        overflow: hidden;
        border-radius: 20px;
        z-index: 9;
        border: 1px solid rgba(102, 92, 92, 0.685);
        border: 1px solid #8f8c8c91; // border: 1px solid;
        box-shadow: 0px 20px 40px 0px #00000026;
        background: #f6f6f612;
        border: 1px solid;
        border: 1px solid;
        border-image-source: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 5%,
          rgba(255, 255, 255, 0.5882352941) 25%,
          rgba(255, 255, 255, 0) 90%
        );
        border-image-slice: 1;
        .inner-searchbar {
          display: flex;
          justify-content: space-between;
          align-items: center;
          // position: relative;
          // backdrop-filter: blur(2px);
          border-radius: 19px;
          height: 100%;
          width: 100%;

          .innn {
            width: 75%;
            input {
              height: 100%;
              width: 100%;
              font-weight: 400;
              padding: 0 1rem;
              border: none;
              outline: none;
              margin: 0;
              background: transparent;
              color: white;
              font-family: Maven Pro;
              font-size: 18px;
              font-weight: 400;
              text-underline-position: from-font;
              text-decoration-skip-ink: none;
            }
          }
          button {
            width: 130px;
            height: 100%;
            padding: 5px 17px;
            font-size: 1rem;
            font-family: "Maven Pro", sans-serif;
            font-weight: 700;
            border: none;
            border-radius: 17px;
            color: var(--text-normal);

            background: radial-gradient(
                43.34% 122.55% at 0% -40.2%,
                rgba(255, 255, 255, 0.9) 0%,
                rgba(255, 255, 255, 0) 100%
              ),
              linear-gradient(0deg, #4285f4, #4285f4);
            // border: 1px solid;

            // border-image-source: linear-gradient(
            //     0deg,
            //     rgba(255, 255, 255, 0.2),
            //     rgba(255, 255, 255, 0.2)
            //   ),
            //   linear-gradient(
            //     108.65deg,
            //     #ffffff3f -26.25%,
            //     rgba(255, 255, 255, 0) 31.16%
            //   );
            // border-image-slice: 1;
            border: 1px solid; /* Set the border width */
            border-image: linear-gradient(
              43.34% 122.55% at 0% -40.2%,
              rgba(255, 255, 255, 0.9) 0%,
              rgba(255, 255, 255, 0) 100%
            );

            border-image-slice: 1;
            border: 1px solid rgba(255, 255, 255, 0.295);
          }
        }
      }
    }

    //
    .bgimages {
      display: none;
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      z-index: -1;
      overflow: hidden;
      .bg4 {
        width: 50%;
        position: absolute;
        top: -20%;
        right: -4%;
        filter: blur(9px);
      }
      .bg1 {
        width: 30%;
        z-index: 1;
        position: absolute;
        right: -5%;
        top: 35%;
        background: linear-gradient(90deg, #34a853 0%, #5de18b 100%);
        opacity: 0.4;
        filter: blur(124px);
        width: 234px;
        height: 234px;
        gap: 0px;
      }
      .bg2 {
        position: absolute;
        top: 5%;
        left: 0;
        z-index: 1;
      }
      .bg3 {
        position: absolute;
        top: 10%;
        left: -10%;
        // border: 1px solid red;
      }
    }

    // section tow
    .section-tow-container {
      position: absolute;
      bottom: 1%;

      .sectionTwo {
        width: 100%;
        height: 114px;
        position: relative;
        z-index: 2;
        .sec-bg-img {
          z-index: 1;
          position: relative;
          height: 114px;
          width: 100%;

          .topbg {
            width: 100%;
            height: 100%;
            top: -85px;
            z-index: -1;
            margin-top: -54px;
            background-repeat: no-repeat;
          }
          .buttombg {
            width: 100%;

            bottom: 100%;
            left: 0;
            margin-top: 35px;
            z-index: -1;
          }
        }
        .ourhead {
          z-index: 2;
          position: absolute;
          top: 0;
          height: 100%;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #131313b2;
          backdrop-filter: blur(94px);
          border: 0;

          h2 {
            font-size: 2.9rem;
            font-weight: 500;
            display: flex;
            .bluetext {
              height: 93%;
              margin-top: 3px;
              margin-left: 0.6rem;
              padding-right: 15px;
              background: linear-gradient(
                90deg,
                rgba(81, 146, 255, 0) 25.32%,
                #4285f4 93.28%,
                #b6d1ff 100%
              );
              box-shadow: 48px 0px 84px 0px #4285f4b2;
            }
            .greentext {
              margin-left: 0.4rem;
            }
            .line {
              padding: 0 1px;
              position: relative;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              font-weight: 100;
              opacity: 0.8;
              padding-right: 10px;
              .top {
                width: 8px;
                height: 2px;
                background: rgb(255, 255, 255);
              }
              .bot {
                width: 8px;
                height: 2px;
                background: rgb(255, 255, 255);
              }

              .center {
                height: 58px;
                width: 3px; /* Adjust width for vertical center */
                background: white;
                box-shadow: 0.2px 0 5px 4px #34a853, 4px 0 4px -2px #34a853;
                z-index: -1;
              }
              .blueCenter {
                box-shadow: 0.2px 0 5px 4px #4285f4, 4px 0 4px -2px #4285f4;
              }
            }

            .greentext {
              height: 93%;
              margin-top: 3px;
              margin-left: 0.6rem;
              background: linear-gradient(
                90deg,
                rgba(88, 255, 146, 0) 25.32%,
                #5de18b 93.28%,
                #dcffe8 100%
              );
              padding-right: 10px;
              box-shadow: 48px 0px 84px 0px #58ff9299;
            }
          }
        }
      }
    }

    .ourserive {
      height: 100%;
      width: 100%;
      margin-top: 25rem;
      border: 1px solid red !important;

      // padding-top: 3rem;
      z-index: 3;
    }
    z-index: -1;
  }
  .ourserive {
    position: relative;
    overflow: hidden;
    .ourservicebgbg {
      position: absolute;
      top: -60%;
      left: -5%;
      z-index: -1;
      opacity: 0.7;
    }
    height: 100%;
    width: 100%;
    padding-top: 10rem;
    z-index: 9;
  }

  .ourClient {
    margin-top: 10rem;
  }

  .LinePart {
    display: 100%;
    display: flex;
    justify-content: center;
    margin: 4rem 0rem;
    .CheckLinePart {
      width: 1280px;
      height: 2px;
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        #fff 50%,
        rgba(255, 255, 255, 0) 100%
      );
    }
  }
  .sectionThree {
    margin-top: 15rem;
  }

  .AditForm {
    margin-top: 15rem;
  }

  .JoinForms {
    margin-bottom: 2rem;
    padding: 0 2.5rem;
  }
}

@media (max-width: 1024px) {
  .Home {
    .section-one {
      padding: 1rem 0 3px 0;
      height: 35rem;
      .heroTopDiv {
        .digtaltext {
          font-size: 2.1rem;
          margin-top: 4rem;
        }
        .drivestext {
          font-size: 2.8rem;
        }
        p {
          margin-top: 1.5rem;
        }
        .searchbar {
          width: 564px;
          height: 50px;
          padding: 3px;

          border-radius: 20px;

          .inner-searchbar {
            border-radius: 19px;

            .innn {
              input {
                padding: 0 1rem;

                font-size: 18px;
              }
            }
            button {
              width: 130px;

              padding: 5px 17px;
              font-size: 1rem;
              border-radius: 17px;
            }
          }
        }
      }

      .bgimages {
        top: 0;
        .bg4 {
          width: 50%;
          top: -20%;
          right: -4%;
        }
        .bg1 {
          width: 30%;
          z-index: 1;
          position: absolute;
          right: -5%;
          top: 35%;
          background: linear-gradient(90deg, #34a853 0%, #5de18b 100%);
          filter: blur(124px);
          width: 234px;
          height: 234px;
          gap: 0px;
        }
        .bg2 {
          position: absolute;
          top: 5%;
          left: 0;
          z-index: 1;
        }
        .bg3 {
          position: absolute;
          top: 10%;
          left: -10%;
          // border: 1px solid red;
        }
      }

      // section tow
      .section-tow-container {
        .sectionTwo {
          width: 100%;
          height: 90px;
          z-index: 4;
          .sec-bg-img {
            z-index: 1;
            position: relative;
            height: 100%;
            width: 100%;
            .topbg {
              margin-top: -43px;
            }
            .buttombg {
              margin-top: 28px;
            }
          }
          .sec-bg-img {
            z-index: 1;
            .topbg {
              top: -64px;
              // width: 0%;
            }
          }
          .ourhead {
            h2 {
              font-size: 2.1rem;
              display: flex;
              align-items: center;
              justify-content: center;
              .bluetext {
                margin-left: 0.4rem;
                padding-right: 10px;
              }
              .greentext {
                margin-left: 0.4rem;
              }
              .line {
                padding-right: 10px;
                .top {
                  width: 8px;
                }
                .bot {
                  width: 8px;
                  height: 2px;
                  background: rgb(255, 255, 255);
                }

                .center {
                  height: 42px;
                  width: 3px; /* Adjust width for vertical center */
                  background: white;
                  box-shadow: 0.2px 0 5px 4px #34a853, 4px 0 4px -2px #34a853;
                  z-index: -1;
                }
                .blueCenter {
                  box-shadow: 0.2px 0 5px 4px #4285f4, 4px 0 4px -2px #4285f4;
                }
              }
            }
          }
        }
      }
    }
    .ourserive {
      .ourservicebgbg {
        width: 70%;
        @media (max-width: 768px) {
          width: 60%;
          top: -30%;
        }
      }
    }

    // .ourteams {
    //   height: 100vh;
    // }
    .JoinForms {
      height: 100%;
    }
  }
}

@media (max-width: 778px) {
  .Home {
    .JoinForms {
      padding: 0 1.5rem;
      margin-top: 4rem;
    }
  }
}
@media (max-width: 768px) {
  .Home {
    .section-one {
      padding: 1rem 0 3px 0;
      // height: 100%;
      height: 32rem;
      .heroTopDiv {
        .digtaltext {
          font-size: 1.7rem;
          // margin-top: 2.2rem;
        }
        .drivestext {
          font-size: 2.1rem;
          line-height: 27px;
        }
        p {
          margin-top: 1.5rem;
        }
        .searchbar {
          width: 500px;
          height: 40px;
          padding: 2px;
          .inner-searchbar {
            button {
              width: 130px;
              font-weight: 200;
              padding: 0px 17px;
              font-size: 1rem;
              border-radius: 17px;
            }
          }
        }
      }

      .bgimages {
        top: 0;

        .bg4 {
          width: 50%;
          top: -20%;
          right: -4%;
        }
        .bg1 {
          width: 30%;
          z-index: 1;
          position: absolute;
          right: -5%;
          top: 35%;
          background: linear-gradient(90deg, #34a853 0%, #5de18b 100%);
          filter: blur(124px);
          width: 234px;
          height: 234px;
          gap: 0px;
        }
        .bg2 {
          position: absolute;
          top: 5%;
          left: 0;
          z-index: 1;
        }
        .bg3 {
          position: absolute;
          top: 10%;
          left: -10%;
          // border: 1px solid red;
        }
      }

      // section tow
      .section-tow-container {
        // border: 1px solid red;
        .sectionTwo {
          width: 100%;
          width: 100vw;
          height: 90px;
          z-index: 4;
          .sec-bg-img {
            z-index: 1;
            .topbg {
              top: -64px;
            }
            .buttombg {
              position: absolute;
              bottom: -4%;
              // margin-top: 28px;
              // border: 4px solid rgba(255, 0, 0, 0);
            }
          }
          // .ourhead {
          //   h2 {
          //     font-size: 2.1rem;
          //     display: flex;
          //     align-items: center;
          //     justify-content: center;
          //     .bluetext {
          //       margin-left: 0.4rem;
          //       padding-right: 10px;
          //     }
          //     .greentext {
          //       margin-left: 0.4rem;
          //     }
          //     .line {
          //       padding-right: 10px;
          //       .top {
          //         width: 8px;
          //       }
          //       .bot {
          //         width: 8px;
          //         height: 2px;
          //         background: rgb(255, 255, 255);
          //       }

          //       .center {
          //         height: 35px;
          //         width: 3px; /* Adjust width for vertical center */
          //         background: white;
          //         box-shadow: 0.2px 0 5px 4px #34a853, 4px 0 4px -2px #34a853;
          //         z-index: -1;
          //       }
          //       .blueCenter {
          //         box-shadow: 0.2px 0 5px 4px #4285f4, 4px 0 4px -2px #4285f4;
          //       }
          //     }
          //   }
          // }
        }
      }
    }
    .ourserive {
      z-index: -1;
    }
    .PortFolio {
      margin-top: -9rem !important;
      z-index: 1;
    }
  }
}
@media (max-width: 600px) {
  .Home {
    .JoinForms {
      margin-top: 2rem;
    }
  }
}

@media (max-width: 550px) {
  .Home {
    .section-one {
      height: 28rem;
      padding: 1rem 0 3px 0;
      margin: 0;
      margin-bottom: 3rem;
      .greenji {
        height: 140%;
        opacity: 0.9;
      }
      .heroTopDiv {
        .digtaltext {
          font-size: 1.7rem;
          margin-top: 2.2rem;
          line-height: 1.9rem;
          margin-bottom: 0;
        }
        .drivestext {
          font-size: 2.1rem;
          line-height: 38px;
          margin: 0;
        }
        p {
          width: 80%;
          margin: 1.5rem auto;

          br {
            display: none;
          }
        }
        .searchbar {
          width: 82%;
          height: 39px;
          padding: 2px;
          // z-index: 1 !important;
          .inner-searchbar {
            .innn {
              input {
                font-size: 15px;
              }
            }
            button {
              width: 100px;
              font-weight: 200;
              padding: 0px 17px;
              font-size: 14px;
              border-radius: 17px;
            }
          }
        }
      }

      .bgimages {
        top: 0;

        .bg4 {
          width: 50%;
          top: -20%;
          right: -4%;
        }
        .bg1 {
          width: 30%;
          z-index: 1;
          position: absolute;
          right: -5%;
          top: 35%;
          background: linear-gradient(90deg, #34a853 0%, #5de18b 100%);
          filter: blur(124px);
          width: 234px;
          height: 234px;
          gap: 0px;
        }
        .bg2 {
          position: absolute;
          top: 5%;
          left: 0;
          z-index: 1;
        }
        .bg3 {
          position: absolute;
          top: 10%;
          left: -10%;
        }
      }

      // section tow
      .section-tow-container {
        z-index: -1;
        .sectionTwo {
          margin-top: 6rem;
          height: 55px;
          top: 50%;
          .sec-bg-img {
            position: relative;

            .topbg {
              margin-top: -27px;
            }
          }
          .ourhead {
            h2 {
              margin-top: 9px;
              font-size: 1.5rem;

              .bluetext {
                margin-left: 0.4rem;
                padding-right: 7px;
              }
              .greentext {
                margin-left: 0.4rem;
              }
              .line {
                padding-right: 7px;
                .top {
                  width: 5px;
                  height: 1px;
                }
                .bot {
                  width: 5px;
                  height: 1px;
                  background: rgb(255, 255, 255);
                }

                .center {
                  height: 31px;
                  width: 1px;
                }
              }
            }
          }
        }
      }
    }
    .ourClient {
      padding: 2rem 0;
    }
    .ourserive {
      margin-top: -51px;
      padding: 0;
      padding-top: 5rem;
      .ourservicebgbg {
        width: 100%;
        height: 100%;
        top: -60%;
        left: -29%;
      }
    }
    .AditForm {
      margin: 0;
    }
    .JoinForms {
      margin-top: -20px;
    }
  }
}
@media (max-width: 370px) {
  .Home {
    .section-one {
      padding-bottom: 0;

      margin-bottom: 0;
      .heroTopDiv {
        .digtaltext {
          font-size: 1rem;
          margin-top: 2rem;
        }
        .drivestext {
          font-size: 1.5rem;
        }
        p {
          font-size: 12px;
        }
      }
      // .searchbar {
      //   // margin-top: 2rem;

      //   input {
      //     font-size: 12px;
      //     padding: 8px 20px;
      //   }
      //   button {
      //     width: 50%;
      //     font-size: 12px;
      //   }
      // }

      .bgimages {
        top: 0;

        .bg4 {
          width: 50%;
          top: -20%;
          right: -4%;
        }
        .bg1 {
          width: 30%;
          z-index: 1;
          position: absolute;
          right: -5%;
          top: 35%;
          background: linear-gradient(90deg, #34a853 0%, #5de18b 100%);
          filter: blur(124px);
          width: 234px;
          height: 234px;
          gap: 0px;
        }
        .bg2 {
          position: absolute;
          top: 5%;
          left: 0;
          z-index: 1;
        }
        .bg3 {
          position: absolute;
          top: 10%;
          left: -10%;
          // border: 1px solid red;
        }
      }

      // section tow
      .section-tow-container {
        .sectionTwo {
          height: 45px;

          .sec-bg-img {
            .topbg {
              // top: -64px;
            }
          }
          .ourhead {
            h2 {
              font-size: 1rem;

              .line {
                .center {
                  height: 21px;
                }
              }
            }
          }
        }
      }
    }
    // .ourserive {
    //   margin: 0;
    //   margin-top: 1rem;
    // }
  }
}
