.first-div {
  height: 100vh;
  max-height: 800px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  // margin-top: 1rem;
  position: relative;
  // overflow-y: hidden;
  z-index: 1;
  padding: 3rem 0;
  .CareeerInnerSection {
    position: relative;
    .cc {
      position: absolute;
      opacity: 0.8;
      position: absolute;
      left: -40%;
      top: -60%;
      transform: rotate(-15deg);
      z-index: -1;
    }

    .career-hero-header {
      font-size: 7vw;
      font-weight: 900;
      z-index: 9;
      position: relative;
      font-family: "Ubuntu", sans-serif;
      font-size: 44.59px;
      font-weight: 400;
      line-height: 51.24px;
      text-align: center;
      background: radial-gradient(
        104.21% 376.56% at 50% 50%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background-clip: text;
      color: transparent;

      i {
        text-shadow: 222px 29px 20px (inset white);
      }

      .one {
        display: inline;
        font-size: 7vw;
        color: rgba(0, 0, 0, 0.013);
        z-index: 2222 !important;
        text-shadow: -2px -2px 0 white, 2px -2px 0 white, -2px 2px 0 white,
          2px 2px 0 white, -2px 0 0 white, 2px 0 0 white, 0 -2px 0 white,
          0 2px 0 white;
      }
    }

    .heading-2 {
      font-family: "Ubuntu", sans-serif;
      font-size: 59.31px;
      font-weight: 400;
      line-height: 68.14px;
      text-align: center;
      background: radial-gradient(
        104.21% 376.56% at 50% 50%,
        #ffffff 0%,
        rgba(255, 255, 255, 0) 100%
      );
      background-clip: text;
      color: transparent;
      margin-bottom: 1.5rem;
      .special-words {
        background: radial-gradient(
          351.67% 1064.88% at -39.28% -40.2%,
          #ffffff 0%,
          #4285f4 16.41%,
          rgba(66, 133, 244, 0) 100%
        );
        background-clip: text;
        color: transparent;
        font-style: italic;
      }
    }
    .para-top {
      font-family: "Maven Pro", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.45px;
      text-align: center;

      .apply-btn {
        font-family: "Maven Pro", sans-serif;
        font-size: 14px;
        font-weight: 500;
        line-height: 16.45px;
        text-align: center;
        text-decoration: none;
        text-decoration-style: solid;
        color: #4285f4;
      }
    }

    .box1 {
      opacity: 0.7;
      position: absolute;
      left: -40%;
      bottom: -85%;
      transform: rotate(-6deg);
      filter: blur(2px);
    }

    .box3 {
      position: absolute;
      bottom: -130%;
      left: 85%;
      transform: rotate(30deg);
      filter: blur(5px);
      z-index: 1;
    }
  }
  .background-effect {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 558px;
    height: 358px;
    background: #4285f4;
    filter: blur(100px);
    opacity: 0.4;
    z-index: -1;
    border-radius: 50%;
  }
}

@media (max-width: 1024px) {
  .first-div {
    .CareeerInnerSection {
      .cc {
        position: absolute;
        opacity: 0.8;
        position: absolute;
        left: -40%;
        top: -60%;
        transform: rotate(-15deg);
        z-index: -1;
      }
      .career-hero-header {
        font-size: 2.5rem;
        margin: 0;
      }

      .heading-2 {
        font-size: 2rem;
        line-height: 24.14px;
        margin-bottom: 1.5rem;
    
      }
    

      .box1 {
        opacity: 0.7;
        position: absolute;
        left: -40%;
        bottom: -85%;
        transform: rotate(-6deg);
        filter: blur(2px);
      }

      .box3 {
        position: absolute;
        bottom: -130%;
        left: 85%;
        transform: rotate(30deg);
        filter: blur(5px);
        z-index: 1;
      }
    }
  }
}
@media (max-width: 840px) {
  .first-div {
    .CareeerInnerSection {
      margin-top: 3rem;
      .cc {
        
        left: -25%;
        top: -60%;
        transform: rotate(-15deg);
        z-index: -1;
      }
      .career-hero-header {
        font-size: 2.5rem;
        margin: 0;
      }

      .heading-2 {
        font-size: 2rem;
        line-height: 24.14px;
        margin-bottom: 1.5rem;
    
      }
    

      .box1 {
        opacity: 0.7;
        position: absolute;
        left: -26%;
        bottom: -100%;
        transform: rotate(-6deg);
        filter: blur(2px);
      }

      .box3 {
        position: absolute;
        bottom: -130%;
        left: 85%;
        transform: rotate(30deg);
        filter: blur(5px);
        z-index: 1;
      }
    }
  }
}

@media (max-width: 700px) {
  .first-div {
    height: 100%;
    padding-top: 7rem;
    .box1,
    .box2,
    .box3 {
      display: none;
    }
    .cc {
      display: none;
    }
    .para-top {
      width: 90%;
    }
    .background-effect {
      margin-top: 4rem;
      width: 358px;
      height: 158px;
      opacity: 0.6;
    }
  }
}

@media (max-width: 550px) {
  .first-div {
    .CareeerInnerSection {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .career-hero-header {
        font-size: 2rem;
        margin: 0;
      }
     

      .heading-2 {
        font-size: 1.4rem;
        line-height: 16.14px;
        margin-bottom: 1rem;
      }
      .para-top{
        font-size: 14px;
        .apply-btn{
          font-size: 14px;
        }
      }
    

      .box1 {
        opacity: 0.7;
        position: absolute;
        left: -26%;
        bottom: -100%;
        transform: rotate(-6deg);
        filter: blur(2px);
      }

      .box3 {
        position: absolute;
        bottom: -130%;
        left: 85%;
        transform: rotate(30deg);
        filter: blur(5px);
        z-index: 1;
      }
    }
  }
}

@media (max-width: 530px) {
  .first-div {
   

    .cc {
      display: none;
    }

    h1 {
      font-size: 9.5vw;

      .one {
        color: white;
        font-size: 12vw;
      }
    }

    .heading-2 {
      line-height: 73px;
    }

    .para-top {
      margin: 0 !important;
    }

    .box1,
    .box2,
    .box3 {
      display: none;
    }
  }
}
